import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';
import { FaHeartCircleCheck, FaInstagram, FaLinkedinIn, FaLocationDot } from "react-icons/fa6";
import { TiSocialFacebook } from 'react-icons/ti';
import { Rating } from "primereact/rating";
import { MdOutlineSafetyCheck } from 'react-icons/md';
const TopAgenciesSlider = () => {
    const sliderTopAgenciesProductService = [
        {
          url: "./Images/agency_1.jpg",
          name:"Shushma Silver Enclave",
          listing:"2 Listing",
        },
        {
            url: "./Images/agency_2.png",
          name:"Rose National Houses",
          listing:"5 Listing",
         features:"Featured",
        },
        {
            url: "./Images/agency_3.png",
          name:"Maker Good Resort",
          listing:"12 Listing",
        },
        {
            url: "./Images/agency_4.jpg",
          name:"Seetal Modern Enclave",
          listing:"6 Listing",
         features:"Featured",
        },
        {
            url: "./Images/agency_5.png",
          name:"Shushma Silver Enclave",
          listing:"9 Listing",
         features:"Featured",
        },
       
      
          
      ];
      
      const sliderTopAgenciesresponsiveOptions = [
        {
          breakpoint: "2400px",
          numVisible: 4,
          numScroll: 1,
        },
        {
          breakpoint: "1199px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "767px",
          numVisible: 1,
          numScroll: 1,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
          numScroll: 1,
        },
      ];
      const sliderTopAgenciesproductTemplate = (product) => {
        return (
          <div className='top-agencies-slider-card border'>
          
            <div className='d-flex align-items-center justify-content-between'>
               
                {product.features?<div className='featured-box'>{product.features}</div>:<div></div>}
                <div className='heart-icon'>
                <MdOutlineSafetyCheck size={22} />
                </div>
            </div>
            <div className='agency-image'>
                <img src={product.url}  alt="" />
            </div>
            <div className="agency-listing">{product.listing}</div>
            <div className='agency-name'>{product.name}</div>
          </div>
        );
      };

  return (
    <>
                <Slider ProductService={sliderTopAgenciesProductService} responsiveOptions={sliderTopAgenciesresponsiveOptions} productTemplate={sliderTopAgenciesproductTemplate}/>

    </>
  )
}

export default TopAgenciesSlider

