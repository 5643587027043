import { InputText } from "primereact/inputtext";
import "./news.css";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const dummyData = [
  {
    title: "Retail banks wake up to digital lending this year",
    description:
      "Show pony game plan, close the loop so what do you feel you would bring to the table if you were hired for this position. Powerpoint Bunny shotgun approach, nor powerPointless. We just need to put these last issues to...",
    date: "July 29, 2024",
    postedBy: "Admin",
    categories: "In The News, Real Estate",
    image: "./Images/news1.jpg",
  },
  {
    title: "Strategic and commercial approach with issues",
    description:
      "Show pony game plan, close the loop so what do you feel you would bring to the table if you were hired for this position. Powerpoint Bunny shotgun approach, nor powerPointless. We just need to put these last issues to...",
    date: "April 21, 2019",
    postedBy: "Admin",
    categories: "Real Estate",
    image: "./Images/news2.jpg",
  },
  {
    title: "Within the construction industry as their overdraft",
    description:
      "Waste of resources locked and loaded, programmatically dog and pony show, productize or paddle on both sides accountable talk. Social currency pipeline. Churning anomalies cannibalize this vendor is incompetent cross-...",
    date: "February 1, 2019",
    postedBy: "Admin",
    categories: "Real Estate",
    image: "./Images/news3.jpg",
  },
  {
    title: "A digital prescription for the pharma industry",
    description:
      "Race without a finish line this is not the hill i want to die on work flows , but deliverables, so low-hanging fruit blue sky thinking. Run it up the flag pole red flag. Diversify kpis get buy-in organic growth. Socia...",
    date: "February 23, 2019",
    postedBy: "Admin",
    categories: "In The News, Real Estate",
    image: "./Images/news4.jpg",
  },
  {
    title: "Harvest great ideas from your company’s best assets",
    description:
      "Show pony game plan, close the loop so what do you feel you would bring to the table if you were hired for this position. Powerpoint Bunny ",
    date: "February 23, 2019",
    postedBy: "Admin",
    categories: "Images And B-Roll, Real Estatee",
    image: "./Images/news5.jpg",
  },
  {
    title: "The Gifts We Want to Give in 2018",
    description:
      "Social currency pipeline. Churning anomalies cannibalize this vendor is incompetent cross-pollination. When does this sunset? no scraps hit the floor ramp up put a record on and see who dances, so critical mass. Digit...",
    date: "December 18, 2018",
    postedBy: "Admin",
    categories: "Images And B-Roll, Real Estate",
    image: "./Images/news6.jpg",
  },
];

const RECENTNEWS = [
  "Retail banks wake up to digital lending this year",
  "Strategic and commercial approach with issues",
  "Within the construction industry as their overdraft",
];

const mediaGallery = [
    {id:1,
      url:"./Images/media_gallery1.png",
    },

    {id:2,
      url:"./Images/media_gallery2.png",
    },
    {id:3,
      url:"./Images/media_gallery3.png",
    }
    ,
    {id:4,
      url:"./Images/news3.jpg",
    },
    {id:5,
      url:  "./Images/media_gallery5.png",
    },
    {id:6,
      url:    "./Images/media_gallery6.png",
    }


];

export const News = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [widthSize, setWidthSize] = useState(0);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredNews = dummyData.filter((news) =>
    news.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const viewImage = (imageUrl) => {
    setSelectedImage(imageUrl);
    setVisible(true);
  };

  useEffect(() => {
    const handleResize = () => setWidthSize(window.innerWidth);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dialogStyle = {
    width: widthSize < 480 ? '90vw' : '40vw'
  };

  return (
    <>
      <div className="body_section_news">
        <div className="top_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12 col-sm-12 left-side">
                <h1 className="mb-3">News</h1>

                {filteredNews.map((news, index) => (
                  <div className="news-card mb-5" key={index}>
                    <div className="news-image">
                      <img src={news.image} alt="News" />
                      <div className="posted-on">{news.date}</div>
                    </div>
                    <div className="news-details">
                      <h4 className="title">{news.title}</h4>
                      <p>{news.description}</p>
                      <div className="d-flex gap-3 posted">
                        <span className="posted-by">
                          Posted by:{" "}
                          <span className="text-primary posted-pointer">
                            {news.postedBy}
                          </span>
                        </span>
                        <span className="categories">
                          Categories:{" "}
                          <span className="text-primary posted-pointer">
                            {news.categories}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 right-side">
                <div className="addborder"></div>
                <h6>RECENT NEWS</h6>
                <section className="recent-posts-2">
                  <ul>
                    {RECENTNEWS.map((news, index) => (
                      <li key={index}>
                        <a href="#">{news}</a>
                      </li>
                    ))}
                  </ul>
                </section>

                <div className="addborder mt-5 mb-4"></div>

                <section className="media_gallery">
                  <h6>SEE OUR GALLERY</h6>

                  <div className="gallery-icon">
                    {mediaGallery?.map((url, index) => (
                      <img
                        key={index}
                        src={url?.url}
                        className="mediaGallery_img"
                        alt=""
                        height="90"
                        width="90"
                        onClick={() => viewImage(url?.url)}
                      />
                    ))}
                  </div>
                </section>

                <div className="addborder mt-5"></div>

                <section id="categories-2" className="widget widget_categories">
                  <h6 className="widget-title mt-2">Categories</h6>
                  <ul className="">
                    <li className="cat-item cat-item-211">
                      <a href="#">
                        Images and B-Roll
                      </a>
                    </li>
                    <li className="cat-item cat-item-209">
                      <a href="#">
                        In the News
                      </a>
                    </li>
                    <li className="cat-item cat-item-1">
                      <a href="3">
                        Real Estate
                      </a>
                    </li>
                  </ul>
                </section>

                <section>
                  <button className="download-btn w-100 mt-4">
                    DOWNLOAD BROCHURE
                  </button>
                </section>

                <section>
                  <div className="search-bar mb-4 mt-4">
                    <InputText
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search news..."
                    />
                    <FaSearch className="search-icon" />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card flex justify-content-center">
        <Dialog
          visible={visible}
          style={dialogStyle}
          onHide={() => setVisible(false)}
          draggable={false}
        >
          <div className="d-flex dialog_class mb-3 h-auto">
            {selectedImage && <img src={selectedImage} alt="Preview" style={{ width: '100%' }} />}
          </div>
        </Dialog>
      </div>
    </>
  );
};