import React, { useState } from 'react'

const ServiesDropdowns = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleHover = (itemName) => {
    setSelectedItem(itemName);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Selling Guides":
        return (
          <>
            <div className="property-div">
              <div className="gray-header">Property Types</div>
              <div className="under-content">
                <div className="property-name">Home Selling Guide</div>
                <div className="property-name">Sellers Guide</div>
                <div className="property-name">Explore Your Options</div>
                <div className="property-name">Selling Options</div>
                <div className="property-name">Prepare Your Home for Sale </div>
                <div className="property-name">Marketing Your Property</div>
              </div>
            </div>


          </>
        );

      default:
        return (
          <>

            <div className="drop-header-div2">
              <div className="header-item">Interior Design</div>
              <div className="header-item">Design & Architecture</div>
              <div className="header-item">Renovation Contractors</div>
              <div className="header-item">Loan Hub</div>
              <div className="header-item">Legal Expertise</div>
              <div className="header-item">Valuation Advisors</div>
            </div>







            <div className="drop-header-div2">
              <div className="header-item">Property Management</div>
              <div className="header-item">Marketing Mastery</div>
              <div className="header-item">Packers & Movers</div>
              <div className="header-item">Home Renovations</div>
              <div className="header-item">Mortgage Services</div>
              <div className="header-item">Real Estate Advisory</div>

            </div>


            <div className="drop-header-div2">
              <div className="header-item">Investment Insights</div>
              <div className="header-item">Property Insurance</div>
              <div className="header-item">Property Inspections</div>
              <div className="header-item">Home Staging</div>
              <div className="header-item">Cleaning Solutions</div>
              <div className="header-item">Energy Efficiency</div>

            </div>


            <div className="drop-header-div2">
              <div className="header-item">Green Building Solutions</div>
              <div className="header-item">Smart Home Solutions</div>
              <div className="header-item">Home Security</div>
              <div className="header-item">Landscaping Services</div>
              <div className="header-item">Photography & Videography</div>
              <div className="header-item">Events & Celebrations</div>

            </div>
          </>
        );
    }
  };


  return (
    <>
      <div className="under-buy-dropbox">
        <div className="d-flex gap-0">



          {renderContent()}




        </div>
      </div>
    </>
  );

}

export default ServiesDropdowns
