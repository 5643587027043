import React, { useState } from "react";
import "./NavDropdowns.css";
import { PiArrowUpRightBold } from "react-icons/pi";
import { BsCheckCircle } from "react-icons/bs";

const RentDropdown = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleHover = (itemName) => {
    setSelectedItem(itemName);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Commercial Rentals":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Office Spaces for Rent</div>
              <div className="property-name">Retail Shops for Rent</div>
              <div className="property-name">Warehouses for Rent </div>
              <div className="property-name">Showrooms for Rent</div>
              <div className="property-name">Co-working Spaces for Rent </div>
              <div className="property-name">Industrial Units for Rent</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
      case "Short-Term Rentals":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Serviced Apartments</div>
              <div className="property-name">Vacation Homes</div>
              <div className="property-name"> Furnished Rentals</div>
              <div className="property-name">Holiday Rentals</div>
              <div className="property-name">Temporary Housing</div>
                <div className="property-name"> Extended Stay Rentals
                </div>              
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 25 Lac </div>
              <div className="popular-name">&#x20B9; 25 Lac - &#x20B9; 50 Lac</div>
              <div className="popular-name">&#x20B9; 50 LAc - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Dwarka </div>
              <div className="explore-name">Projects in Mumbai</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find Building</div>
              <div className="explore-name">Investment </div>
            </div>
            </div>
          </>
        );
      case "Affordable Rentals":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Budget Apartments</div>
              <div className="property-name">Low-Cost Housing</div>
              <div className="property-name">Student Accommodation</div>
              <div className="property-name">Subsidized Housing</div>
              <div className="property-name">Cost-Effective Living</div>
              <div className="property-name"> Shared Housing</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
     
      case "Specialized Rentals":
        return (
          <>
                    <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Co-living Spaces</div>
              <div className="property-name">Bachelor-Friendly Homes</div>
              <div className="property-name">Luxury Rentals</div>
              <div className="property-name">Furnished Homes</div>
              <div className="property-name">Immediately Available Rentals</div>
              <div className="property-name">Corporate Housing</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Noida </div>
              <div className="explore-name">Projects in Noida</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find an Agent</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
        case "Rental Services":
            return (
              <>
               <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Property Management</div>
              <div className="property-name">List Your Rentals</div>
              <div className="property-name">Tenant Screening</div>
              <div className="property-name">Create a Lease </div>
              <div className="property-name">Collect Rent Online</div>
              <div className="property-name">Maintenance Support</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
              </>
            );
      default:
        return (
            <>
               <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Apartments for Rent</div>
              <div className="property-name">Houses for Rent</div>
              <div className="property-name">Villas for Rent</div>
              <div className="property-name">Studio Apartments</div>
              <div className="property-name">Shared Apartments</div>
              <div className="property-name">PG/Hostels</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Noida </div>
              <div className="explore-name">Projects in Noida</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find an Agent</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
            </>
        );
    }
  };

  return (
    <>
      <div className="under-buy-dropbox">
        <div className="d-flex gap-4">
          <div className="drop-header-div">
            <div className="header-item" onMouseEnter={() => handleHover("Residential Rentals")}> Residential Rentals</div>
            <div className="header-item" onMouseEnter={() => handleHover("Commercial Rentals")}> Commercial Rentals </div>
            <div className="header-item" onMouseEnter={() => handleHover("Short-Term Rentals")}> Short-Term Rentals</div>
            <div className="header-item" onMouseEnter={() => handleHover("Affordable Rentals")}>Affordable Rentals </div>
            <div className="header-item" onMouseEnter={() => handleHover("Specialized Rentals")}>
              {" "}
              Specialized Rentals 
              {/* <span className="tag">NEW</span> */}
            </div>
            <div className="header-item" onMouseEnter={() => handleHover("Rental Services")}> Rental Services</div>
          </div>
         
            {renderContent()}
         
          
          
       
        </div>
      </div>
    </>
  );
};

export default RentDropdown;
