import React, { useState } from 'react'
import './AgentsBuilders.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { Dropdown } from 'primereact/dropdown'
import { CgMenuGridO, CgSearch } from 'react-icons/cg'
import { InputText } from 'primereact/inputtext'
import { TfiMenuAlt } from 'react-icons/tfi'
import { MdAddIcCall } from 'react-icons/md'
import { IoCallOutline, IoCallSharp, IoMailOutline } from 'react-icons/io5'
import { FaFacebookF, FaHouseChimneyMedical, FaLinkedinIn, FaTwitter } from 'react-icons/fa6'
import Pagination from '../../Components/Pagination/Pagination'
const AgentsBuilders = () => {
    const [selectedCity, setSelectedCity] = useState(null);
    const [value, setValue] = useState("");
    const cities = [
        { name: "Default  Order", code: "NY" },
        { name: "All", code: "NY" },
        { name: "Low to High", code: "NY" },
        { name: "High to low", code: "NY" },
      

      
      ];
      const agentsData =[
        {
        url:"./Images/agents_one.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
        {
        url:"./Images/local-advisor.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
        {
        url:"./Images/lease-connect.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
      {
        url:"./Images/lease-connect.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
      {
        url:"./Images/local-advisor.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
        {
        url:"./Images/agents_one.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
      {
        url:"./Images/agents_one.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
        {
        url:"./Images/local-advisor.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
        {
        url:"./Images/lease-connect.png",
name:"Rita Singh",
position:"Realtor",
number:"+91 7210378452",
email:"demotech@gmai.com",

      },
    ]
  return (
    <>
   <Navbar/>
   <div className="agents_body_cont">
<div className="container">
<div className="agents_top_header">
    <div className="top_header_heading">
    Agents
    </div>
    <div className="search_bar_section">
          {/* <form  className=""> */}
            <div className="location-search d-flex justify-content-between flex-wrap gap-2 align-items-center">
             
              
              <div className="input-text-box">
                <InputText
                id="inputtext-id"
                  value={value}
                  placeholder={`Agent Name`}
                  onChange={(e) => setValue(e.target.value)}
                />
                  <div className="search-for-input">
                  <CgSearch  size={17} />
                  </div>
              </div>
              <button className="advance-btn" >
                {/* <MdKeyboardVoice  size={28}/> */}
                <CgMenuGridO  size={24}/>
              </button>
              <button className="advance-btn" >
                {/* <MdKeyboardVoice  size={28}/> */}
                <TfiMenuAlt   size={24}/>
              </button>
              <div className="dropdown-box d-flex align-items-center">
                <Dropdown
                  // dropdownIcon={<MdOutlineKeyboardArrowDown />}
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.value)}
                  options={cities}
                  optionLabel="name"
                  placeholder="Default Order"
                  className="w-100 md:w-14rem"
                />
              </div>
              
            </div>
          {/* </form> */}
        </div>
  </div>

  <div className="agents_cards">
   {
    agentsData.map((res,index)=>(
        <div className="agent_card" id={index}>
       <div className="agent_image">
       <img src={res.url} alt="" />
       </div>
       <div className="content_card">
       <div className="agent_name">{res.name}</div>
        <div className="agent_about">{res.position}</div>
        <div className="agent_contact mt-2"> <MdAddIcCall size={19} /> {res.number}</div>
        <div className="agent_contact"> <IoMailOutline  size={19} />{res.email}</div>
        <div className="horizontal_line"></div>
        <div className="social_icons">
        <FaFacebookF size={24} /> <FaTwitter size={24} />  <FaLinkedinIn size={24} />
        </div>
       </div>
    </div> 
    ))
   }
   
  </div>
  <div className="pagination">
    <Pagination/>
  </div>
</div>

<div className="find_home_section">
    <div className="container">
        <div className="row">
            <div className="col-md-7 col-lg-8 left_agent_cont">
<div className="big_heading">
Find for your dream home and increase your investment opportunities
</div>
<div className="para mt-3">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sed tristique metus proin id lorem odio
</div>
<button className="primary-btn mt-4">
<IoCallOutline size={20} />  Contact Seller
</button>
            </div>
            <div className="col-md-5 col-lg-4 right_agent_img">
                <img src="https://themesflat.co/html/dreamhomehtml/assets/images/mark/mark-contact2.png" alt="" />
            </div>
        </div>
    </div>
</div>
<div className="container mb-5">
   <div className="row">
   <div className="col-md-6 pe-3">
        <div className="animated_card">
           <div className="left_image_card">
            <img src="https://themesflat.co/html/dreamhomehtml/assets/images/icon/footer-icon-1.png" alt="" />
           </div>
           <div className="right_cont_card">
            <h3>You need a house</h3>
            <div className="para">Tell us your needs, we will give you thousands of suggestions for the dream home.</div>
            <button className="primary-btn">
<IoCallOutline size={20} />  Contact Seller
</button>
           </div>
        </div>
    </div>
    <div className="col-md-6 ps-3">
    <div className="animated_card">
           <div className="left_image_card">
            <img src="https://themesflat.co/html/dreamhomehtml/assets/images/icon/footer-icon-2.png" alt="" />
           </div>
           <div className="right_cont_card">
            <h3>Sell your house</h3>
            <div className="para">We will connect you to thousands of people who need to buy a home.</div>
            <button className="primary-btn">
<FaHouseChimneyMedical  size={20}/>  Sell Property
</button>
           </div>
        </div>
    </div>
   </div>
</div>
   </div>
   <Footer/>
      
    </>
  )
}

export default AgentsBuilders
