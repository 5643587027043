import React, { useState } from "react";
import "./NavDropdowns.css";
import { PiArrowUpRightBold } from "react-icons/pi";
import { BsCheckCircle } from "react-icons/bs";


const CommercialDropdown = () => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleHover = (itemName) => {
      setSelectedItem(itemName);
    };
  
    const renderContent = () => {
      switch (selectedItem) {
        case "Commercial Leasing":
          return (
            <>
             <div className="property-div">
           
              <div className="gray-header">Property Types</div>
              <div className="under-content">
              <div className="property-name">Office Leasing</div>
                <div className="property-name">Retail Leasing</div>
                <div className="property-name">Industrial Leasing</div>
                <div className="property-name">Event Space Leasing</div>
             
                <div className="property-name">Short Term Leasing</div>
                <div className="property-name">Long-Term Leasing</div>
              </div>
              </div>
              <div className="popular-div">
              <div className="gray-header">budget</div>
              <div className="under-content">
                <div className="popular-name">Under &#x20B9; 50 Lac </div>
                <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
                <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
                <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
                <div className="popular-name">Above &#x20B9; 2 Cr</div>
              </div>
              </div>
              <div className="explore-div">
              <div className="gray-header">Explore</div>
              <div className="under-content">
                <div className="explore-name">Localities in Delhi </div>
                <div className="explore-name">Projects in Delhi</div>
                <div className="explore-name">Investment Hotspot</div>
                <div className="explore-name">Find House</div>
                <div className="explore-name">Investment</div>
              </div>
              </div>
            </>
          );
        case "Industrial Properties Sale":
          return (
            <>
             <div className="property-div">
              <div className="gray-header">Property Types</div>
              <div className="under-content">
                <div className="property-name">Warehouse Sales</div>
                <div className="property-name">Industrial Sales</div>
                <div className="property-name">Factory Sales</div>
                <div className="property-name">Industrial Sales</div>
                <div className="property-name">Logistics Parks</div>
                  <div className="property-name">Manufacturing Units Sales
                  </div>              
              </div>
              </div>
              <div className="popular-div">
              <div className="gray-header">budget</div>
              <div className="under-content">
                <div className="popular-name">Under &#x20B9; 25 Lac </div>
                <div className="popular-name">&#x20B9; 25 Lac - &#x20B9; 50 Lac</div>
                <div className="popular-name">&#x20B9; 50 LAc - &#x20B9; 1 Cr</div>
                <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 2 Cr</div>
                <div className="popular-name">Above &#x20B9; 2 Cr</div>
              </div>
              </div>
              <div className="explore-div">
              <div className="gray-header">Explore</div>
              <div className="under-content">
                <div className="explore-name">Localities in Dwarka </div>
                <div className="explore-name">Projects in Mumbai</div>
                <div className="explore-name">Investment Hotspot</div>
                <div className="explore-name">Find Building</div>
                <div className="explore-name">Investment </div>
              </div>
              </div>
            </>
          );
        case "Investment Properties":
          return (
            <>
             <div className="property-div">
              <div className="gray-header">Property Types</div>
              <div className="under-content">
                <div className="property-name">Commercial Investments</div>
                <div className="property-name">Property Portfolio</div>
                <div className="property-name">Real Estate Funds</div>
                <div className="property-name">High-Yield Properties</div>
                <div className="property-name">Investment Analysis</div>
                <div className="property-name">Commercial Plots</div>
              </div>
              </div>
              <div className="popular-div">
              <div className="gray-header">budget</div>
              <div className="under-content">
                <div className="popular-name">Under &#x20B9; 50 Lac </div>
                <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
                <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
                <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
                <div className="popular-name">Above &#x20B9; 2 Cr</div>
              </div>
              </div>
              <div className="explore-div">
              <div className="gray-header">Explore</div>
              <div className="under-content">
                <div className="explore-name">Localities in Delhi </div>
                <div className="explore-name">Projects in Delhi</div>
                <div className="explore-name">Investment Hotspot</div>
                <div className="explore-name">Find House</div>
                <div className="explore-name">Investment</div>
              </div>
              </div>
            </>
          );
       
        case "Development Projects":
          return (
            <>
                      <div className="property-div">
              <div className="gray-header">Property Types</div>
              <div className="under-content">
                <div className="property-name">Commercial Land</div>
                <div className="property-name">Mixed-Use Developments</div>
                <div className="property-name">Construction Projects</div>
                <div className="property-name">Investment Opportunities</div>
                <div className="property-name">Urban Development</div>
                <div className="property-name">Redevelopment Projects</div>
              </div>
              </div>
              <div className="popular-div">
              <div className="gray-header">budget</div>
              <div className="under-content">
                <div className="popular-name">Under &#x20B9; 50 Lac </div>
                <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
                <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
                <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
                <div className="popular-name">Above &#x20B9; 2 Cr</div>
              </div>
              </div>
              <div className="explore-div">
              <div className="gray-header">Explore</div>
              <div className="under-content">
                <div className="explore-name">Localities in Noida </div>
                <div className="explore-name">Projects in Noida</div>
                <div className="explore-name">Investment Hotspot</div>
                <div className="explore-name">Find an Agent</div>
                <div className="explore-name">Investment</div>
              </div>
              </div>
            </>
          );
          case "Market Trends":
              return (
                <>
                 <div className="property-div">
              <div className="gray-header">Property Types</div>
              <div className="under-content">
                <div className="property-name">Industry Trends</div>
                <div className="property-name">Market Forecasts</div>
                <div className="property-name">Economic Impacts</div>
                <div className="property-name">Regional Market Analysis</div>
                <div className="property-name">Expert Investment Tips</div>
                <div className="property-name">Growth Trends</div>
              </div>
              </div>
              <div className="popular-div">
              <div className="gray-header">budget</div>
              <div className="under-content">
                <div className="popular-name">Under &#x20B9; 50 Lac </div>
                <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
                <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
                <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
                <div className="popular-name">Above &#x20B9; 2 Cr</div>
              </div>
              </div>
              <div className="explore-div">
              <div className="gray-header">Explore</div>
              <div className="under-content">
                <div className="explore-name">Localities in Delhi </div>
                <div className="explore-name">Projects in Delhi</div>
                <div className="explore-name">Investment Hotspot</div>
                <div className="explore-name">Find House</div>
                <div className="explore-name">Investment</div>
              </div>
              </div>
                </>
              );
        default:
          return (
              <>
                 <div className="property-div">
              <div className="gray-header">Property Types</div>
              <div className="under-content">
                <div className="property-name">Office Buildings<span className="tag">FREE</span></div>
                <div className="property-name">Corporate Offices</div>
                <div className="property-name">Retail Spaces</div>
                <div className="property-name">Co-working Spaces</div>
                <div className="property-name">Specialized Venues</div>
                <div className="property-name">Industrial Facilities</div>
              </div>
              </div>
              <div className="popular-div">
              <div className="gray-header">budget</div>
              <div className="under-content">
                <div className="popular-name">Under &#x20B9; 50 Lac </div>
                <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
                <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
                <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
                <div className="popular-name">Above &#x20B9; 2 Cr</div>
              </div>
              </div>
              <div className="explore-div">
              <div className="gray-header">Explore</div>
              <div className="under-content">
                <div className="explore-name">Localities in Noida </div>
                <div className="explore-name">Projects in Noida</div>
                <div className="explore-name">Investment Hotspot</div>
                <div className="explore-name">Find an Agent</div>
                <div className="explore-name">Investment</div>
              </div>
              </div>
              </>
          );
      }
    };
  
    return (
      <>
        <div className="under-buy-dropbox">
          <div className="d-flex gap-4">
            <div className="drop-header-div">
              <div className="header-item" onMouseEnter={() => handleHover("Commercial Property Sales")}>Commercial Property Sales</div>
              <div className="header-item" onMouseEnter={() => handleHover("Commercial Leasing")}>Commercial Leasing</div>
              <div className="header-item" onMouseEnter={() => handleHover("Industrial Properties Sale")}>Industrial Properties Sale</div>
              <div className="header-item" onMouseEnter={() => handleHover("Investment Properties")}>Investment Properties</div>
               <div className="header-item" onMouseEnter={() => handleHover("Development Projects")}>Development Projects</div>
                <div className="header-item" onMouseEnter={() => handleHover("Market Trends")}>Market Trends</div>
        
            </div>
           
              {renderContent()}
           
            
            
         
          </div>
        </div>
      </>
    );
  };
export default CommercialDropdown
