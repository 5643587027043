import React, { useState } from 'react'
import './Advertise.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import TrustedCompaniesSlider from '../HomePage/MultiSliders/TrustedCompaniesSlider'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
const Advertise = () => {
    const [selectedManage, setSelectedManage] = useState(null);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [state, setState] = useState('');
    const [propertyName, setPropertyName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState();
    const [selectedManage2, setSelectedManage2] = useState(null);
    const [name2, setName2] = useState('');
    const [company2, setCompany2] = useState('');
    const [state2, setState2] = useState('');
    const [propertyName2, setPropertyName2] = useState('');
    const [email2, setEmail2] = useState('');
    const [phone2, setPhone2] = useState();
    const Navigate = useNavigate()
    const properties = [
        { name: 'I manage:', code: 'NY' },
        { name: 'Properties with 25 or more units', code: 'RM' },
        { name: 'Properties with 1-24 units', code: 'RM' },

    ];
    const SubmitForm = (e) => {
        e.preventDefault();

    }
    const industry = [
        {
            name: "I'm an agent or broker",
            url: "./Images/agent-image.png"
        },
        {
            name: "I'm a property manager",
            url: "./Images/property-manager.png"
        },
        {
            name: "I'm  a landlord",
            url: "./Images/lanlord-image.png"
        },
        {
            name: "I'm a lender or loan officer",
            url: "./Images/loan-officer.png"
        },
        {
            name: "I'm a builder",
            url: "./Images/builder.png"
        },
        {
            name: "I'm a advertiser",
            url: "./Images/local-advisor.png"
        },
    ]
    return (
        <>
            <Navbar />
            <div className='advertise_body'>
                <div className='container px-md-0 px-5'>
                    <div className='top_section_advertise'>
                        <div className='big-heading'>
                            Let SFTArea Build Your Business
                        </div>
                        <div className='paragraph mt-2'>
                            Reach millions of buyers, sellers and renters on the largest real estate network on the web.
                        </div>
                        <div className='horizontal-primary'></div>
                    </div>
                    <div className='industry_section'>
                        <div className='normal-heading'>
                            Select your industry to get started
                        </div>

                        <div className='d-flex justify-content-between mt-3 mb-5 gap-3 flex-wrap'>
                            {
                                industry.map((data, id) => (
                                    <div className="type_card mt-3 border">
                                        <div className="rounded-image">
                                            <img src={data.url} width={'100%'}  alt="" />
                                        </div>
                                        <div className='py-3 d-flex flex-column gap-3'>
                                            <div className='h5-heading text-center'>{data.name}</div>
                                            <div className='w-100 mt-auto d-flex justify-content-center'>
                                                <button className='primary-btn w-50 py-2'>
                                                Get Started
                                            </button>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }

                        </div>
                    </div>




                    <div className='realstate_sites_section my-4 pt-3 '>
                        <div className='normal-heading'>
                            Reach your target audience on our network of leading real estate sites.
                        </div>

                        <TrustedCompaniesSlider />
                    </div>
                </div>

                <div className='reach-renters-section reach-renters-section_url1'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 d-flex flex-column gap-3 pt-2 col-12 pe-5'>
                                <h2>Reach zillions of renters</h2>
                                <p>Want to get more renters in the door? Advertise with SFTArea Rentals. As the #1 most-visited rentals network, we know that renters prefer us. And you will, too. </p>
                                <p>Already listing with us? <Link to={"/login-register/login"}>Sign in</Link></p>
                            </div>

                            <div className='col-lg-6 col-12'>
                                <div className='form_card_section'>
                                    <h5>Start connecting now</h5>
                                    <p>Submit your information below.   </p>
                                    <form onSubmit={SubmitForm}>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">I manage: *</label>
                                            <Dropdown style={{ padding: '4px 0px 4px 12px' }} value={selectedManage} onChange={(e) => setSelectedManage(e.value)} options={properties} optionLabel="name"
                                                placeholder="Select Value" className="w-full md:w-14rem" />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Full Name *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={name} placeholder="First and last name" onChange={(e) => setName(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Property management company *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={company} placeholder="Property management company" onChange={(e) => setCompany(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Company HQ State *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={state} placeholder="Company HQ State" onChange={(e) => setState(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Property Name *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={propertyName} placeholder="Property Name" onChange={(e) => setPropertyName(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Email *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Phone *</label>
                                            <InputNumber value={phone} placeholder="Phone" onValueChange={(e) => setPhone(e.value)} useGrouping={false} maxLength={10} />
                                        </div>
                                        <div className='mt-4 d-flex flex-column rounded_corner'>
                                            <button className='primary-btn py-2'>Submit</button>
                                        </div>
                                    </form>
                                    <div className='mt-4 p-0'>Are you a landlord with just a few properties?</div>
                                    <Link to={''}>Click here</Link>
                                    <div className='small-text'>
                                        <div className='mt-2 p-0 d-flex gap-1'>We respect your privacy. See our  <Link to={''}>Click here</Link></div>
                                        {/* <div className='mt-2'>By pressing Submit, you agree that SFTArea Group may contact you via phone/text about your inquiry, which may involve the use of automated means. You are not required to consent as a condition of purchasing any goods or services. Message/data rates may apply.</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container px-md-0 px-4'>
                <div className='row two_parts_section'>
                        <div className='py-2 text-center'><h3>Choose your perfect multifamily advertising solution</h3></div>
<p className='px-lg-5 px-1 mb-4 text-center'> Multifamily advertising tailored to your business, whether you manage 25 units or thousands. Choose what works now and adjust as you grow.</p>
                        <div className='col-12 col-md-6 pe-4'>
                            <div className='multifamily_card'>
                                <img width={'100%'} className='rounded_corner'  src="./Images/rent-connect.png" alt="" />
                           <h5 className='mt-2'>SFTArea Rent Connect™</h5>
                           <div className='text-center'>Connect with high-intent renters using one of our three advertising packages.</div>
                       <Link to={''}>Learn more</Link>
                            </div>
                                                   </div>
                        <div className='col-12 col-md-6 mt-md-0 mt-4 ps-4'>
                        <div className='multifamily_card'>
                                <img width={'100%'} className='rounded_corner'  src="./Images/lease-connect.png" alt="" />
                           <h5 className='mt-2 p-0 mb-1'>SFTArea Lease Connect™</h5>
                           <div className='text-center'>The simple pay-per-lease solution that lets you accurately track your cost per lease.</div>
                       <Link to={''}>Learn more</Link>
                            </div>
                        </div>
                    </div>


                    <div className='row two_parts_section'>
                        <div className='col-12 col-md-6'>
                            <img className='rounded_corner' width={'100%'}  src="./Images/ladiesWithKeys.png" alt="" />
                        </div>
                        <div className='col-12 col-md-6  mt-md-0 mt-4'>
                            <h5>Do you manage single-family properties?</h5>
                            <div className=' mt-4'> <Link to={''}>SFTArea Feed Connect™ </Link>
                                is the streamlined solution for property managers using third-party feed services.
                            </div>
                            <div className=' mt-3'> <Link to={''}>SFTArea Rental Manager </Link>
                                lets landlords post listings, screen tenants, collect rent and more.
                            </div>
                        </div>
                    </div>
                    <div className='row two_parts_section'>
                        <div className='py-5 text-center'><h3>Included with SFTArea Rentals</h3></div>

                        <div className='col-12 col-md-6 pe-4'>
                            <h5>Your all-in-one account portal</h5>
                            <div className=' mt-4'> Easily manage your SFTArea advertising account with 24/7 access to billing, performance analytics and more.
                            </div>
                            <div className='mt-3'>
                                <button className='transparent-btn' onClick={() => Navigate("/login-register/login")}>Sign in </button>
                            </div>
                        </div>
                        <div className='col-12 col-md-6  mt-md-0 mt-4'>
                            <img width={'100%'} 
                           src="./Images/account-portal.png"
                             alt="" />
                        </div>
                    </div>
                    <div className='row two_parts_section'>


                        <div className='col-12 col-md-6'>
                            <img width={'100%'} 
                                src="./Images/Immersive.png" alt="" />
                        </div>
                        <div className='col-12 col-md-6 ps-4  mt-md-0 mt-4'>
                            <h5>Immersive 3D tours</h5>
                            <div className='mt-4'>
                                Immersive 3D tours
                                Help get more eyes on your property — and more renters in the door — with SFTArea 3D Home™ tours.
                            </div>
                            <div className='mt-3'>
                            <button className='transparent-btn' onClick={() => Navigate("")}>Learn more </button>
                            </div>
                        </div>
                    </div>
                    <div className='row two_parts_section'>
                    
                        <div className='col-12 col-md-6 pe-4'>
                            <h5>Insights at your fingertips</h5>
                            <div className=' mt-4'>Get more visibility into lead activity, ad performance and market-specific trends with analytics and insights.
                            </div>
                            <div className='mt-3'>
                                <button className='transparent-btn' onClick={() => Navigate("")}> Get insights</button>
                            </div>
                        </div>
                        <div className='col-12 col-md-6  mt-md-0 mt-4'>
                            <img width={'100%'} className=''
                            src="./Images/fingertips.png" alt="" />
                        </div>
                    </div>
                </div>

                <div className='reach-renters-section mb-0 reach-renters-section_url2'>
                    <div className='container'>
                        <div className='row d-flex justify-content-center mt-5'>
                            

                            <div className='col-md-8 col-12  d-flex flex-column justify-content-center align-items-center'>
                            <h2>SFTArea Rental Property Advertising</h2>
                                <p className='text-center mt-3'>It’s easier than ever to get renters in the door. Save time and stress — from start to finish — with SFTArea Rentals. </p>
                                <div className='form_card_section'>
                                 {/* second form  */}
                                    <form onSubmit={SubmitForm}>
                                        <div className='d-flex flex-column'>
                                            <label htmlFor="">I manage: *</label>
                                            <Dropdown style={{ padding: '4px 0px 4px 12px' }} value={selectedManage2} onChange={(e) => setSelectedManage2(e.value)} options={properties} optionLabel="name"
                                                placeholder="Select Value" className="w-full md:w-14rem" />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Full Name *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={name2} placeholder="First and last name" onChange={(e) => setName2(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Property management company *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={company2} placeholder="Property management company" onChange={(e) => setCompany2(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Company HQ State *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={state2} placeholder="Company HQ State" onChange={(e) => setState2(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Property Name *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={propertyName2} placeholder="Property Name" onChange={(e) => setPropertyName2(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Email *</label>
                                            <InputText style={{ padding: '4px 0px 4px 12px' }} value={email2} placeholder="Email" onChange={(e) => setEmail2(e.target.value)} />
                                        </div>
                                        <div className='mt-3 d-flex flex-column'>
                                            <label htmlFor="">Phone *</label>
                                            <InputNumber value={phone2} placeholder="Phone" onValueChange={(e) => setPhone2(e.value)} useGrouping={false} maxLength={10} />
                                        </div>
                                        <div className='mt-4 d-flex flex-column rounded_corner'>
                                            <button className='primary-btn py-2'>Submit</button>
                                        </div>
                                    </form>
                                    <div className='mt-4 p-0'>Are you a landlord with just a few properties?</div>
                                    <Link to={''}>Click here</Link>
                                    <div className='small-text'>
                                        <div className='mt-2 p-0 d-flex gap-1'>We respect your privacy. See our  <Link to={''}>Click here</Link></div>
                                        {/* <div className='mt-2'>By pressing Submit, you agree that SFTArea Group may contact you via phone/text about your inquiry, which may involve the use of automated means. You are not required to consent as a condition of purchasing any goods or services. Message/data rates may apply.</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                <div className='end_footer_section'>
            <div className='left_content'>
              <div className='normal_tag'>Need Help Choosing a Plan?</div>
              <h2 className='mt-2'>We're Here to Help!</h2>
              <p>Not sure which plan is right for you? Our team is ready to assist you at any time. Just click the button below to chat with us live.
              </p>
            </div>
            <div className='right-btn'>
              <button className='primary-btn'>
                Go To Live Chat page
              </button>
            </div>
          </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Advertise
