import React, { useState } from "react";
import "./NavDropdowns.css";
import { PiArrowUpRightBold } from "react-icons/pi";
import { BsCheckCircle } from "react-icons/bs";

const BuyDropdown = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleHover = (itemName) => {
    setSelectedItem(itemName);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "HOMES & VILLAS FOR SALE":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Independent Houses</div>
              <div className="property-name"> Duplex Homes </div>
              <div className="property-name"> Budget Homes </div>
              <div className="property-name"> Luxury Villas</div>
              <div className="property-name">Gated Community Villas </div>
              <div className="property-name">Independent Villas </div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
      case "HIGH-RISE RESIDENCES":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">High-Rise Apartments</div>
              <div className="property-name">Luxury High-Rises</div>
              <div className="property-name"> Penthouse Suites</div>
              <div className="property-name">Skyline Apartments</div>
              <div className="property-name">City View Apartment</div>
                <div className="property-name"> Modern Flats
                </div>              
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 25 Lac </div>
              <div className="popular-name">&#x20B9; 25 Lac - &#x20B9; 50 Lac</div>
              <div className="popular-name">&#x20B9; 50 LAc - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Dwarka </div>
              <div className="explore-name">Projects in Mumbai</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find Building</div>
              <div className="explore-name">Investment </div>
            </div>
            </div>
          </>
        );
      case "NEW CONSTRUCTIONS":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Recently Launched Projects</div>
              <div className="property-name">Upcoming Developments</div>
              <div className="property-name">Pre-Launch Offers</div>
              <div className="property-name">Under Construction Properties </div>
              <div className="property-name">Move-In Ready Properties</div>
              <div className="property-name"> Modern Developments</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
     
      case "UPCOMING PROPERTIES":
        return (
          <>
                    <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Flats in Noida</div>
              <div className="property-name">House for sale in Noida</div>
              <div className="property-name">Vila in Noida</div>
              <div className="property-name">office Space in Noida</div>
              <div className="property-name">Plots in Dwarka Delhi</div>
              <div className="property-name">Vila in Gurugram</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Noida </div>
              <div className="explore-name">Projects in Noida</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find an Agent</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
        case "PLOTS, LANDS, AND FARMS":
            return (
              <>
               <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Residential Plots</div>
              <div className="property-name">Farmhouses</div>
              <div className="property-name">Agricultural Lands</div>
              <div className="property-name">Industrial Sites </div>
              <div className="property-name">Factory & Manufacturing</div>
              <div className="property-name">Warehouse & Cold Storage</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
              </>
            );
      default:
        return (
            <>
               <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Apartments for Sale</div>
              <div className="property-name">Luxury Residences</div>
              <div className="property-name">Affordable Apartments</div>
              <div className="property-name">Move-In Ready Flats</div>
              <div className="property-name">Flats under Construction</div>
              <div className="property-name">Studio Living</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Noida </div>
              <div className="explore-name">Projects in Noida</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find an Agent</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
            </>
        );
    }
  };

  return (
    <>
      <div className="under-buy-dropbox">
        <div className="d-flex gap-4">
          <div className="drop-header-div">
            <div className="header-item" onMouseEnter={() => handleHover("FLAT FOR SALE")}> FLAT FOR SALE</div>
            <div className="header-item" onMouseEnter={() => handleHover("HOMES & VILLAS FOR SALE")}> HOMES & VILLAS FOR SALE </div>
            <div className="header-item" onMouseEnter={() => handleHover("HIGH-RISE RESIDENCES")}> HIGH-RISE RESIDENCES</div>
            <div className="header-item" onMouseEnter={() => handleHover("NEW CONSTRUCTIONS")}>NEW CONSTRUCTIONS </div>
            <div className="header-item" onMouseEnter={() => handleHover("UPCOMING PROPERTIES")}>
              {" "}
              UPCOMING PROPERTIES <span className="tag">NEW</span>
            </div>
            <div className="header-item" onMouseEnter={() => handleHover("PLOTS, LANDS, AND FARMS")}> PLOTS, LANDS, AND FARMS</div>
          </div>
         
            {renderContent()}
         
          
          
       
        </div>
      </div>
    </>
  );
};

export default BuyDropdown;
