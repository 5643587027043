import React from "react";

import "./MarketInsight.css";
import FeaturesPropertySlider from "../HomePage/MultiSliders/FeaturesPropertySlider";
import { ImageWithOverlay } from "./ImageWithOverlay";
import { FaArrowRightLong } from "react-icons/fa6";
import TestomonialSlider from "../HomePage/MultiSliders/TestomonialSlider";
import TrustedCompaniesSlider from "../HomePage/MultiSliders/TrustedCompaniesSlider";

export const MarketInsight = () => {
  const sliderTestmonialProductService = [
    {
      comment: `As a first-time home buyer, the guidance and listings provided on this site were invaluable. The detailed information about each property made my decision much easier!`,
      url: "./Images/client-image1.png",
      name:"Avinash Reddy",
      position:"Buying Properties",
      location:"Hyderabad",
   
    },
    {
      comment: `I found the perfect apartment through this website, and what's best, I didn't have to pay any service fees! The process was transparent and the customer support was very responsive!`,
      url: "./Images/client-image2.png",
      name:"Sumitra Choudhary",
      position:"Buying Properties",
      location:"Vijayawada",
   
    },
    {
      comment: `Excellent platform with a wide range of options available in different price ranges. The search filters helped me find exactly what I was looking for!`,
      url: "./Images/client-image3.png",
      name:"Karthik Rao ",
      position:"Buying Properties",
      location:"Tirupati",
   
    },
    {
      comment: `This website made my property search a breeze with its user-friendly interface and detailed listings. I found a great place within my budget and the locality I desired!`,
      url: "./Images/client-image4.png",
      name:"Nazia Shaik ",
      position:"Buying Properties",
      location:"Visakhapatnam",
   
    },
    {
      comment: `Thanks to this portal, I bought my first home smoothly and efficiently. The no-charge policy is a real money-saver!`,
      url: "./Images/client-image.png",
      name:"Rohan Naidu ",
      position:"Buying Properties",
      location:"Nellore",
   
    },
    {
      comment: `Selling my house was faster and easier than I expected. The listing process was straightforward, and I appreciated not having to pay any service charges!`,
      url: "./Images/client-image5.png",
      name:"Manisha Choudhary ",
      position:"Selling Properties",
      location:"Warangal",
   
    },
    {
      comment: `Great exposure for my property and received inquiries from genuine buyers. Very pleased with the service and especially that it’s free!`,
      url: "./Images/client-image1.png",
      name:"Srinivas Reddy  ",
      position:"Selling Properties",
      location:"Karimnagar",
   
    },
    {
      comment: `This portal helped me connect with serious buyers without intermediaries. The process was transparent and simple!`,
      url: "./Images/client-image3.png",
      name:"Deepak Rao",
      position:"Selling Properties",
      location:"Guntur",
   
    },
    {
      comment: `I managed to sell my property in just a few weeks, thanks to the excellent visibility my listing got on this website. Also, the absence of service charges was a huge plus!`,
      url: "./Images/client-image2.png",
      name:"Fatima Shaik ",
      position:"Selling Properties",
      location:"Rajahmundry",
   
    },
    {
      comment: `A straightforward and effective platform for sellers. I listed my property, and it was sold quicker than I thought, without any fees!`,
      url: "./Images/client-image1.png",
      name:"Prakash Reddy  ",
      position:"Selling Properties",
      location:"Ongole",
   
    },
    {
      comment: `The commercial property listings on this site are comprehensive and provided me with all the information I needed to make an informed decision. Highly recommended for business real estate needs!`,
      url: "./Images/client-image.png",
      name:"Vijay Kumar Choudhary  ",
      position:"Commercial Properties",
      location:"Nizamabad",
   
    },
    {
      comment: `I found the perfect office space for my startup on this site. The filtering options allowed me to narrow down my choices efficiently!`,
      url: "./Images/client-image2.png",
      name:"Lakshmi Reddy  ",
      position:"Commercial Properties",
      location:"Hyderabad",
   
    },
    {
      comment: `Excellent resource for finding commercial spaces. The detailed descriptions and photos made choosing much easier!`,
      url: "./Images/client-image1.png",
      name:"Suresh Rao  ",
      position:"Commercial Properties",
      location:"Kakinada",
   
    },
 
  
      
  ];

  const articles = [
    {
        title: "Tips for First-Time Homebuyers",
        text: "Navigating the real estate market can be challenging for first-time homebuyers. Learn essential tips and strategies to make your homebuying experience smooth and successful.",
        imgSrc: "./Images/Article1.jpg",
        profileImgSrc: "./Images/client-image1.png",
        author: "John Doe",
        date: "July 16, 2024"
    },
    {
        title: "Understanding Real Estate Investment",
        text: "Looking to sell your home? Discover effective home improvement projects and staging tips that can significantly increase your property’s market value.",
        imgSrc: "./Images/Article2.jpg",
        profileImgSrc:"./Images/client-image3.png",
        author: "Jane Smith",
        date: "July 6, 2024"
    },
    {
        title: "Understanding Real Estate Investment",
        text: "Thinking about investing in real estate? Get insights into the different types of real estate investments, potential returns, and risk management strategies",
        imgSrc: "./Images/Article3.jpg",
        profileImgSrc:  "./Images/client-image2.png",
        author: "Alexa Johnson",
        date: "July 2, 2024"
    }
];
  return (
    <div className="body_section_MarketInsight">
      <div className="top_section">
        <div className="container d-flex flex-column gap-0 mb-5">
          <div className="big-heading">
            Discover a place you'll love to live
          </div>
          <h3 className="mt-2 color-primary">
            Let's find a home that's perfect for you
          </h3>
          <div className="big-para">
            "Unlock the door to your dream home with our Premium Access. Enjoy
            exclusive benefits like unlimited property listings, direct
            communication with owners, and dedicated support tailored to your
            unique needs. Start your seamless home search journey with us
            today."
          </div>
        </div>
      </div>

      <div className="container  px-md-0 px-5">
        <div className="text-center mt-5">
          <h2>Featured Properties</h2>
          <p className="text-secondary">Handpicked properties by our team.</p>
        </div>

        <div className="section-fourth mt-5">
          <div>
            <FeaturesPropertySlider />
          </div>
        </div>

        <div className="find_Properties">
          <div className="text-center mt-5">
            <h2>Find Properties in These Cities</h2>
            <p className="text-secondary">Handpicked properties by our team.</p>
          </div>
          <div className="row mt-5"  >
            <div className="col-12 col-md-4 ">
              <ImageWithOverlay
                imgSrc="/Images/FindProperties1.png"
                heading="
Hyderabad"
                text="24 Properties"
              />
            </div>
            <div className="col-12 col-md-8 ">
              <ImageWithOverlay
                imgSrc="/Images/FindProperties2.png"
                heading="Mumbai"
                text="20 Properties"
              />
            </div>
            <div className="col-12 col-md-8 ">
              <ImageWithOverlay
                imgSrc="/Images/FindProperties3.png"
                heading="
Bengaluru"
                text="14 Properties"
              />
            </div>
            <div className="col-12 col-md-4 ">
              <ImageWithOverlay
                imgSrc="/Images/FindProperties4.png"
                heading="
Pune"
                text="10 Properties"
              />
            </div>
          </div>
        </div>

        <div className="why-chose-us-cards-section mb-5 ">
          <div className="text-center ">
            <h2>Why Choose Us</h2>
            <p className="text-secondary">
              We provide full service at every step.
            </p>
          </div>
          <div className=" d-flex flex-wrap justify-content-center ">
            <div className="choose-card">
              <div className="post-logo-image">
                <img
                  src={"./Images/smart-post-logo.svg"}
                  width={"70px"}
                  alt=""
                />
              </div>
              <div className="resion-heading mb-1">Buy & Sell</div>
              <div className="resion-heading mt-0 mb-0">
                Fast & Free Listings
              </div>
              <div className="card-why-paragraph my-0">
                List your property for free and quickly connect with buyers and
                sellers.
              </div>

              <br />

              <div className="card-image-post">
                <div className="overlay"></div>
                <img
                  src={"./Images/smart-post-image.jpg"}
                  width={"100%"}
                  alt=""
                />
              </div>
              <div className="card-why-paragraph">
                <b>Explore Buying and Selling: </b> Post your property with ease
                and attract buyers faster.
              </div>
              <div className="see-all-listing-btn">
                See all listings <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div className="choose-card">
              <div className="post-logo-image">
                <img
                  src={"./Images/filter-post-logo.svg"}
                  width={"70px"}
                  alt=""
                />
              </div>
              <div className="resion-heading mb-1">Commercial Properties</div>
              <div className="resion-heading mt-0 mb-0">
                Prime Business Spaces
              </div>
              <div className="card-why-paragraph my-0">
                Easily find and lease the perfect commercial space.
              </div>
              <br />

              <div className="card-image-post">
                <div className="overlay"></div>
                <img src={"./Images/filter-image.jpg"} width={"100%"} alt="" />
              </div>
              <div className="card-why-paragraph">
                Always updated, so you never miss top opportunities.
              </div>
              <div className="see-all-listing-btn">
                See all listings <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div className=" choose-card">
              <div className="post-logo-image">
                <img
                  src={"./Images/getsupport-icon.png"}
                  width={"70px"}
                  alt=""
                />
              </div>
              <div className="resion-heading mb-1">Rentals & Co-Living</div>
              <div className="resion-heading mt-0 mb-0">Effortless Rentals</div>
              <div className="card-why-paragraph my-0">
                Discover rental and co-living spaces that fit your lifestyle
                with no fees.
              </div>
              <br />

              <div className="card-image-post">
                <div className="overlay"></div>
                <img
                  src={"./Images/get-support-lady.png"}
                  width={"100%"}
                  alt=""
                />
              </div>
              <div className="card-why-paragraph">
                <b> Explore Comfortable Rentals: </b>: Updated options to find
                your ideal home.
              </div>
              <div className="see-all-listing-btn">
                See all listings <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
          </div>
        </div>


        <div className="section-twenty mt-5 ">
        <div className="text-center mt-5">
          <h2>Testimonials</h2>
          <p className="text-secondary">Here could be a nice sub title</p>
        </div>

            <div>
              <TestomonialSlider sliderTestmonialProductService={sliderTestmonialProductService} />
            </div>
          </div>


          <div className=" articles-and-tips mt-5 ">
            <div className="row">
            <div className="text-center mt-5 ">
            <h2>Articles & Tips</h2>
            <p className="text-secondary">
              We provide full service at every step.
            </p>
          </div>
                {articles.map((article, index) => (
                    <div className="col-md-4 mb-3 mt-3" key={index}>
                        <div className="card h-100">
                            <img src={article.imgSrc} className="card-img-top" alt={article.title} />
                            <div className="card-body">
                                <h5 className="card-title text-danger mb-3">{article.title}</h5>
                                <p className="card-text">{article.text}</p>
                       

                                <div className="profile-date-container mb-3">
                                    <div className="profile-info">
                                        <img src={article.profileImgSrc} alt="Profile" />
                                        <span>{article.author}</span>
                                    </div>
                                    <div className="date">{article.date}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>



        <div className="container main-cont  pt-0 " style={{paddingTop:"58px !important"}}>
          {/* section trusted companies */}
          <div className="section-seventeen ">
          <div className="text-center mt-5">
          <h2>Our Partners</h2>
          <p className="text-secondary">We only work with the best companies around the globe</p>
        </div>
            <div>
              <TrustedCompaniesSlider />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
