import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider'

const FeaturesPropertySlider = () => {
        // for slider three 
const sliderThreeProductService = [
    {
      name: "Prestige Rainbow Waters",
      url: "./Images/three1.jpg",
      group_name:"Prestige Group",
      apartments:"4, 5 BHK Apartments",
      location:"Rai Durg, Hyderabad",
      price:"Rs 5.45 Cr - Rs 8.80 Cr"

    },
    {
        name: "Candeur Twins",
        url: "./Images/three2.jpg",
        group_name:"Candeur Developers And B...",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Miyapur, Hyderabad",
        price:"Rs 85.6 L - Rs 66.66 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/three3.jpg",
        group_name:"Pacifica Companies",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/three4.jpg",
        group_name:"Pacifica Companies",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/three5.jpg",
        group_name:"Pacifica Companies",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/three6.jpg",
        group_name:"Pacifica Companies",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
     
     
  ];
  
  const sliderThreeresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderThreeproductTemplate = (product) => {
    return (
      <div className="primeProject-slider-card border">
        
     
          <div className="rounded-image">
            <img src={product.url} width={'100%'} height={'180px'} alt="" />
          </div>
         <div  className='p-3 pb-5'>
         <div className='h5-heading'><h5>{product.name}</h5></div>
          <div className='group-name'>{product.group_name}</div>
          <div className='apartment mt-2'>{product.apartments}</div>
          <div className='location'>{product.location}</div>
          <div className='price mt-2'>{product.price}</div>
         </div>
      
      </div>
    );
  };
  
  return (
    <>
             <Slider ProductService={sliderThreeProductService} responsiveOptions={sliderThreeresponsiveOptions} productTemplate={sliderThreeproductTemplate}/>

    </>
  )
}

export default FeaturesPropertySlider
