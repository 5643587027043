import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';

const VisionarySlider = () => {
            // for slider fourth 
const sliderFourthProductService = [
    {
      name: "Prestige Rainbow ",
      url: "./Images/features-1.jpg",
      group_name:"Prestige Group",
      apartments:"4, 5 BHK Apartments",
      location:"Rai Durg, Hyderabad",
      price:"Rs 5.45 Cr - Rs 8.80 Cr",
      group_logo:"./Images/group-logo2.jpg"

    },
    {
        name: "Candeur Twins",
        url: "./Images/features-2.jpg",
        group_name:"Candeur Developers",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Miyapur, Hyderabad",
        price:"Rs 85.6 L - Rs 66.66 Cr",
        group_logo:"./Images/group-logo.jpg"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/features-3.jpg",
        group_name:"Pacifica Companies",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr",
        group_logo:"./Images/group-logo2.jpg"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/features-4.jpg",
        group_name:"Pacifica Companies",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr",
        group_logo:"./Images/group-logo.jpg"
  
      },
     
     
      
  ];
  
  const sliderFourthresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderFourthproductTemplate = (product) => {
    return (
      <div className="popularOwner-slider-card border">
        
     
          <div className="rounded-image border-none">
            <img src={product.url} width={'100%'} height={'240px'} alt="" />
          </div>
          <div className='d-flex justify-content-between gap-2 flex-wrap p-3 pb-4'>
            <div className='d-flex justify-content-between gap-3'>
             <div>  <img src={product.group_logo} alt="" /></div>
           <div className=''>
           <div className='h5-heading'><h5>{product.name}</h5></div>
          <div className='group-name'>{product.group_name}</div>
          <div className='apartment mt-2'>{product.apartments}</div>
          <div className='location'>{product.location}</div>
           </div>
            </div>
            <div className=''>
 
            <div className='price '>{product.price}</div>
          <div className='location mt-1'>Price</div>
          <div className='view-detail-btn mt-2'>View Details</div>
            </div>
          </div>
        
      
      </div>
    );
  };
  return (
    <>
          <Slider ProductService={sliderFourthProductService} responsiveOptions={sliderFourthresponsiveOptions} productTemplate={sliderFourthproductTemplate}/>
    </>
  )
}

export default VisionarySlider
