import React from "react";
import Slider from "../../../Components/Common/Slider/Slider";

const IndianCitiesSlider = () => {
  // for slider
  const sliderEightProductService = [
    {
      name1: "Gachibowli",
      name2: "Hitech City",
      url1: "./Images/Gachibowli.png",
      url2: "./Images/Hitech.png",
      group_name1: "15 Properties",
      group_name2: "18 Properties",
    },
    {
      name1: "Madhapur",
      name2: "Jubilee Hills",
      url1: "./Images/Madhapur.png",
      url2: "./Images/Jubile.png",
      group_name1: "25 Properties",
      group_name2: "65 Properties",
    },
    {
      name1: "Banjara Hills",
      name2: "Kondapur",
      url1: "./Images/Banjara.png",
      url2: "./Images/Kondapur.png",
      group_name1: "52 Properties",
      group_name2: "89 Properties",
    },
    {
      name1: "Manikonda",
      name2: "Kukatpally",
      url1: "./Images/Manikonda.png",
      url2: "./Images/Kukatpally.png",
      group_name1: "98 Properties",
      group_name2: "52 Properties",
    },
    {
      name1: "Miyapur",
      name2: "Nallagandla",
      url1: "./Images/Miyapur.png",
      url2: "./Images/Nallagandla.png",
      group_name1: "56 Properties",
      group_name2: "12 Properties",
    },
    {
      name1: "Tellapur",
      name2: "Narsingi",
      url1: "./Images/Tellapur.png",
      url2: "./Images/Narsingi.png",
      group_name1: "19 Properties",
      group_name2: "14 Properties",
    },
    {
      name1: "Chandanagar",
      name2: "Uppal",
      url1: "./Images/Chandanagar.png",
      url2: "./Images/Uppal.png",
      group_name1: "65 Properties",
      group_name2: "85 Properties",
    },
    {
      name1: "Shamshabad",
      name2: "Patancheru",
      url1: "./Images/Shamshabad.png",
      url2: "./Images/Patancheru.png",
      group_name1: "45 Properties",
      group_name2: "15 Properties",
    },
    {
      name1: "Bachupally",
      name2: "Adibatla",
      url1: "./Images/Bachupally.png",
      url2: "./Images/Adibatla.png",
      group_name1: "15 Properties",
      group_name2: "15 Properties",
    },
    {
      name1: "Bhuvanagiri",
      name2: "Sangareddy",
      url1: "./Images/Bhuvanagiri.png",
      url2: "./Images/Sangareddy.png",
      group_name1: "15 Properties",
      group_name2: "15 Properties",
    },
  ];

  const sliderEightresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderEightproductTemplate = (product) => {
    return (
      <div className="d-flex flex-column p-0 m-0 gap-4">
        <div className="Hot-Picks-slider-card d-flex align-items-center gap-2">
          <div className="rounded-image">
            <img src={product.url1} height={""} alt="" />
          </div>
          <div className="content-div-hot">
            <div className="h5-heading">
              <h5>{product.name1}</h5>
            </div>
            <div className="group-name mt-1">{product.group_name1} </div>
          </div>
        </div>
        <div className="Hot-Picks-slider-card d-flex align-items-center gap-2">
          <div className="rounded-image">
            <img src={product.url2} height={""} alt="" />
          </div>
          <div className="content-div-hot">
            <div className="h5-heading">
              <h5>{product.name2}</h5>
            </div>
            <div className="group-name mt-1">{product.group_name2}</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Slider
        ProductService={sliderEightProductService}
        responsiveOptions={sliderEightresponsiveOptions}
        productTemplate={sliderEightproductTemplate}
      />
    </>
  );
};

export default IndianCitiesSlider;
