import React, { useState } from "react";
import "./NavDropdowns.css";
import { PiArrowUpRightBold } from "react-icons/pi";
import { BsCheckCircle } from "react-icons/bs";

const SellDropdown = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleHover = (itemName) => {
    setSelectedItem(itemName);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Selling Guides":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Home Selling Guide</div>
              <div className="property-name">Sellers Guide</div>
              <div className="property-name">Explore Your Options</div>
              <div className="property-name">Selling Options</div>
              <div className="property-name">Prepare Your Home for Sale </div>
              <div className="property-name">Marketing Your Property</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
      case "Selling Tools":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Free Market Appraisal</div>
              <div className="property-name">Property Valuation</div>
              <div className="property-name"> Find an Agent</div>
              <div className="property-name">Online Estate Agents</div>
              <div className="property-name">Private House Sales</div>
                <div className="property-name"> Home Improvement Trends
                </div>              
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 25 Lac </div>
              <div className="popular-name">&#x20B9; 25 Lac - &#x20B9; 50 Lac</div>
              <div className="popular-name">&#x20B9; 50 LAc - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Dwarka </div>
              <div className="explore-name">Projects in Mumbai</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find Building</div>
              <div className="explore-name">Investment </div>
            </div>
            </div>
          </>
        );
      case "For Agents & Builders":
        return (
          <>
           <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">My Dashboard</div>
              <div className="property-name">Ad Packages</div>
              <div className="property-name">Developer Lounge</div>
              <div className="property-name">Sales Enquiry</div>
              <div className="property-name">Agent Support</div>
              <div className="property-name"> Marketing & Advertising Solutions</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
     
      case "Property Types":
        return (
          <>
                    <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Apartments for Sale</div>
              <div className="property-name">Houses for Sale</div>
              <div className="property-name">Villas for Sale</div>
              <div className="property-name">New Homes for Sale</div>
              <div className="property-name">Luxury Properties</div>
              <div className="property-name">Commercial Properties</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Noida </div>
              <div className="explore-name">Projects in Noida</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find an Agent</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
          </>
        );
        case "Market Trends":
            return (
              <>
               <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Current Market Trends</div>
              <div className="property-name">Local Market Analysis</div>
              <div className="property-name">Recent Sales Data</div>
              <div className="property-name">Property Value Estimations </div>
              <div className="property-name">Buyer Demand Statistics</div>
              <div className="property-name">Industry News & Updates</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Delhi </div>
              <div className="explore-name">Projects in Delhi</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find House</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
              </>
            );
      default:
        return (
            <>
               <div className="property-div">
            <div className="gray-header">Property Types</div>
            <div className="under-content">
              <div className="property-name">Post Property<span className="tag">FREE</span></div>
              <div className="property-name">My Dashboard</div>
              <div className="property-name">Sell/Rent Ad Packages</div>
              <div className="property-name">Check Your Home Value</div>
              <div className="property-name">Home Values</div>
              <div className="property-name">Help to Buy Schemes</div>
            </div>
            </div>
            <div className="popular-div">
            <div className="gray-header">budget</div>
            <div className="under-content">
              <div className="popular-name">Under &#x20B9; 50 Lac </div>
              <div className="popular-name">&#x20B9; 50 Lac - &#x20B9; 1 Cr</div>
              <div className="popular-name">&#x20B9; 1 Cr - &#x20B9; 1.5 Cr</div>
              <div className="popular-name">&#x20B9; 1.5 Cr - &#x20B9; 2 Cr</div>
              <div className="popular-name">Above &#x20B9; 2 Cr</div>
            </div>
            </div>
            <div className="explore-div">
            <div className="gray-header">Explore</div>
            <div className="under-content">
              <div className="explore-name">Localities in Noida </div>
              <div className="explore-name">Projects in Noida</div>
              <div className="explore-name">Investment Hotspot</div>
              <div className="explore-name">Find an Agent</div>
              <div className="explore-name">Investment</div>
            </div>
            </div>
            </>
        );
    }
  };

  return (
    <>
      <div className="under-buy-dropbox">
        <div className="d-flex gap-4">
          <div className="drop-header-div">
            <div className="header-item" onMouseEnter={() => handleHover("For Owners")}> For Owners</div>
            <div className="header-item" onMouseEnter={() => handleHover("Selling Guides")}> Selling Guides </div>
            <div className="header-item" onMouseEnter={() => handleHover("Selling Tools")}> Selling Tools</div>
            <div className="header-item" onMouseEnter={() => handleHover("For Agents & Builders")}>For Agents & Builders </div>
            <div className="header-item" onMouseEnter={() => handleHover("Property Types")}>
              {" "}
              Property Types 
              {/* <span className="tag">NEW</span> */}
            </div>
            <div className="header-item" onMouseEnter={() => handleHover("Market Trends")}> Market Trends</div>
          </div>
         
            {renderContent()}
         
          
          
       
        </div>
      </div>
    </>
  );
};

export default SellDropdown;
