
import { Router } from './Routes';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider } from 'primereact/api';

import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <PrimeReactProvider>
    <Router/>
    </PrimeReactProvider>
  );
}

export default App;
