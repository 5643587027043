import React, { useState } from "react";
import "./NavDropdowns.css";


const PagesDropdown = () => {


  const [selectedItem, setSelectedItem] = useState(null);

  const handleHover = (itemName) => {
    setSelectedItem(itemName);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "Contact & Support":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Support
              </div>
              <div className="header-item">Inquiries</div>
              <div className="header-item">Feedback</div>
              <div className="header-item">Addresses</div>
              <div className="header-item">Phone Numbers</div>
              <div className="header-item">Emails</div>

            </div>

          </>
        );
      case "FAQs & User Guide":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Buyer FAQs</div>
              <div className="header-item">Seller FAQs</div>
              <div className="header-item">Renting FAQs</div>
              <div className="header-item">Account Setup</div>
              <div className="header-item">Troubleshooting</div>
              <div className="header-item">How it works</div>

            </div>


          </>
        );
      case "Privacy & Terms":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Privacy Policy
              </div>
              <div className="header-item">Data Collection</div>
              <div className="header-item">User Agreement</div>
              <div className="header-item">Service Terms</div>
              <div className="header-item">Usage Guidelines</div>
              <div className="header-item">Policy Updates</div>

            </div>


          </>
        );

      case "Resources":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Articles
              </div>
              <div className="header-item">News</div>
              <div className="header-item">Guides</div>
              <div className="header-item">Research</div>
              <div className="header-item">Tools</div>
              <div className="header-item">Calculators</div>

            </div>

          </>
        );
      case "Blog":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Latest Posts
              </div>
              <div className="header-item">Market Insights
              </div>
              <div className="header-item">Home Tips</div>
              <div className="header-item">Investment Advice</div>
              <div className="header-item">Community</div>
              <div className="header-item">Experts</div>

            </div>

          </>
        );
      default:
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Company Overview
                {/* <span className="tag">FREE</span> */}
              </div>
              <div className="header-item">Mission</div>
              <div className="header-item">Team
              </div>
              <div className="header-item">Locations</div>
              <div className="header-item">Press</div>
              <div className="header-item">Careers</div>

            </div>


          </>
        );
    }
  };
  const renderContent1 = () => {
    switch (selectedItem) {
      case "Events":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Upcoming Events
                <span className="tag">NEW</span></div>
              <div className="header-item">Past Events</div>
              <div className="header-item">Webinars
              </div>
              <div className="header-item">Workshops</div>
              <div className="header-item">Conferences</div>
              <div className="header-item">Registration</div>

            </div>



          </>
        );
      case "Partners":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Partner Programs</div>
              <div className="header-item">Affiliates
              </div>
              <div className="header-item">Alliances</div>
              <div className="header-item">Benefits
              </div>
              <div className="header-item">Join Partner    </div>
              <div className="header-item">Directory</div>

            </div>


          </>
        );
      case "Investor Relations":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Financial Reports       </div>
              <div className="header-item">Investor Updates</div>
              <div className="header-item">Governanc          </div>
              <div className="header-item">Stock Info        </div>
              <div className="header-item">Annual Reports    </div>
              <div className="header-item">Investor Contact  </div>

            </div>



          </>
        );

      case "Media Center":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Press Releases             </div>
              <div className="header-item">Media Kit</div>
              <div className="header-item">Brand Assets</div>
              <div className="header-item">Media Contacts</div>
              <div className="header-item">News Coverage</div>
              <div className="header-item">Inquiries</div>

            </div>

          </>
        );
      case "Testimonials & Community":
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Reviews
              </div>
              <div className="header-item">Success Stories
              </div>
              <div className="header-item">Feedback
              </div>
              <div className="header-item">Video Testimonials</div>
              <div className="header-item">Case Studies</div>
              <div className="header-item">Submit Review</div>

            </div>

          </>
        );
      default:
        return (
          <>
            <div className="drop-header-div2">

              <div className="header-item">Subscribe
              </div>
              <div className="header-item">Editions</div>
              <div className="header-item">Archive</div>
              <div className="header-item">Reports</div>
              <div className="header-item">Insights</div>
              <div className="header-item">Unsubscribe</div>

            </div>



          </>
        );
    }
  };

  return (
    <>
      <div className="under-buy-dropbox">
        <div className="d-flex gap-4">
          <div className="drop-header-div">
            <div className="header-item" onMouseEnter={() => handleHover("About Us")}> About Us              </div>

            <div className="header-item" onMouseEnter={() => handleHover("Contact & Support")}> Contact & Support </div>
            <div className="header-item" onMouseEnter={() => handleHover("FAQs & User Guide")}> FAQs & User Guide</div>
            <div className="header-item" onMouseEnter={() => handleHover("Privacy & Terms")}>Privacy & Terms </div>
            <div className="header-item" onMouseEnter={() => handleHover("Resources")}>
              {" "}
              Resources
              {/* <span className="tag">NEW</span> */}
            </div>
            <div className="header-item" onMouseEnter={() => handleHover("Blog")}> Blog</div>
          </div>

          {renderContent()}


          <div className="drop-header-div">
            <div className="header-item" onMouseEnter={() => handleHover("Newsletter")}> Newsletter</div>

            <div className="header-item" onMouseEnter={() => handleHover("Events")}> Events </div>
            <div className="header-item" onMouseEnter={() => handleHover("Partners")}> Partners</div>
            <div className="header-item" onMouseEnter={() => handleHover("Investor Relations")}>Investor Relations </div>
            <div className="header-item" onMouseEnter={() => handleHover("Media Center")}>
              {" "}
              Media Center
              {/* <span className="tag">NEW</span> */}
            </div>
            <div className="header-item" onMouseEnter={() => handleHover("Testimonials & Community")}> Testimonials & Community</div>
          </div>

          {renderContent1()}




        </div>
      </div>
    </>
  );
}

export default PagesDropdown;



