import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';
import { FaCheck } from 'react-icons/fa';

const TopSelectionsSlider = () => {
            // for slider sixth 
const sliderSixthProductService = [
    {
      name: "Prestige Rainbow Waters",
      url: "./Images/prestige.png",
      date:"Possession from June 2028",
      apartments:"4, 5 BHK Apartments",
      location:"Rai Durg, Hyderabad",
      price:"Rs 5.45 Cr - Rs 8.80 Cr"

    },
    {
        name: "Candeur Twins",
        url: "./Images/candeur.png",
        date:"Possession from June 2028",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Miyapur, Hyderabad",
        price:"Rs 85.6 L - Rs 66.66 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/Pacifica.png",
        date:"Possession from June 2028",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/Pacifica.png",
        date:"Possession from June 2028",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/Pacifica.png",
        date:"Possession from June 2028",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/Pacifica.png",
        date:"Possession from June 2028",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/Pacifica.png",
        date:"Possession from June 2028",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      {
        name: "Pacifica Hillcrest Phase 2",
        url: "./Images/Pacifica.png",
        date:"Possession from June 2028",
        apartments:"2, 3, 4 BHK Apartments",
        location:"Gachibowli, Hyderabad",
        price:"Rs 99.7 L - 2.33 Cr"
  
      },
      
  ];
  
  const sliderSixthresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderSixthproductTemplate = (product) => {
    return (
        <div className="top-selections-slider-card ">
        <div className="rounded-image-div" style={{backgroundImage: ` linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)),url(${product.url})`}}>
          {/* <img src={product.url} width={'100%'} alt="" /> */}
        <div className='content-div'>
            <div className='rera-tag'>
            <FaCheck />  RERA
            </div>
        <div className="started-date">{product.date}</div>
        </div>
        </div>
        <div className="bottom-content-div">
           
              <div className="h5-heading">
                <h5>{product.name}</h5>
              </div>
            
              <div className='d-flex gap-1'> <div className="apartment">{product.apartments} in</div> 
              <div className="location">{product.location}</div></div>
          
              <div className="price">{product.price}</div>
             
         
          </div>
      </div>
    );
  };
  return (
    <>
                   <Slider ProductService={sliderSixthProductService} responsiveOptions={sliderSixthresponsiveOptions} productTemplate={sliderSixthproductTemplate}/>

    </>
  )
}

export default TopSelectionsSlider
