import React, { useState } from "react";
import "./Footer.css";
import { FaLocationArrow, FaLocationDot, FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdMail, MdOutlineWatchLater } from "react-icons/md";
const Footer = () => {
  const [isReadMore, setIsReadMore] = useState(true);
  const text = `SFTAREA operates solely as a platform to facilitate the advertisement of properties by sellers for potential customers/buyers/users. As such, SFTAREA.com is not directly involved in, nor has any control over, any transactions that occur between sellers and customers/buyers/users`;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="footer-container">
        <div className="container">
          <div className="row mx-0 px-0">
            <div className="col-sm-12  col-lg-4 col-sm-6">
              <div className="footer-right-box pe-3 pb-3">
                <div className="top-head d-flex flex-column gap-3">
                  <div className="brand-image d-flex align-items-center gap-3 ">
                    <img src="/Images/Brandicon3.png" width={"45px"} alt="" />
                    <h3 className=" brand-name-gold text-nowrap">SFTAREA</h3>
                  </div>
                  <div className="connected-section mt-md-2 mt-3  d-flex flex-column ">
                    <p className="Company-description">
                      Sftarea.com redefines real estate in India, seamlessly
                      connecting buyers, sellers, renters, and co-living
                      enthusiasts through a transparent and user-friendly
                      platform. We empower you to engage with property markets
                      confidently and efficiently.
                    </p>
                    <p className="mt-2 mb-1 d-flex align-items-start  gap-2">
                      <span className="mt-1">
                        {" "}
                        <FaLocationDot size={21} />
                      </span>
                      <span className="">
                        {" "}
                        PLOT NO.7/A, Aditya Arcade, Suraksha Enclave,
                        Chandanagar, Hyderabad 500050.{" "}
                      </span>
                    </p>
                    <p className="mt-3 mb-1 d-flex align-items-center   gap-2">
                      <span>
                        {" "}
                        <FaPhone size={21} />
                      </span>
                      <span className=""> +1800 41 99099</span>
                    </p>
                    <p className="mt-3 mb-1 d-flex align-items-center   gap-2">
                      <span>
                        {" "}
                        <MdMail size={21} />{" "}
                      </span>
                      <span className=""> sftareaquery@gmail.com</span>
                    </p>
                    {/* <p className='gold-para my-1'>Monday - Saturday</p> */}
                    <p className="mt-3 mb-3 d-flex align-items-center   gap-2">
                      {" "}
                      <span>
                        <MdOutlineWatchLater size={21} />
                      </span>{" "}
                      9:30 AM to 6:30 PM (Mon-Sun)
                    </p>
                  </div>
                </div>
                <div className="social-media-div">
                  <Link>
                    {" "}
                    <img src="/Images/linkedin.png" width={"40px"} alt="" />
                  </Link>
                  <Link>
                    {" "}
                    <img src="/Images/twitter.png" width={"40px"} alt="" />
                  </Link>
                  <Link>
                    {" "}
                    <img src="/Images/facebook.png" width={"40px"} alt="" />
                  </Link>
                  <Link>
                    {" "}
                    <img src="/Images/instagram.png" width={"40px"} alt="" />
                  </Link>
                  <Link>
                    {" "}
                    <img src="/Images/p-logo.png" width={"40px"} alt="" />
                  </Link>
                  <Link>
                    {" "}
                    <img src="/Images/trend.png" width={"40px"} alt="" />
                  </Link>
                  {/* <Link> <img src="/Images/youtube.png"   width={'48px'}   alt="" /></Link> */}
                </div>
                {/* <form onSubmit={handleSubmit} className='footer-form'> 
<input type="text" placeholder='Enter Your Email' />
<div className='d-flex gap-1 justify-content-center'>
<button className='white-btn'> Subscribe Now</button>
</div>
</form> */}

                <hr />
                <h5>Download the SFTAREA App</h5>
                <div className="appstore-div mb-2">
                  <img src="/Images/playstore.png" alt="" />
                  <img src="/Images/appstoreIcon.png" alt="" />
                </div>
                {/* <h5 className=''>Download the SFTAREA App
 </h5> */}
                {/*  <p className='mt-3 mb-1'> Explore Real Estate on the Go
 </p>*/}
                {/* <div className='store-down-text'>
Usage of SFTArea.com to upload content showing area in non standard units or which enables targeting by religion/community/caste/race is prohibited. Please report inappropriate content by writing to us at  <Link to=''> Report abuse</Link>
</div> */}
                {/*<div className='footer-down-text text-start'>
Access properties, manage listings,
and stay updated with real-time alerts.
All from your mobile device.
</div>*/}
              </div>
            </div>
            <div className="col-md-6  col-lg-2 col-6 d-flex flex-column gap-3">
              <h5 className="mt-lg-0  mt-4">About Us</h5>
              <div className="line"></div>
              <Link to={""}>Company Info</Link>
              <Link to={""}>Our Solutions</Link>
              <Link to={""}>Price Insights </Link>
              <Link to={""}>Investments </Link>
              <Link to={""}>Property Managements</Link>
              <Link to={""}>Trend Insights </Link>
              <Link to={""}>Property Listing </Link>
              <Link to={""}>Problem Reporting </Link>
              <Link to={""}>Our Blog</Link>
              <Link to={""}>News & Articles</Link>
              <Link to={""}>Careers </Link>
              <Link to={""}>Complaints </Link>
              <Link to={""}>Feedback </Link>
              <Link to={""}>Get in Touch </Link>
            </div>
            <div className="col-lg-2 col-md-6  col-5  d-flex flex-column gap-3">
              <h5 className="mt-lg-0  mt-4">Property Services</h5>
              <div className="line"></div>
              <Link to={""}>Company Info</Link>
              <Link to={""}>Our Solutions</Link>
              <Link to={""}>Price Insights </Link>
              <Link to={""}>Investments </Link>
              <Link to={""}>Property Managements</Link>
              <Link to={""}>Trend Insights </Link>
              <Link to={""}>Property Listing </Link>
              <Link to={""}>Problem Reporting </Link>
              <Link to={""}>Our Blog</Link>
              <Link to={""}>News & Articles</Link>
              <Link to={""}>Careers </Link>
              <Link to={""}>Complaints </Link>
              <Link to={""}>Feedback </Link>
              <Link to={""}>Get in Touch </Link>
            </div>
            <div className="col-lg-2 col-md-6 col-6 d-flex mt-lg-0  mt-4 flex-column gap-3">
              <h5 className="mt-lg-0  mt-4">More Services</h5>
              <div className="line"></div>
              <Link to={""}>Interior Design </Link>
              <Link to={""}>Renew Contractors </Link>
              <Link to={""}>Loan Hub </Link>
              <Link to={""}>Legal Expert </Link>
              <Link to={""}>Property Care </Link>
              <Link to={""}>Valuation Advisors </Link>
              <Link to={""}>Design & Architectures </Link>
              <Link to={""}>Market Masterys </Link>
              <Link to={""}>Packers & Move </Link>
              <Link to={""}>Vasthu & Astrology </Link>
              <Link to={""}>Home Staging </Link>
              <Link to={""}>Construction Advisory </Link>
              <Link to={""}>Landscape Design </Link>
              <Link to={""}>Smart Home Automation </Link>
            </div>
            <div className="col-md-6 col-lg-2 col-6 mt-lg-0  mt-4 d-flex flex-column gap-3">
              <h5 className="mt-lg-0  mt-4">Resources & Guide</h5>
              <div className="line"></div>
              <Link to={""}>RERA Facts </Link>
              <Link to={""}>NRI Advice</Link>
              <Link to={""}>Market Trends </Link>
              <Link to={""}>Legal Tips </Link>
              <Link to={""}>Local Insights</Link>
              <Link to={""}>Owner's Guide</Link>
              <Link to={""}>Estate Care </Link>
              <Link to={""}>Future Trends</Link>
              <Link to={""}>Commercial Real Estate </Link>
              <Link to={""}>Government Initiatives</Link>
              <Link to={""}>Holiday Homes</Link>
              <Link to={""}>Property Rights</Link>
              <Link to={""}>Urban Growth</Link>
              <Link to={""}>First-Time Buyers</Link>
            </div>
          </div>
          <div className="row mt-2 mx-0 px-0">
            
            <div className="footer-down-text">
            <span>Responsible Use Policy: </span>
               <span className="text-light">SFTAREA.com</span> fosters a welcoming environment
              free from discrimination. We prohibit postings that use
              non-standard units or discriminate based on religion, community,
              caste, or race. For any concerns, please reach out to our{" "}
              <span className=" text-light">Moderation Team</span> to help us
              maintain these standards.
            </div>
          </div>

          <div className="row mx-0 px-0 mt-2 align-items-start">
            {/* <div className='footer-social-media-div d-flex gap-1 mt-1'>
<Link> <img src="/Images/linkedin.png"  width={'35px'} alt="" /></Link>
<Link> <img src="/Images/twitter.png"   width={'35px'}   alt="" /></Link>
<Link> <img src="/Images/facebook.png"  width={'35px'}   alt="" /></Link>
<Link> <img src="/Images/instagram.png" width={'35px'}   alt="" /></Link>
<Link> <img src="/Images/p-logo.png"    width={'35px'}   alt="" /></Link>
<Link> <img src="/Images/trend.png"     width={'35px'}   alt="" /></Link>

</div> */}
            <div className="multi-links d-flex flex-wrap gap-2 ">
              <Link to={""}>Privacy Policy &nbsp; | </Link>
              <Link to={""}>Terms & Conditions &nbsp; | </Link>
              <Link to={""}>Safety and Security &nbsp; | </Link>
              <Link to={""}>Help &nbsp; | </Link>
              <Link to={""}>Sitemap &nbsp; | </Link>
              <Link to={""}>Cookies &nbsp; | </Link>
              <Link to={""}>Legal &nbsp; | </Link>
              <Link to={""}>Unsubscribe &nbsp; | </Link>
              <Link to={""}>Testimonials &nbsp; | </Link>
              <Link to={""}>Sales Enquiry &nbsp; | </Link>
              <Link to={""}>Ads Enquiry </Link>
            </div>
          </div>

          <div className="row mt-0 mx-0 px-0">
          <div className="footer-down-text">
          <span>Disclaimer&nbsp;</span> 
              <span className="text-light">Intermediary Role: </span>
              {isReadMore ? text.slice(0, 209) : text}
              {isReadMore ? (
                <span
                  style={{ cursor: "pointer" }}
                  className="text-light "
                  onClick={toggleReadMore}
                >
                  {isReadMore ? " ...Read more" : " ...Show less"}
                </span>
              ) : (
                <>
                
                  <div className="footer-down-text">
                    <span className="text-light">Offers and Promotions: </span>
                    All offers, promotions, and discounts presented on SFTAREA.com
                    are provided by various builders and developers who have
                    chosen to advertise their products through our platform.
                    SFTAREA.com is not the seller of these properties nor does it
                    render any specific services related to the promotions. We do
                    not warrant, endorse, or assume responsibility for any of the
                    offers or services.
                  </div>
                  <div className="footer-down-text">
                    <span className="text-light">No Representations: </span>
                    SFTAREA.com does not make any representations or guarantees
                    regarding the accuracy, reliability, or validity of any offers
                    made on the site. Users should verify all promotional details
                    and terms directly with the advertisers.
                  </div>
                  <div className="footer-down-text mb-2">
                    <span className="text-light">Dispute Resolution: </span>
                    SFTAREA.com shall not be responsible or liable to mediate,
                    manage, or resolve any disputes or disagreements that arise
                    between the seller and the customer/buyer/user. It is the
                    responsibility of both parties involved in the transaction to
                    settle their disputes independently, without involving
                    SFTAREA.com in any capacity.
                  </div>{" "}
                </>
              )}
            </div>

          </div>
        </div>
        {/* <div className='footer-right-side p-0'>
            <div className='footer-right-box'>

              <div className='top-head d-flex  gap-3'>
                <div className='brand-image d-flex flex-column justify-content-center align-items-center'>
                  <img src="/Images/Brandicon2.png" width={'53px'} alt="" />
                  <h5 className='mt-2 brand-name-gold text-nowrap'>SFT Area</h5>
                </div>
                <div className='connected-section mt-md-1 mt-3  d-flex flex-column align-items-center'>
                  <p className='my-1 d-flex flex-wrap justify-content-center gap-3'><span>Connect with us</span>  <span>1800 41 99099</span></p>
                  <p className='gold-para my-1'>Monday - Saturday</p>
                  <p>(9:00AM to 11:00PM ist)</p>

                </div>
              </div>
              <div className='social-media-div'>
              <Link> <img src="/Images/linkedin.png"  width={'40px'} alt="" /></Link>
              <Link> <img src="/Images/twitter.png"   width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/facebook.png"  width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/instagram.png" width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/p-logo.png"    width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/trend.png"     width={'40px'}   alt="" /></Link>
              <Link> <img src="/Images/youtube.png"   width={'50px'}   alt="" /></Link>
              
              </div>
              <form onSubmit={handleSubmit} className='footer-form'> 
<input type="text" placeholder='Enter Email' />
<div className='d-flex gap-1 justify-content-center'>
  <button className='simple-btn'>Feedback</button>
  <button className='white-btn'> Subscribe Now</button>
</div>
              </form>
            </div>
            </div>
            <div className='footer-left-side pb-3'>
           <div className='row d-flex justify-content-between p-0 m-0'>
          <div className='col-lg-1 col-0 blank-div'></div>
            <div className='col-md-3 col-sm-7 d-flex flex-column gap-3'><h5 className=''>Our Company</h5>
            <Link to={''}>About SFTArea</Link>
            <Link to={''}>Our Services</Link>
            <Link to={''}>Meet Our Team</Link>
            <Link to={''}>Testimonials</Link>
            <Link to={''}>Career Opportunities</Link>
            <Link to={''}>SFTArea News</Link>
            <Link to={''}>Blog</Link>
            </div>
            <div className='col-md-3 col-sm-5  d-flex flex-column gap-3'><h5 className='mt-sm-0  mt-4'>Buying & Selling</h5>
            <Link to={''}>Buy Property</Link>
            <Link to={''}>Sell Property</Link>
            <Link to={''}>Investment Opportunities</Link>
            <Link to={''}>Market Insights</Link>
          
            </div>
            <div className='col-md-2  col-sm-7 d-flex flex-column gap-3'><h5 className='mt-md-0  mt-4'>Resources</h5>
            <Link to={''}>Buyer’s Guide</Link>
            <Link to={''}>Seller’s Guide</Link>
            <Link to={''}>FAQs</Link>
            <Link to={''}>Glossary of Terms</Link>
           
            </div>
            <div className='col-md-3 col-sm-5 d-flex flex-column gap-3'><h5 className='mt-md-0  mt-4'>Legal</h5>
            <Link to={''}>Terms & Conditions</Link>
            <Link to={''}>Privacy Policy</Link>
            <Link to={''}>Legal Disclaimer</Link>
        
            </div>
           </div>
            </div>
             */}
      </div>
    </>
  );
};

export default Footer;
