import React, { useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';
import './Login.css'
import { MdOutlineAlternateEmail, MdOutlinePhoneAndroid } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { Checkbox } from 'primereact/checkbox';
import { LuUser } from 'react-icons/lu';
import { PiUserListThin } from 'react-icons/pi';
import { Dropdown } from 'primereact/dropdown';
import { Link, useParams } from 'react-router-dom';

const LoginRegister = () => {
    let { tab } = useParams();
    const [loginPass, setLoginPass] = useState('');
    const [username, setUsername] = useState('');
    const [username2, setUsername2] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [signUpPass, setSignUpPass] = useState('');
    const [currentTab, setCurrentTab] = useState(tab);
    const [checked, setChecked] = useState(false);
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: 'Private', code: 'PR' },
        { name: 'Business', code: 'BN' },

    ];
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (


        <>
         
            <div className='Content_login'>
                <div className='mid_card'>
                    <div className='top_both_btn'>
                        <button className={currentTab === 'login' ? 'login-btn active' : 'login-btn'} onClick={() => handleTabChange("login")}>Login</button>
                        <button className={currentTab === 'register' ? 'register-btn active' : 'register-btn'} onClick={() => handleTabChange("register")}>Register</button>
                    </div>
                    <div className='down_white_card'>
                        <div className='continue_with_social_btn'>
                            <img src='https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/512px-Facebook_f_logo_%282021%29.svg.png?20210818083032' alt='' />
                            Continue with facebook
                        </div>
                        <div className='continue_with_social_btn'>
                            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 256 262">
                                <path fill="#4285F4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"></path>
                                <path fill="#34A853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"></path>
                                <path fill="#FBBC05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"></path>
                                <path fill="#EB4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"></path>
                            </svg>
                            Continue with Google
                        </div>
                        <div className='continue_with_social_btn'>
                        <img src='https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/apple-icon.png' alt='' />

                            Continue with Apple
                        </div>


                        <div className='d-flex align-items-center mt-2 mb-3 gap-3'>
                            <div className='horizontal_line'></div>
                            <div>or</div>
                            <div className='horizontal_line'></div>
                        </div>

                        {
                            currentTab === "login" && (
                                <form onSubmit={handleSubmit}>
                                    <div className='input_fields'>
                                        <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon bg-transparent">
                                                <MdOutlineAlternateEmail size={20} />
                                            </span>
                                            <InputText placeholder="Email or Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                        </div>
                                        <div className="p-inputgroup mt-3 flex-1">
                                            <span className="p-inputgroup-addon bg-transparent">
                                                <RiLockPasswordLine size={20} />
                                            </span>
                                            <Password placeholder="Password" value={loginPass} onChange={(e) => setLoginPass(e.target.value)} toggleMask />
                                        </div>

                                        <div className='d-flex justify-content-between mt-3'>
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="ingredient1" name="remember_me" invalid={!checked} onChange={(e) => setChecked(e.checked)} checked={checked}></Checkbox>
                                                <label htmlFor="ingredient9" style={{ fontSize: "16px", fontWeight: "400", color: "#2b2b2b" }} className="ml-2 ps-2">Remember me</label>
                                            </div>
                                            <div className='forgot-btn'>
                                                Forgot password?
                                            </div>
                                        </div>
                                        <button className='primary-btn d-flex justify-content-center mt-4 rounded-pill'>
                                            Login
                                        </button>
                                    </div>
                                </form>
                            )
                        }
                        {
                            currentTab === "register" && (
                                <form onSubmit={handleSubmit}>
                                    <div className='input_fields'>

                                        <div className="p-inputgroup flex-1">
                                            <span className="p-inputgroup-addon bg-transparent">
                                                <LuUser size={20} />
                                            </span>
                                            <InputText placeholder="Username*" value={username2} onChange={(e) => setUsername2(e.target.value)} />
                                        </div>
                                        <div className="p-inputgroup flex-1 mt-3">
                                            <span className="p-inputgroup-addon bg-transparent">
                                                <MdOutlineAlternateEmail size={20} />
                                            </span>
                                            <InputText placeholder="E-mail*" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div className="p-inputgroup flex-1 mt-3">
                                            <span className="p-inputgroup-addon bg-transparent">
                                                <MdOutlinePhoneAndroid size={20} />
                                            </span>
                                            <InputNumber value={number} onValueChange={(e) => setNumber(e.value)} maxLength={10} useGrouping={false} placeholder="Phone" />
                                        </div>
                                        <div className="flex align-items-center mt-3">
                                            <Checkbox inputId="ingredient1" name="whatsapp" invalid={!checked1} onChange={(e) => setChecked1(e.checked)} checked={checked1}></Checkbox>
                                            <label htmlFor="ingredient9" style={{ fontSize: "16px", fontWeight: "400", color: "#2b2b2b" }} className="ml-2 ps-2 color-primary">Enable WhatsApp Communication</label>
                                        </div>

                                        <div className="p-inputgroup mt-3 flex-1">
                                            <span className="p-inputgroup-addon bg-transparent">
                                                <RiLockPasswordLine size={20} />
                                            </span>
                                            <Password placeholder="Create Password" value={signUpPass} onChange={(e) => setSignUpPass(e.target.value)} toggleMask />
                                        </div>
                                        <div className="p-inputgroup flex-1 mt-3">
                                            <span className="p-inputgroup-addon bg-transparent">
                                                <PiUserListThin size={24} />
                                            </span>
                                            <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                                placeholder="Select type    " className="w-full md:w-14rem" />
                                        </div>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <div className="flex align-items-center">
                                                <Checkbox inputId="ingredient1" name="remember_me" invalid={!checked2} onChange={(e) => setChecked2(e.checked)} checked={checked2}></Checkbox>
                                                <label htmlFor="ingredient9" style={{ fontSize: "16px", fontWeight: "400", color: "#2b2b2b" }} className="ml-2 ps-2 color-primary">I accept the
                                                    <Link to="" className='color-primary pointer ps-2'>Privacy Policy</Link></label>
                                            </div>

                                        </div>
                                        <button className='primary-btn d-flex justify-content-center mt-4 rounded-pill'>
                                            Register
                                        </button>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                </div>
            </div>
          
        </>
    )
}

export default LoginRegister
