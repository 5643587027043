import React, { useEffect, useState } from 'react';
import './ImageSlider.css';
import { Link } from 'react-router-dom';

function ImageSlider({ imgs }) {
  const [wordData, setWordData] = useState(imgs[0]);
  const [val, setVal] = useState(0);
  const isMobile = window.innerWidth <= 1200;
  // Function to handle changing the active index
  
  
  const handleClick = (index) => {
    setVal(index);
    const wordSlider = imgs[index];
    setWordData(wordSlider);
  };

  useEffect(() => {
    // console.log(imgs)
    const interval = setInterval(() => {
      setVal((prevVal) => (prevVal === imgs.length - 1 ? 0 : prevVal + 1));
      setWordData(imgs[val]);
    }, 3000);

    return () => clearInterval(interval);
  }, [val, imgs]);

  return (
    <div className="main">
      {imgs?.length ===3?<div className="flex_row d-flex gap-3 me-2">
        {imgs.map((data, i) => (
          <div className="thumbnail" key={i}>
             <div   className={wordData.id === i ? `clicked image-container` : 'image-container'}  onClick={() => handleClick(i)}>
    <img
      src={data.value}
      height="100"
      width="170"
      alt=''
    />
    <div className="image-overlay"></div> {/* Add a div for the overlay */}
  </div>
            <p style={{ width: '165px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {data.group_name}
            </p>
          </div>
        ))}
      </div>:<>
      <div className="blank-height-div"></div>
      </>}
    <div className='white-above-box shadow-sm'>
    
           <div className='d-flex gap-3'>
           <div className="brand-logo-signature">
              <img src={wordData.brand_logo} width={'60px'} alt="" />
            </div>
            <div className="brand-name">
              <h6 className="m-0 ">{wordData.name}</h6>
              <Link>View Projects</Link>
            </div>
           </div>
          
    </div>
      <div className="spotlight-container-slider-card">
        <div className="left-spotlight">
          <div className="signature-brand">
            <div className="brand-logo-signature">
              <img src={wordData.brand_logo} width={'60px'} alt="" />
            </div>
            <div className="brand-name">
              <h6 className="m-0 ">{wordData.name}</h6>
              <Link>View Projects</Link>
            </div>
          </div>
          <div className="brand-name d-flex flex-column gap-1">
            <h5 className="mb-0" style={{ width: '228px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {wordData.group_name}
            </h5>
            <p>{wordData.location}</p>
          </div>
          <div className="price-location d-flex flex-column gap-1">
            <h5 className="mb-0">{wordData.price}</h5>
            <p>{wordData.apartments}</p>
          </div>
          <button className="primary-btn">Contact</button>
        </div>
        <div className="right-spotlight" style={{ backgroundImage:isMobile? `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(${wordData.value})`:`url(${wordData.value})`,backgroundSize: 'cover',backgroundPosition: 'center',}}>
<div className="spotlight-image-content">
  <div className=''>
    <div className='brand-image-name'>
  <h6 className="mb-0">
              {wordData.group_name}
            </h6>
  </div>
  <div className="location-data">
     <p>{wordData.location}</p>
  </div>
  <div className="apartment">
      <p>{wordData.apartments}</p>
  </div>
  <div className="price-value"> <h6 className="mb-0">{wordData.price}</h6></div> </div>
  <div className='primary-btn'>
  Contact
</div>
 
</div>
{imgs?.length ===3? <>
  <div className="carousel-indicators">
  <span className={`indicator ${val === 0 ? 'active' : ''}`} onClick={() => handleClick(0)}></span>
  <span className={`indicator ${val === 1 ? 'active' : ''}`} onClick={() => handleClick(1)}></span>
  <span className={`indicator ${val === 2 ? 'active' : ''}`} onClick={() => handleClick(2)}></span>
</div> 
</>
 :""

}

</div>
      </div>
    </div>
  );
}

export default ImageSlider;
