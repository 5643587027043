import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaAngleDown, FaUser } from "react-icons/fa6";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { RiCloseFill } from "react-icons/ri";
import { GoTriangleDown, GoTriangleRight } from "react-icons/go";
import { IoArrowBack, } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { PiHeadsetFill } from "react-icons/pi";
import { MdCall, MdPhoneCallback } from "react-icons/md";
import { Sidebar } from "primereact/sidebar";
import {

  FaBars,
  FaCaretRight,
  FaChevronDown,
  FaChevronUp,
  FaUserCircle,
} from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import BuyDropdown from "../NavDropdowns/BuyDropdown";
import RentDropdown from "../NavDropdowns/RentDropdown";
import SidebarMenu from "../Common/SidebarMenu/SidebarMenu";
import SellDropdown from "../NavDropdowns/SellDropdown";
import CommercialDropdown from "../NavDropdowns/CommercialDropdown";
import MarketInsightsDropdown from "../NavDropdowns/MarketInsightsDropdown";
import ServiesDropdowns from "../NavDropdowns/ServiesDropdowns";
import PagesDropdown from "../NavDropdowns/PagesDropdown";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleUnderRight, setVisibleUnderRight] = useState(false);
  const [visibleRightMenu, setVisibleRightMenu] = useState(false);
  const [headerValue, setHeaderValue] = useState("Buying")
  const Navigate = useNavigate()
  // const onHide = () => {
  //   setVisibleRight(false);
  //   document.body.classList.remove('no-scroll'); // Remove the class to enable scrolling
  // };

  // const onShow = () => {
  //   setVisibleRight(true);
  //   document.body.classList.add('no-scroll'); // Add the class to disable scrolling
  // };

  // const onHideMenu = () => {
  //   setVisibleRightMenu(false);
  //   document.body.classList.remove('no-scroll'); // Remove the class to enable scrolling
  // };

  // const onShowMenu = () => {
  //   setVisibleRightMenu(true);
  //   document.body.classList.add('no-scroll'); // Add the class to disable scrolling
  // };
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        // Change 50 to the desired offset
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    transition: "background-color 0.3s",
    width: scrolled ? "100% !important" : "100% !important",
    backgroundColor: scrolled ? "#fff" : "transparent", // Change colors as needed
    zIndex: 1000,

    boxShadow: scrolled ? "none" : "",
    color: scrolled ? "#2B2B2B" : "#fff",
    borderBottom: scrolled ? "1px solid #ebecf0" : "",
  };

  const nearbyCities = ["New Delhi", "Gurugram", "Greater Noida", "Ghaziabad"];
  const popularCities = [
    "Ahmedabad",
    "Indore",
    "Navi Mumbai",
    "Bangalore",
    "Jaipur",
    "New Delhi",
    "Chennai",
    "Kolkata",
    "Noida",
    "Gurgaon",
    "Lucknow",
    "Pune",
    "Hyderabad",
    "Mumbai",
    "Thane",
  ];
  const otherCities = [
    "Agra",
    "Ahmadnagar",
    "Ahmadnagar",
    "Aurangabad",
    "Aluva",
    "Allahabad",
    "Amritsar",
    "Bhiwadi",
    "Bareilly",
    "Bhubaneswar",
    "Bhiwandi",
    "Badlapur",
    "Bhopal",
    "Belgaum",
    "Chengalpattu",
    "Chandigarh",
    "Coimbatore",
    "Dehradun",
    "Durgapur",
    "Ernakulam",
    "Erode",
    "Faridabad",
    "Ghaziabad",
    "Gorakhpur",
    "Greater Noida",
    "Guntur",
    "Guwahati",
    "Gwalior",
    "Goa",
    "Haridwar",
    "hosur",
    "Hubli",
    "Jabalpur",
    "Jalandhar",
    "Jammu",
    "Jamshedpur",
    "Jodhpur",
    "kalyan",
    "Kannur",
    "kanpur",
    "Khopoli",
    "Kochi",
    "Kodaikanal",
    "kottayam",
    "Kozhikode",
    "Lonavala",
    "Ludhiana",
    "Madurai",
    "Mangalore",
    "Mohali",
    "Mysore",
    "Nagpur",
    "Nainital",
    "Nanded",
    "Nashik",
    "Navsari",
    "Nellore",
    "Newtown",
    "Ooty",
    "Pallakkad",
    "Palghar",
    "Panchkula",
    "Patiala",
    "Patna",
    "Raipur",
    "Ranchi",
    "Salem",
    "Satara",
    "Shimla",
    "Solapur",
    "Thanjavur",
    "Tirupati",
    "Tirupur",
    "Udaipur",
    "Udupi",
    "Vadodara",
    "Vapi",
    "Varanasi",
    "Vridavan",
    "Zirakpur",
  ];

  const CityList = ({ title, cities }) => (
    <div>
      <div className="location-type-heading">{title}</div>
      <div className="row">
        {cities.map((city, index) => (
          <div key={index} className="col-md-2">
            <div className="location-name">{city}</div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <div className="main-nav-container">
        <div className="top-navbar">
          <div className="w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center gap-2">
              {" "}
              <div className="brand-icon" onClick={() => Navigate("/")}>
                <img src="/Images/Brandicon3.png" alt="" />
                <span className="sft-text">SFTAREA</span>
              </div>
              <div className="drop-location">
                Hyderabad <FaChevronDown size={18} className="ps-1 down-icon" />{" "}
                <FaChevronUp size={18} className="ps-1 up-icon" />
                <div className="location-dropdown-card">
                  <div className="country-name">
                    <FaLocationDot size={18} /> India
                  </div>
                  <CityList title="Nearby Cities" cities={nearbyCities} />
                  <CityList title="Popular Cities" cities={popularCities} />
                  <CityList title="Other Cities" cities={otherCities} />
                </div>
              </div>
            </div>
            <div className="d-flex gap-2  gap-lg-3 gap-xxl-4 align-items-center">
              <Link to={"/premium_access"}
                className="nav-item">
                Premium Access
              </Link>
              <Link to={"/agents_builders"} className="nav-item">
                Agents-Builders
              </Link>
              <Link to={"/lead_market"} className="nav-item">
                Lead-Market
              </Link>
              <Link to={"/advertise"} className="nav-item">
                Advertise
              </Link>
              <Link to={""} className="nav-item">
                {" "}
                Download App
              </Link>
              <button className="nav-post-btn d-flex justify-content-between" onClick={() => Navigate("/list_property")}>
                List Property <div className="free-span">Free</div>
              </button>
              <button className="contact-btn">
                <PiHeadsetFill />
                <div className="main-contact-card">
                  <div className="contact-card">
                    <h6 className="mb-0">CONTACT US</h6>
                    <div className="first-call call-details py-2 px-4">
                      <div className="phone-icon">
                        <MdCall size={20} />
                      </div>
                      <div className="">
                        <p>Toll Free | 9 AM - 8 PM IST</p>
                        <div className="number">1800-41-99099</div>
                      </div>
                    </div>
                    <div className="second-call d-flex align-items-center justify-content-between px-4">
                      <div className="call-details py-2 ">
                        <div className="phone-icon">
                          <MdCall size={20} />
                        </div>
                        <div className="">
                          <p>Toll Free | 9 AM - 8 PM IST</p>
                          <div className="number">1800-41-99099</div>
                        </div>
                      </div>
                      <div className="another-card-icon">
                        <FaCaretRight />
                      </div>
                      <div className="second-call-card py-4">
                        <div className="heading-card px-4">
                          International Numbers
                        </div>
                        <div className="first-call call-details py-2 px-4  mt-2">
                          <div className="phone-icon">
                            <MdCall size={20} />
                          </div>
                          <div className="">
                            <p>United Arab Emirated</p>
                            <div className="number p-0 m-0">800 18 2956</div>
                          </div>
                        </div>
                        <div className="first-call call-details py-2 px-4 ">
                          <div className="phone-icon">
                            <MdCall size={20} />
                          </div>
                          <div className="">
                            <p>United Arab Emirated</p>
                            <div className="number p-0 m-0">800 18 2956</div>
                          </div>
                        </div>
                        <div className="first-call call-details py-2 px-4 ">
                          <div className="phone-icon">
                            <MdCall size={20} />
                          </div>
                          <div className="">
                            <p>United Arab Emirated</p>
                            <div className="number p-0 m-0">800 18 2956</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 mt-3">
                      <div className="request-call-btn">
                        <MdPhoneCallback size={19} /> Request a Call Back
                      </div>
                    </div>
                    <div className="faq-text px-4 py-2">
                      To check all the FAQ <span>click here</span>
                    </div>
                  </div>
                </div>
              </button>
              <div className="d-flex user-box align-items-center gap-1">
                {" "}
                <div className="login-btn">
                <FaUser />
                  <span className="over-red"></span>{" "}
                </div>{" "}
                <FaChevronDown size={16} className="down-icon" />{" "}
                <FaChevronUp size={16} className="up-icon" />
                <div className="main-login-card">
                  <div className="login-card">
                    {/* <h6>Login / Register</h6> */}
                    <div className="small-heading">My Actions</div>
                    <div className="d-flex ps-3 pt-2 flex-column gap-2">
                      <span className="text-small">Recent Searches</span>
                      <span className="text-small">Recent Views</span>
                      <span className="text-small">Favorites List</span>
                      <span className="text-small">Reached Out</span>
                    </div>
                    <hr />
                    <div className="footer-login">
                      <div className="login-button" onClick={() => Navigate("/login-register/login")}>Login</div>
                      <p className="new-user-para">
                        New to SFTArea ? <Link to={"/login-register/register"}>Sign Up</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="sidebar-btn-menu"
                onClick={
                  () => setVisibleRight(true)
                  // onShow
                }
              >
                {" "}
                <FaBars size={18} />
                {/* <img src="/Images/login-user-icon2.png" alt="" width={"25px"} /> */}
                Menu
              </button>
              <button
                className="mobile-sidebar-btn"
                onClick={

                  () => setVisibleRightMenu(true)
                  // onShowMenu
                }
              >
                {" "}
                <FaBars size={18} />

              </button>
            </div>
            {/* sidebar for main-navbar hembergur menu for  multiple tabs ----- */}
            <Sidebar
              visible={visibleRight}
              closeIcon={<RiCloseFill size={25} />}
              position="right"
              header={
                <div className="brand-icon-sidebar" onClick={() => Navigate("/")}>
                  <img src="/Images/Brandicon4.png" alt="" />
                  <span className="">SFTAREA</span>
                </div>
              }
              onHide={
                () => setVisibleRight(false)
                // onHide
              }
            >
              <div className="sidebar-content-body pb-1 ps-3">

                <div className="login-ragister-btn" onClick={() => Navigate("/login-register/login")}>
                  <FaUserCircle size={21} /> login / register
                </div>
                <div className="divider-down"></div>
                <div className="post-property-btn" onClick={() => Navigate("/list_property")} >
                  List Your Property <span className="tag">FREE</span>
                </div>
                <div className="divider-down"></div>
                <div className="my-activity-div">
                  Property Types
                </div>
                <div className="simple-sidebar-btn" onClick={() => {
                  setVisibleUnderRight(true);
                  setHeaderValue("Buying");
                }}>
                  {" "}
                  <GoTriangleRight size={18} /> Buying
                </div>
                <div className="simple-sidebar-btn" onClick={() => {
                  setVisibleUnderRight(true);
                  setHeaderValue("Selling");
                }}>
                  {" "}
                  <GoTriangleRight size={18} /> Selling
                </div>
                <div className="simple-sidebar-btn" onClick={() => {
                  setVisibleUnderRight(true);
                  setHeaderValue("Commercial");
                }}>
                  {" "}
                  <GoTriangleRight size={18} /> Commercial
                </div>
                <div className="simple-sidebar-btn" onClick={() => {
                  setVisibleUnderRight(true);
                  setHeaderValue("Renting");
                }}>
                  {" "}
                  <GoTriangleRight size={18} /> Renting
                </div>
                <div className="simple-sidebar-btn" onClick={() => {
                  setVisibleUnderRight(true);
                  setHeaderValue("PG/Co-living");
                }}>
                  {" "}
                  <GoTriangleRight size={18} /> PG/Co-living
                </div>
                <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> 	 My Actions               </div>}>
                    <div className="ps-4">
                    <div className="d-flex flex-column gap-1">
                  <div className="simple-sidebar-btn">
                    {" "}
                    <img src={"/Images/eye-icon.svg"} alt="" /> Recently Viewed
                  </div>
                  <div className="simple-sidebar-btn">
                    {" "}
                    <img src={"/Images/search-icon.svg"} alt="" />
                    Recently Searched
                  </div>
                  <div className="simple-sidebar-btn">
                    {" "}
                    <img src={"/Images/shortlisted-icon.svg"} alt="" />
                    Shortlisted
                  </div>
                  <div className="simple-sidebar-btn">
                    {" "}
                    <img src={"/Images/call-icon.svg"} alt="" /> Contacted
                  </div>
                </div>

                    </div>
                  </AccordionTab>

                </Accordion>

                <div className="divider-down my-1"></div>
                <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> Professional Services                 </div>}>
                    <div className="ps-4">
                      <div className="simple-sidebar-btn mt-0">
                        {" "}
                        Rental Services
                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Loans Hub

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Valuations

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Legal Advice

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Mortgages

                      </div>
                    </div>
                  </AccordionTab>

                </Accordion>
                <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2">Featured Services              </div>}>
                    <div className="ps-4">
                      <div className="simple-sidebar-btn mt-0">
                        {" "}
                        Interior Design

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Design & Architecture

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Renovation Contractors
                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Property Management
                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Marketing Mastery

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Packers & Movers

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Home Renovations

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Real Estate Advisory

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Investment Insights

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Property Insurance

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Property Inspections

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Home Staging
                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Cleaning Solutions

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Energy Efficiency

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Green Building Solutions

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Smart Home Solutions

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Home Security

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Landscaping Services

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Photography & Videography

                      </div>
                      <div className="simple-sidebar-btn">
                        {" "}
                        Events & Celebrations


                      </div>

                    </div>
                  </AccordionTab>

                </Accordion>
                <div className="divider-down"></div>
                {/* <div className="divider-down mt-2 mb-0"></div> */}
                <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> 	Insight Trends                    </div>}>
                    <div className="ps-4">
                      <div className="simple-sidebar-btn mt-0"
                      onClick={()=>Navigate("/news-articles")}
                      >
                        {" "}
                        	Articles & News   </div>
                      <div className="simple-sidebar-btn" 
                      onClick={()=>Navigate("/market_insights")}
                      >
                        {" "}
                        Market Insights  </div>
                      <div className="simple-sidebar-btn" 
                      onClick={()=>Navigate("/blog")}
                      >

                        {" "}
                        	Blog </div>

                    </div>
                  </AccordionTab>

                </Accordion>
           

                <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> 	   Help & Support       </div>}>
                    <div className="ps-4">
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        Property Advice </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        FAQs & Guides </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        Help Center </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        Report Fraud </div>

                    </div>
                  </AccordionTab>

                </Accordion> 
                <div className="divider-down"></div>
                <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> 	Contact Us                   </div>}>
             <div className="ps-4">
             <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> 	Phone                 </div>}>
                    <div className="ps-4">
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	Inquiries: <span className="fw-bold">+91 801 966 6058</span>  </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        Support: <span className="fw-bold"> +91 801 966 6956</span>  </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	Toll-Free:  <span className="fw-bold">1800 41 99099      </span>  </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	Inquiries: <span className="fw-bold">+91 801 966 6058</span>  </div>
                     
                    </div>
                  </AccordionTab>

                </Accordion> 
             <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> 	Emails                </div>}>
                    <div className="ps-4">
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	support@sftarea.com  </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	sales@sftarea.com
                        </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	marketing@sftarea.com
                        </div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	contact@sftarea.com
                        </div>
                     
                    </div>
                  </AccordionTab>

                </Accordion> 
             </div>
                  </AccordionTab>

                </Accordion> 
                <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                  <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2"> 		Tools & Preferences                    </div>}>
                    <div className="ps-4">
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        Download Our App
</div>
                      <div className="simple-sidebar-btn mt-0"    >
                        {" "}
                        	Language Preference
                        </div>
                    </div>
                  </AccordionTab>

                </Accordion> 
               
              </div>
            </Sidebar>
            {/* sidebar for undertabs  buying, selling,commercial,renting,Pg/CoLiving tab */}

            <Sidebar
              visible={visibleUnderRight}
              closeIcon={<RiCloseFill size={25} />}
              position="right"

              showCloseIcon={false}
              style={{ marginTop: "50px" }}

              header={
                <div className="backward-header py-3 px-2">
                  <span className=""><IoArrowBack size={20} onClick={() => setVisibleUnderRight(false)} /> </span>
                  {headerValue}
                </div>
              }
              onHide={
                () => setVisibleUnderRight(false)
                // onHide
              }

            >
              {
                headerValue === "Buying" &&
                (<div className="sidebar-content-body pb-3 ps-3">

                  <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">  Flat For Sale                   </div>} className="border-0 bg-none" >
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Search Homes for Sale

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Luxury Apartments


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Budget Flats
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Ready-to-Move-In Flats

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Under-Construction Flats
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Studio Apartments

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Available Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Featured Properties

                        </div>

                      </div>
                    </AccordionTab>
                    <div className="divider-down my-3"></div>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">  Homes &  Villas For Sale</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Independent Houses

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Duplex Homes



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Luxury Villas

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Gated Community Villas


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Budget Homes

                        </div>


                        <div className="simple-sidebar-btn">
                          {" "}
                          All Available Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Featured Properties

                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize"> Plots, Lands, and Farms</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Residential Plots



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Agricultural Lands


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Farmhouses


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Industrial Lands

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Factory & Manufacturing


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Warehouse & Cold Storage


                        </div>

                        <div className="simple-sidebar-btn">
                          {" "}
                          All Available Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Featured Properties

                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize"> High Rise Residences</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Sky Apartments



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Penthouse Suites



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          High-End Condos


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Modern Flats


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          City View Apartments


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          High-Rise Apartments


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Available Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Featured Properties

                        </div>
                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize"> New Constructions                      </div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Pre-Launch Offers



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Early Bird Specials



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Recently Launched


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Under Construction

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Upcoming Projects

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Affordable Housing


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Available Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Featured Properties

                        </div>
                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Upcoming Properties</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          New Launch Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Future Housing Developments

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Planned Community Projects

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Pre-Launch Opportunities

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Pipeline Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Forthcoming Listings

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Available Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          All Featured Properties

                        </div>

                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Top Residential Areas</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Kondapur Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Kokapet Focus

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Bachupally Focus

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Gachibowli Focus

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Narsingi Focus



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Nallagandla Focus

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Miyapur Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Kollur Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Manikonda Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Pragathi Nagar Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}	Tellapur Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Puppalaguda Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Nizampet Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Hi-Tech City Focus

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Kukatpally Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Bandlaguda Focus

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Madhapur Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Banjara Hills Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Jubilee Hills Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Chandanagar Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Ameerpet Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Begumpet Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Kompally Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Attapur Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Financial District Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Serilingampally Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Sainikpuri Focus


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Nagole Focus



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          LB Nagar Focus



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Uppal Focus



                        </div>


                      </div>
                    </AccordionTab>



                  </Accordion>

                </div>)

              }
              {
                headerValue === "Selling" &&
                (<div className="sidebar-content-body pb-3 ps-3">

                  <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">  For Owners                  </div>} className="border-0 bg-none" >
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Post Property<div className="free-tag-under">(Free)</div>
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          My Dashboard
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Sell/Rent Ad Packages
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Check Your Home Value
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Home Values
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Help to Buy Schemes
                        </div>

                      </div>
                    </AccordionTab>
                    <div className="divider-down my-3"></div>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize"> Property Types</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Apartments for Sale
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Houses for Sale
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Villas for Sale
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          New Homes for Sale
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Luxury Properties
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Commercial Properties
                        </div>
                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize"> Seller Tools</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          List Your Property
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Market Insights
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Regional Market Insights
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Seller Guides
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Recent Sales
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Legal and Documentation Support
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Virtual Tours and Staging
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}                      Seller Support
                        </div>
                      </div>
                    </AccordionTab>



                  </Accordion>

                </div>)
              }
              {
                headerValue === "Commercial" &&
                (<div className="sidebar-content-body pb-3 ps-3">

                  <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">  Hyderabad Commercial Properties                  </div>} className="border-0 bg-none" >
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Available Commercial Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Featured Commercial Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Commercial for sale
                        </div>


                      </div>
                    </AccordionTab>
                    <div className="divider-down my-3"></div>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">  Commercial Property Sales
                    </div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Office Buildings

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Corporate Offices




                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Retail Spaces

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Co-working Spaces


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Specialized Venues

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Industrial Facilities


                        </div>





                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Commercial Leasing</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Office Leasing



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Retail Leasing



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Industrial Leasing


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Event Space Leasing


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Short Term Leasing

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Long-Term Leasing



                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Industrial Properties Sale</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Warehouse Sales



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Industrial Sales




                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Factory Sales



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Industrial Sales


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Logistics Parks

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Manufacturing Units Sales



                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Investment Properties</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Commercial Investments



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Property Portfolio



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Real Estate Funds


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          High-Yield Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Investment Analysis


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Commercial Plots




                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Development Projects</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Commercial Land



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Mixed-Use Developments


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Construction Projects

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Investment Opportunities


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Urban Development


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Urban Development




                        </div>


                      </div>
                    </AccordionTab>




                  </Accordion>

                </div>)
              }
              {
                headerValue === "Renting" &&
                (<div className="sidebar-content-body pb-3 ps-3">

                  <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">   Find Your Rental in Hyderabad                 </div>} className="border-0 bg-none" >
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Available Rental Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Featured Rental Properties


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Search Homes for Rent
                        </div>


                      </div>
                    </AccordionTab>
                    <div className="divider-down my-3"></div>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">  Residential Rentals
                    </div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Apartments for Rent

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Houses for Rent




                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Villas for Rent

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Studio Apartments


                        </div>

                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Commercial Rentals</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Available Commercial Rent



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Featured Commercial Rent



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Office Spaces for Rent


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Retail Shops for Rent



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Warehouses for Rent

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Showrooms for Rent


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Co-working Spaces for Rent



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Industrial Units for Rent
                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Short-Term Rentals</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Serviced Apartments



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Vacation Homes




                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Furnished Rentals




                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Holiday Rentals


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Temporary Housing

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Extended Stay Rentals

                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Affordable Rentals
                    </div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Budget Apartments

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Low-Cost Housing

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Student Accommodation


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Subsidized Housing


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Cost-Effective Living


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Shared Housing



                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Specialized Rentals</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Co-living Spaces



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Bachelor-Friendly Homes


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Luxury Rentals

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Furnished Homes


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Immediately Available Rentals



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Corporate Housing

                        </div>


                      </div>
                    </AccordionTab>




                  </Accordion>

                </div>)
              }
              {
                headerValue === "PG/Co-living" &&
                (<div className="sidebar-content-body pb-3 ps-3">

                  <Accordion expandIcon={<GoTriangleRight size={18} />} collapseIcon={<GoTriangleDown size={18} />} multiple >
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize"> PGs/Co-living Spaces in Hyderabad                </div>} className="border-0 bg-none" >
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          Boys' PG Accommodation


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Girls' PG Accommodation


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Student Accommodation
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Single Occupancy PG

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Twin Sharing PG
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Triple Occupancy PG
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Shared Living Spaces
                        </div>



                      </div>
                    </AccordionTab>
                    <div className="divider-down my-3"></div>

                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">For Agents & Builders</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          My Dashboard



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Ad Packages



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Developer Lounge


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Sales Enquiries



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Agent Support

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Marketing & Advertising


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Project Listings

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Lead Management
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Client Relations
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Builder Resources
                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Promotional Tools

                        </div>


                      </div>
                    </AccordionTab>
                    <AccordionTab headerClassName={"accordian-header"} headerTemplate={<div className="ps-2 text-capitalize">Agencies & Developers</div>}>
                      <div className="ps-4">
                        <div className="simple-sidebar-btn mt-0">
                          {" "}
                          My Dashboard



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Ad Packages




                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Developer Hub




                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}

                          Sales Enquiries


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}
                          Agency Support

                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Marketing Solutions


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Project Management



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Lead Tracking


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Client Services



                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Developer Resources


                        </div>
                        <div className="simple-sidebar-btn">
                          {" "}


                          Promotional Strategies


                        </div>


                      </div>
                    </AccordionTab>





                  </Accordion>

                </div>)
              }
            </Sidebar>
            {/* sidebar for Mobile tab hemberger menu  */}
            <Sidebar
              visible={visibleRightMenu}
              closeIcon={<RiCloseFill size={25} />}
              position="right"
              className="custom-sidebar"
              header={
                <div className="brand-name-logo">
                  <img src="/Images/Brandicon.png" width={'30px'} alt="" />
                  <span className="sft-name">SFTAREA</span>
                </div>
              }
              onHide={
                () => setVisibleRightMenu(false)
                // onHideMenu
              }
            >
              <SidebarMenu />
            </Sidebar>
          </div>
        </div>
        <div className="navbar-container" style={navbarStyle}>
          <div className="container">
            <div className="nav-bar">
              <NavLink
                to=""
                //    className={({ isActive }) =>{

                //      return ([
                //        isActive ? "testactive" : "nav-item",
                //       ].join(" "))
                //     }
                //  }
                className={`${scrolled ? "any-class" : "nav-item"} buy-drop `}
              >
                Buy <FaAngleDown fontSize={14} />
                <div className="buy-dropdown-card">
                  <BuyDropdown />
                </div>
              </NavLink>
              <NavLink
                to=""
                className={`${scrolled ? "any-class" : "nav-item"} buy-drop`}
              >
                Rent <FaAngleDown fontSize={14} /> <div className="buy-dropdown-card">
                  <RentDropdown />
                </div>
              </NavLink>
              <NavLink
                to=""
                className={`${scrolled ? "any-class" : "nav-item"} buy-drop`}
              >
                Sell <FaAngleDown fontSize={14} />
                <div className="buy-dropdown-card">
                  <SellDropdown />
                </div>
              </NavLink>
              <NavLink
                to=""
                className={`${scrolled ? "service-class" : "nav-item"} buy-drop`}>
                Commercial <FaAngleDown fontSize={14} />
                <div className="buy-dropdown-card3">
                  <CommercialDropdown />

                </div>
              </NavLink>
              <NavLink to="" className={scrolled ? "any-class" : "nav-item"}>
                Loans Mortgage <FaAngleDown fontSize={14} />
                {/* <div className="buy-dropdown-card">
                  <div className="under-dropbox">
                    <div className="items"><div className="dot"></div> Sell Property</div>
                    <div className="items"><div className="dot"></div> Sell Houses</div>
                    <div className="items"><div className="dot"></div> Rent </div>
                    <div className="items"><div className="dot"></div> Sell Plotes</div>
                    <div className="items"><div className="dot"></div> Sell Villas</div>
                  </div>
                </div> */}
              </NavLink>
              <NavLink to="" className={`${scrolled ? "service-class" : "nav-item"} buy-drop`}>


                Services <FaAngleDown fontSize={14} />
                <div className="buy-dropdown-card2">
                  <ServiesDropdowns />

                </div>
              </NavLink>
              <NavLink to="" className={`${scrolled ? "service-class" : "nav-item"} buy-drop`}>
                Market Insights<FaAngleDown fontSize={14} />{" "}
                <div className="buy-dropdown-card3">
                  <MarketInsightsDropdown />
                </div>
              </NavLink>
              {/* <NavLink to="" className={scrolled ? "any-class" : "nav-item"}>
                Advertise <FaAngleDown fontSize={14} />
               
              </NavLink> */}
              <NavLink to="" className={`${scrolled ? "service-class" : "nav-item"} buy-drop`}>
                Pages <FaAngleDown fontSize={14} />
                <div className="buy-dropdown-card3">
                  <PagesDropdown />
                </div>

              </NavLink>
              {/* <NavLink to="" className={scrolled ? "any-class" : "nav-item"}>
                Blog{" "}
              </NavLink> */}

              <div className="more-btn d-none">
                <FiMenu size={23} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
