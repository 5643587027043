import React, { useState } from 'react'
import './Blog.css'
import { InputText } from 'primereact/inputtext'
import { CgMenuGridO, CgSearch } from 'react-icons/cg'
import { TfiMenuAlt } from 'react-icons/tfi'
import { FaArrowRightLong, FaHouseChimneyMedical, FaRegFolderOpen } from 'react-icons/fa6'
import Pagination from '../../Components/Pagination/Pagination'
import { Rating } from 'primereact/rating'
import { IoCallOutline } from 'react-icons/io5'
const Blog = () => {
  const [value, setValue] = useState("");
  const blogCardData = [
    {
      url: "./Images/blog_image1.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "April",
      type: "Housing"
    },
    {
      url: "./Images/blog_image2.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "June",
      type: "Renting"
    },
    {
      url: "./Images/blog_image3.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "July",
      type: "Renting"
    },
    {
      url: "./Images/blog_image4.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "August",
      type: "Housing"
    },
    {
      url: "./Images/blog_image5.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "March",
      type: "Housing"
    },
    {
      url: "./Images/blog_image6.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "May",
      type: "Housing"
    },
    {
      url: "./Images/blog_image7.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "April",
      type: "Housing"
    },
    {
      url: "./Images/blog_image8.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "December",
      type: "Housing"
    },
    {
      url: "./Images/blog_image9.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "April",
      type: "Housing"
    },
    {
      url: "./Images/blog_image10.png",
      name: "We are hiring ‘moderately,’ says Compass CEO",
      month: "March",
      type: "Housing"
    },
  ]

  const featuresData = [
    {
      url: "./Images/features1.png",
      name: "Gorgeous Apartment Building",
      price: "$7,500"
    },
    {
      url: "./Images/features2.png",
      name: "Gorgeous Apartment Building",
      price: "$7,500"
    },
    {
      url: "./Images/features3.png",
      name: "Gorgeous Apartment Building",
      price: "$7,500"
    },
  ]
  const nearProperty = [
    {
      url: "./Images/near_property1.png",
      name: "Moncton",
      property: "1570 listing"
    },
    {
      url: "./Images/near_property2.png",
      name: "Mississauga",
      property: "18/70 listing"
    },
    {
      url: "./Images/near_property3.png",
      name: "Halifax",
      property: "1170 listing"
    },
    {
      url: "./Images/near_property4.png",
      name: "Ottawa",
      property: "1250 listing"
    },
    {
      url: "./Images/near_property5.png",
      name: "Iqaluit",
      property: "1420 listing"
    },
    {
      url: "./Images/near_property6.png",
      name: "Toronto",
      property: "1450 listing"
    },

  ]
  return (
    <>
      <div className="blog_cont">
        <div className="container mb-5 px-md-0 px-5">
          <div className="top_section_blogs row">
            <div className="col-md-8 blog_left pe-4">
              <div className="blog_top_header">
                <div className="top_header_heading">
                  Blog
                </div>
                <div className="search_bar_section">
                  {/* <form  className=""> */}
                  <div className="location-search d-flex justify-content-between flex-wrap gap-2 align-items-center">
                    <button className="advance-btn" >

                      <CgMenuGridO size={24} />
                    </button>
                    <button className="advance-btn" >

                      <TfiMenuAlt size={24} />
                    </button>


                  </div>
                  {/* </form> */}
                </div>
              </div>
              <div className="blog_big_image">
                <img src="./Images/blog-rounded-img.png" alt="" />
              </div>

              <div className="blog_heading">Real estate shifts: Prices and sales trending down in two different spheres</div>
              <div className="blog_para">
                Hosted by Brian Sullivan, “Last Call” is a fast-paced, entertaining business show that explores the intersection of money, culture and policy. Tune in Monday through Friday at 7 p.m. ET on...
              </div>

              <div className="blog_many_cards">
                {
                  blogCardData.map((res, index) => (
                    <>
                      <div className="blog_many_card" index={index}>
                        <div className="card_many_image">
                          <img src={res.url} alt="" />
                          <div className="housing_tag">
                            <span className='month_txt'>{res.month}
                            </span>
                            <FaRegFolderOpen size={18} />
                            <span className='housing_txt color-primary '>  {res.type}</span>
                          </div>
                        </div>
                        <h5 className='text-center px-3'>{res.name}
                        </h5>
                        <div className="read_more_btn">
                          Read more    <FaArrowRightLong size={15} />
                        </div>

                      </div>
                    </>
                  ))
                }

              </div>
              <div className="pagination_div">
                <Pagination />
              </div>
            </div>





            <div className="col-md-4 mt-md-0 mt-5 blog_right">
              <div className="search_box">
                <h5>Search</h5>
                <div className="input-text-box">
                  <InputText
                    id="inputtext-id"
                    value={value}
                    placeholder={`Search`}
                    onChange={(e) => setValue(e.target.value)}
                  />
                  <div className="search-for-input">
                    <CgSearch size={17} />
                  </div>
                </div></div>

              <h5 className='mt-5'>
                Categories
              </h5>
              <div className="category_box">
                Office
                <FaArrowRightLong size={15} />
              </div>
              <hr />
              <div className="category_box">
                Business
                <FaArrowRightLong size={15} />
              </div>
              <hr />
              <div className="category_box">
                Luxury villa
                <FaArrowRightLong size={15} />
              </div>
              <hr />
              <div className="category_box">
                Apartments
                <FaArrowRightLong size={15} />
              </div>
              <hr />
              <div className="category_box">
                Duplex House
                <FaArrowRightLong size={15} />
              </div>

              <h5 className='mt-5'>
                Featured listings
              </h5>
              {
                featuresData.map((res, index) => (
                  <>                  <hr />
                    <div className="features_card_blog">
                      <div className="features_card_image">
                        <img src={res.url} alt="" />

                      </div>
                      <div className="side_content_feature d-flex flex-column gap-2 pe-0 pe-lg-5">
                        <div className="text_features">
                          {res.name}
                        </div>
                        <div className="price_feature">{res.price}</div>
                      </div>
                    </div>

                  </>

                ))
              }
              <h5 className='mt-5'>
                Real estate near you
              </h5>

              <div className="near_cards">
                {
                  nearProperty.map((res, index) => (<>
                    <div className="near_card">
                      <div className="near_image">
                        <img src={res.url} alt="" />
                      </div>
                      <div className="content">
                        <div className="heading_near">
                          {res.name}

                        </div>
                        <div className="much_property">
                          {res.property}
                        </div>
                      </div>
                    </div>
                  </>))
                }

              </div>
              <div className="gorgeous_apartment_card">
                <img src="./Images/georgeous.png" alt="" />
                <div className="cont">
                  <h5>Gorgeous Apartment Building</h5>
                  <span className='loaction'>
                    58 Hullbrook Road, Billesley, B13 0LA
                  </span>
                  <div className="review_star">
                    <Rating value={5} readOnly={true} cancel={false} />
                  </div>
                </div>
              </div>
              <h5 className='mt-5'>Popular tags</h5>
              <div className="tags_btns">
                <button className="tag_btn">
                  For Sale
                </button>
                <button className="tag_btn">
                 Office
                </button>
                <button className="tag_btn">
                 Business
                </button>
                <button className="tag_btn">
                Luxury villa
                </button>
                <button className="tag_btn">
                Apartments
                </button>
                <button className="tag_btn">
                Duplex House
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="find_home_section">
    <div className="container">
        <div className="row">
            <div className="col-md-7 col-lg-8 left_agent_cont">
<div className="big_heading">
Find for your dream home and increase your investment opportunities
</div>
<div className="para mt-3">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sed tristique metus proin id lorem odio
</div>
<button className="primary-btn mt-4">
<IoCallOutline size={20} />  Contact Seller
</button>
            </div>
            <div className="col-md-5 col-lg-4 right_agent_img">
                <img src="https://themesflat.co/html/dreamhomehtml/assets/images/mark/mark-contact2.png" alt="" />
            </div>
        </div>
    </div>
</div>
<div className="container mb-5">
   <div className="row">
   <div className="col-md-6 pe-3">
        <div className="animated_card">
           <div className="left_image_card">
            <img src="https://themesflat.co/html/dreamhomehtml/assets/images/icon/footer-icon-1.png" alt="" />
           </div>
           <div className="right_cont_card">
            <h3>You need a house</h3>
            <div className="para">Tell us your needs, we will give you thousands of suggestions for the dream home.</div>
            <button className="primary-btn">
<IoCallOutline size={20} />  Contact Seller
</button>
           </div>
        </div>
    </div>
    <div className="col-md-6 ps-3">
    <div className="animated_card">
           <div className="left_image_card">
            <img src="https://themesflat.co/html/dreamhomehtml/assets/images/icon/footer-icon-2.png" alt="" />
           </div>
           <div className="right_cont_card">
            <h3>Sell your house</h3>
            <div className="para">We will connect you to thousands of people who need to buy a home.</div>
            <button className="primary-btn">
<FaHouseChimneyMedical  size={20}/>  Sell Property
</button>
           </div>
        </div>
    </div>
   </div>
</div>

      </div>
    </>
  )
}

export default Blog
