import React, { useState } from 'react'

const MarketInsightsDropdown = () => {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleHover = (itemName) => {
      setSelectedItem(itemName);
    };
  
    const renderContent = () => {
      switch (selectedItem) {
        case "Selling Guides":
          return (
            <>
             <div className="property-div">
              <div className="gray-header">Property Types</div>
              <div className="under-content">
                <div className="property-name">Home Selling Guide</div>
                <div className="property-name">Sellers Guide</div>
                <div className="property-name">Explore Your Options</div>
                <div className="property-name">Selling Options</div>
                <div className="property-name">Prepare Your Home for Sale </div>
                <div className="property-name">Marketing Your Property</div>
              </div>
              </div>
          
              <div className="explore-div">
              <div className="gray-header">Explore</div>
              <div className="under-content">
                <div className="explore-name">Localities in Delhi </div>
                <div className="explore-name">Projects in Delhi</div>
                <div className="explore-name">Investment Hotspot</div>
                <div className="explore-name">Find House</div>
                <div className="explore-name">Investment</div>
              </div>
              </div>
            </>
          );
   
        default:
          return (
              <>

            
  
              <div className="drop-header-div2">
                <div className="header-item">Market News</div>
                <div className="header-item">Policy Updates</div>
                <div className="header-item">Expert Views</div>
                <div className="header-item">Investment Trends</div>
                <div className="header-item">Regional News</div>
                <div className="header-item">Market Health</div>
              </div>
              
              
                 
  
              <div className="drop-header-div2">
                <div className="header-item">Economic Signals</div>
                <div className="header-item">Supply & Demand</div>
                <div className="header-item">Price Movements</div>
                <div className="header-item">Market Phases</div>
                <div className="header-item">Sector Trends</div>
                <div className="header-item">Hotspots</div>
              </div>
       
             
        
              <div className="drop-header-div2">
                <div className="header-item">ROI Insights</div>
                <div className="header-item">Risk Analysis</div>
                <div className="header-item">Investment Tips</div>
                <div className="header-item">City Focus</div>
                <div className="header-item">Suburban Insights</div>
                 <div className="header-item">Rural Trends</div>
              </div>
              
             

              <div className="drop-header-div2">
                <div className="header-item">Price Analysis</div>
                <div className="header-item">Local Performance</div>
                <div className="header-item">Future Trends</div>
                <div className="header-item">Quarterly Insights</div>
                <div className="header-item">Annual Reviews</div>
                 <div className="header-item">Market Predictions</div>
             
              </div>
              </>
          );
      }
    };
  
    return (
      <>
        <div className="under-buy-dropbox">
          <div className="d-flex gap-0">
           
           
              {renderContent()}
           
            
            
         
          </div>
        </div>
      </>
    );
}

export default MarketInsightsDropdown
