import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./Pages/HomePage/Homepage";
import LoginRegister from "./Pages/Login_Register/LoginRegister";
import Advertise from "./Pages/Advertise/Advertise";
import PremiumAccess from "./Pages/Premium_Access/PremiumAccess";
import ListProperty from "./Pages/List_Property/ListProperty";
import LeadMarket from "./Pages/LeadMarket/LeadMarket";
import ScrollToTop from "./Components/Common/ScrollToTop";
import Services from "./Components/Common/Services/Services";
import AgentsBuilders from "./Pages/Agents_Builders/AgentsBuilders";
import { MarketInsight } from "./Pages/MarketInsight/MarketInsight";
import Navbar from "./Components/Navbar/Navbar";
import Blog from "./Pages/Blog/Blog";
import Footer from "./Components/Footer/Footer";
import { News } from "./Pages/News/News";
export const Router = () => {
  return (
    <>
      
      <BrowserRouter>
        <ScrollToTop />
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/advertise" element={<Advertise/>} />
          <Route exact path="/list_property" element={<ListProperty/>} />
          <Route exact path="/lead_market" element={<LeadMarket/>} />
          <Route exact path="/agents_builders" element={<AgentsBuilders/>} />
          
          <Route exact path="/premium_access" element={<PremiumAccess/>} />
          <Route exact path="/login-Register/:tab" element={<LoginRegister/>} />
          <Route exact path="/services/:id" element={<Services/>} />
          <Route exact path="/market_insights" element={<MarketInsight/>} />
          <Route exact path="/news-articles" element={<News/>} />
          <Route exact path="/blog" element={<Blog/>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
};
