import React, { useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import './LeadMarket.css'
import { Link } from 'react-router-dom'
import { FaCaretRight } from 'react-icons/fa6'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from "primereact/inputtextarea";
const LeadMarket = () => {


    const [selectedPerson, setSelectedPerson] = useState(null);
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [information, setInformation] = useState('');

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState();

    const properties = [
        { name: 'Myself', code: 'M' },
        { name: 'Company', code: 'C' },
        { name: 'Landlord', code: 'L' },
        { name: 'Builder', code: 'B' },

    ];
    const SubmitForm = (e) => {
        e.preventDefault();

    }
    return (
        <>
       
            <div className='lead_market_content container'>
                {/* top banner section  */}
                <div className='top_market_section row'>
                    <div className="col-md-6 col-lg-5 ps-sm-3 col-12 text-center text-md-start">

                        <div className="big-heading">Establish Your <span> Real Estate</span> Presence

                        </div>

                        <div className="big-para mt-2">
                            Utilize our marketing tools to enhance your online visibility and attract more clients.
                        </div>
                        <div className='d-flex justify-content-center justify-content-md-start'><button className='learn_more_btn mt-3'>Build Your Brand  <FaCaretRight size={20} /></button></div>
                    </div>
                    <div className="col-md-6 col-12">
                        <img src="./Images/build_brand.png" width={'100%'} alt="" />
                    </div>
                </div>

                {/* quality leads section  */}
                <div className='row quality_lead_section'>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <h4 className='color-primary'>Get Quality Leads
                        </h4>
                        <div className='col-lg-10 col-md-12 col-12 big-heading '>Generate Predictable Volume of <span>Leads</span>
                        </div>
                        <div className="col-12 mb-5 col-md-9 big-para ">Connect with serious buyers and sellers through our  targeted lead generation strategies.
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className='leads_card'>
                            <img src="./Images/get_quality1.png" width={'100%'}  alt="" />
                            <div className='lead_card_bottom'>
                                <h4 className=''>Get a predictable volume of leads</h4>
                                <div className=''>Enjoy a steady stream of serious buyer and seller connections with a single upfront cost.</div>
                                <button className='learn_more_btn mt-2'>Learn More  <FaCaretRight size={20} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className='leads_card'>
                            <img src="./Images/get_quality1.png" width={'100%'}  alt="" />
                            <div className='lead_card_bottom'>
                                <h4 className=''>Get a predictable volume of leads</h4>
                                <div className=''>Enjoy a steady stream of serious buyer and seller connections with a single upfront cost.</div>
                                <button className='learn_more_btn mt-2'>Learn More  <FaCaretRight size={20} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mt-md-0 mt-4 col-12">
                        <div className='leads_card'>
                            <img src="./Images/get_quality2.png" width={'100%'}  alt="" />
                            <div className='lead_card_bottom'>
                                <h4 className=''>Get a predictable volume of leads</h4>
                                <div className=''>Enjoy a steady stream of serious buyer and seller connections with a single upfront cost.</div>
                                <button className='learn_more_btn mt-2'>Learn More  <FaCaretRight size={20} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mt-md-0 mt-4 col-12">
                        <div className='leads_card'>
                            <img src="./Images/get_quality2.png" width={'100%'}  alt="" />
                            <div className='lead_card_bottom'>
                                <h4 className=''>Get a predictable volume of leads</h4>
                                <div className=''>Enjoy a steady stream of serious buyer and seller connections with a single upfront cost.</div>
                                <button className='learn_more_btn mt-2'>Learn More  <FaCaretRight size={20} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mt-md-0 mt-4 col-12">
                        <div className='leads_card'>
                            <img src="./Images/get_quality2.png" width={'100%'}  alt="" />
                            <div className='lead_card_bottom'>
                                <h4 className=''>Get a predictable volume of leads</h4>
                                <div className=''>Enjoy a steady stream of serious buyer and seller connections with a single upfront cost.</div>
                                <button className='learn_more_btn mt-2'>Learn More  <FaCaretRight size={20} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mt-md-0 mt-4 col-12">
                        <div className='leads_card'>
                            <img src="./Images/get_quality2.png" width={'100%'}  alt="" />
                            <div className='lead_card_bottom'>
                                <h4 className=''>Get a predictable volume of leads</h4>
                                <div className=''>Enjoy a steady stream of serious buyer and seller connections with a single upfront cost.</div>
                                <button className='learn_more_btn mt-2'>Learn More  <FaCaretRight size={20} /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* advance marketing tools section */}
                <div className="row advance_marketing_section">
                    <div className="col-lg-5 col-md-6 col-12">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Advanced Marketing Tools
                            </h4>
                            <div className='big-heading'>Leverage Cutting-Edge Marketing
                                <span> Tools</span>
                            </div>
                            <div className=" big-para ">Access advanced tools such as CRM integration, email marketing, and analytics to streamline your marketing efforts.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 mt-md-0 mt-4 col-12">
                        <img src="./Images/advance_marketing.png" width={'100%'} alt="" />
                    </div>
                </div>
                {/* success story section  */}
                <div className="row advance_marketing_section">

                    <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
                        <img src="./Images/success_story.png" width={'100%'} alt="" />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Success Stories
                            </h4>
                            <div className='big-heading'>Learn from  <span>Success</span> Stories

                            </div>
                            <div className=" big-para ">Discover how other real estate professionals have achieved success using our platform.
                            </div>
                        </div>
                    </div>
                </div>
                {/* Support section */}
                <div className="row advance_marketing_section" style={{marginTop:"0px", marginBottom:"0"}}>
                    <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Personalized Support
                            </h4>
                            <div className='big-heading'>Get Dedicated <span>Support</span>
                            </div>
                            <div className=" big-para ">Our team is here to help you every step of the way, ensuring you get the most out of our services.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mt-md-0 mt-4">
                        <img src="./Images/support_image.png" width={'100%'} alt="" />
                    </div>
                </div>

                {/* Partner section */}
                <div className="row advance_marketing_section">

                    <div className="col-lg-5 col-md-6 col-12">
                        <img src="./Images/partner_image.png" width={'100%'} alt="" />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Join as a Partner
                            </h4>
                            <div className='big-heading'>Become a Strategic
                                <span> Partner</span>
                            </div>
                            <div className=" big-para ">Partner with us to expand your reach and grow your business with exclusive benefits.
                            </div>
                        </div>
                    </div>
                </div>
                {/* real state  Insights  section*/}
                <div className="row advance_marketing_section">
                    <div className="col-lg-5 col-md-6 col-12">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Real Estate Insights
                            </h4>
                            <div className='big-heading'>Stay Informed with
                                <span> Market Insights</span>
                            </div>
                            <div className=" big-para ">Access the latest market trends, research reports, and expert insights to stay ahead in the real estate game.
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
                        <img src="./Images/Insights_image.png" width={'100%'} alt="" />
                    </div>
                </div>

                {/* testomonial section */}
                <div className="row advance_marketing_section">

                    <div className="col-lg-5 col-md-6 col-12">
                        <img src="./Images/testomonial_image.png" width={'100%'} alt="" />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Testimonials
                            </h4>
                            <div className='big-heading'>Hear from Our
                                <span> Clients</span>
                            </div>
                            <div className=" big-para ">: Read testimonials from satisfied clients who have transformed their business with our lead and marketing solutions.
                            </div>
                        </div>
                    </div>
                </div>

                {/*  Additional resourse section */}
                <div className="row advance_marketing_section">
                    <div className="col-lg-5 col-md-6 col-12">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Additional Resources
                            </h4>
                            <div className='big-heading'>Access Additional
                                <span> Resources</span>
                            </div>
                            <div className=" big-para ">Explore our resources, including articles, guides, and webinars, to continuously improve your real estate marketing strategies.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 mt-md-0 mt-4">
                        <img src="./Images/additional-resourse.png" width={'100%'} alt="" />
                    </div>
                </div>

                {/* Growing section  */}
                <div className="row advance_marketing_section">

                    <div className="col-lg-5 col-md-6 col-12">
                        <img src="./Images/growing.png" width={'100%'} alt="" />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-md-0 mt-4">
                        <div className='d-flex flex-column gap-2'>
                            <h4 className='color-primary'>Start Growing Today

                            </h4>
                            <div className='big-heading'>Ready to Boost Your <span> Real Estate </span>  Business?
                            </div>
                            <div className=" big-para ">Start your journey with Lead & Market and unlock the full potential of your real estate business.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* end section interested form  */}
            <div className='interested_section'>
                <div className='container'>
                    <div className='row d-flex justify-content-center mt-5'>


                        <div className='col-md-8 col-12  d-flex flex-column justify-content-center align-items-center'>
                            <h1>Interested in Our Solutions?        </h1>
                            <p className='text-center mt-2'>If you are a company, landlord, or builder interested in our "Lead & Market" solutions, please fill out the form below. Our team will get back to you shortly to discuss how we can help grow your real estate business.                                </p>
                            <div className='form_card_section'>
                                {/* second form  */}
                                <form onSubmit={SubmitForm}>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor="">Who are You ? *</label>
                                        <Dropdown style={{ padding: '4px 0px 4px 12px' }} value={selectedPerson} onChange={(e) => setSelectedPerson(e.value)} options={properties} optionLabel="name"
                                            placeholder="Please Select" className="w-full md:w-14rem" />
                                    </div>
                                    <div className='mt-3 d-flex flex-column'>
                                        <label htmlFor="">First Name *</label>
                                        <InputText style={{ padding: '4px 0px 4px 12px' }} value={fname} placeholder="First  name" onChange={(e) => setFName(e.target.value)} />
                                    </div>
                                    <div className='mt-3 d-flex flex-column'>
                                        <label htmlFor="">Last Name *</label>
                                        <InputText style={{ padding: '4px 0px 4px 12px' }} value={lname} placeholder="Last name" onChange={(e) => setLName(e.target.value)} />
                                    </div>


                                    <div className='mt-3 d-flex flex-column'>
                                        <label htmlFor="">Email *</label>
                                        <InputText style={{ padding: '4px 0px 4px 12px' }} value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className='mt-3 d-flex flex-column'>
                                        <label htmlFor="">Phone *</label>
                                        <InputNumber value={phone} placeholder="Phone" onValueChange={(e) => setPhone(e.value)} useGrouping={false} maxLength={10} />
                                    </div>
                                    <div className='mt-3 d-flex flex-column'>
                                        <label htmlFor="">Additional Information</label>
                                        <InputTextarea autoResize value={information} placeholder='Additional Information' onChange={(e) => setInformation(e.target.value)} rows={4} cols={30} />                                    </div>
                                    <div className='mt-4 d-flex flex-column rounded_corner'>
                                        <button className='primary-btn py-2'>Submit</button>
                                    </div>
                                </form>
                                <div className=' mt-3 p-0'>By submitting this form, you agree to our <span className='color-primary'>Privacy Policy</span>
                                </div>

                                <div className='small-text'>
                                    <div className='mt-2 p-0 d-flex gap-1'>Need help? Contact us at
                                        <Link to={''}>(919) 999-9999.</Link></div>
                                    {/* <div className='mt-2'>By pressing Submit, you agree that SFTArea Group may contact you via phone/text about your inquiry, which may involve the use of automated means. You are not required to consent as a condition of purchasing any goods or services. Message/data rates may apply.</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className='end_footer_section'>
            <div className='left_content'>
              <div className='normal_tag'>Need Help Choosing a Plan?</div>
              <h2 className='mt-2'>We're Here to Help!</h2>
              <p>Not sure which plan is right for you? Our team is ready to assist you at any time. Just click the button below to chat with us live.
              </p>
            </div>
            <div className='right-btn'>
              <button className='primary-btn'>
                Go To Live Chat page
              </button>
            </div>
          </div>

                </div>
        
        </>
    )
}

export default LeadMarket
