
import './MarketInsight.css';

  export const ImageWithOverlay = ({ imgSrc, heading,text }) => {
    return (
      <div className="image-container">
        <img src={imgSrc} alt="Overlay" className="image" />
        <div className="overlay-heading mb-2">{heading}</div>
        <div className="overlay-text mb-2">{text}</div>
      </div>
    );
  };