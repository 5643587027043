import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';
import { Rating } from 'primereact/rating';

const TestomonialSlider = ({sliderTestmonialProductService}) => {
   
      const sliderTestmonialresponsiveOptions = [
        {
          breakpoint: "2400px",
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: "1199px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "767px",
          numVisible: 1,
          numScroll: 1,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
          numScroll: 1,
        },
      ];
      const sliderTestmonialproductTemplate = (product) => {
        return (
          <div className='testomonial-slider-card'>
            <div className="d-flex gap-3">
            <div className='client-image'>
                    <img src={product.url} alt="" />
                </div>
                <div>
                <div className='client-name'>{product.name}</div>
                    <div className='client-position'>{product.position}</div>
                    <div className='review-stars mt-2'><Rating value={5} readOnly={true} cancel={false} /></div>
                </div>
            </div>
            
                
            <div className='testomonial-text'
            dangerouslySetInnerHTML={{ __html: product.comment }}
            >
               
            </div>
               {/* <div className='client-position'>{product.location}</div> */}
            
          </div>
        );
      };

  return (
    <>
                <Slider ProductService={sliderTestmonialProductService} responsiveOptions={sliderTestmonialresponsiveOptions} productTemplate={sliderTestmonialproductTemplate}/>

    </>
  )
}

export default TestomonialSlider
