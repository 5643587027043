import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';
import { FaHeartCircleCheck, FaInstagram, FaLinkedinIn, FaLocationDot } from "react-icons/fa6";
import { TiSocialFacebook } from 'react-icons/ti';
import { Rating } from "primereact/rating";
const TopAgentsSlider = () => {
    const sliderTopAgenciesProductService = [
        {
          url: "./Images/agent1.png",
          name:"Jerry E. Middleton",
          reviews:"312 Reviews",
          agents:"38 Agents",
          location:"Liverpool",
          property:"19 Property",
        },
        {
            url: "./Images/agent2.png",
          name:"Lamont B. Morrissey",
          reviews:"285 Reviews",
          agents:"85 Agents",
          location:"Liverpool",
          property:"65  Property",
        },
        {
            url: "./Images/agent3.png",
          name:"Amber M. Felton",
          reviews:"265 Reviews",
          agents:"85 Agents",
          location:"Liverpool",
          property:"52 Property",
        },
        {
            url: "./Images/agent4.png",
          name:"Agnes R. Boggess",
          reviews:"382 Reviews",
          agents:"27 Agents",
          location:"Liverpool",
          property:"34 Property",
        },
        {
            url: "./Images/agent5.png",
          name:"Shushma Silver Enclave",
          reviews:"365 Reviews",
          agents:"17 Agents",
          location:"Liverpool",
          property:"40 Property",
        },
        {
            url: "./Images/agent6.png",
          name:"Agnes R. Boggess",
          reviews:"381 Reviews",
          agents:"32 Agents",
          location:"Liverpool",
          property:"45 Property",
        },
       
      
          
      ];
      
      const sliderTopAgenciesresponsiveOptions = [
        {
          breakpoint: "2400px",
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: "1199px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "767px",
          numVisible: 1,
          numScroll: 1,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
          numScroll: 1,
        },
      ];
      const sliderTopAgenciesproductTemplate = (product) => {
        return (
          <div className='top-agents-slider-card border'>
          <div className='top-part-card'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='heart-icon'>
                <FaHeartCircleCheck size={19} />
                </div>
                <div className='property-box'>{product.property}</div>
            </div>
            <div className='agency-image'>
                <img src={product.url}  alt="" />
            </div>
            
            <div className='agency-name'>{product.name}</div>
                <div className='review-star d-flex '> <div className='star-yellow'> <Rating value={5} readOnly={true} cancel={false} /></div> {product.reviews}</div>
                <div className='contact-agent-btn'>Contact Agent</div>
          </div>
          <div className='footer-part-card d-flex justify-content-between'>
            <div className='location-agency'>
                <FaLocationDot /> {product.location}
              
            </div>
            <div className='social-links-agency d-flex gap-2'>
                <div className='social-icon'><TiSocialFacebook /></div>
            
                <div className='social-icon'><FaInstagram /></div>
                <div className='social-icon'><FaLinkedinIn />
</div>
            </div>
          </div>
          
          </div>
        );
      };

  return (
    <>
                <Slider ProductService={sliderTopAgenciesProductService} responsiveOptions={sliderTopAgenciesresponsiveOptions} productTemplate={sliderTopAgenciesproductTemplate}/>

    </>
  )
}

export default TopAgentsSlider
