import React, { useState } from "react";
import { Paginator } from 'primereact/paginator';
import { FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

const Pagination = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
  return (
    <>
       <Paginator firstPageLinkIcon={<FaAnglesLeft size={20} />} lastPageLinkIcon={<FaAnglesRight size={20} />} nextPageLinkIcon={<FaAngleRight size={22} />} prevPageLinkIcon={<FaAngleLeft size={22} />} first={first} rows={rows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
    </>
  )
}

export default Pagination
