import React from "react";
import Slider from "../../../Components/Common/Slider/Slider";
import ImageSlider from "../../../Components/ImageSlider";

const SpotLightSlider = () => {
  // for slider spotlight
  const sliderTenProductService = [
    {
      arr: [
        {
          id: 0,
          value:
            "https://housing-images.n7net.in/4f2250e8/5bc51244cdae456ec962aaccac39f489/v0/fs/anuhar_towers-puppalaguda-hyderabad-anuhar_homes_pvt_ltd.jpeg",
        
          name: "Anuhar Homes Pvt Ltd",
      
      brand_logo:
        "https://housing-images.n7net.in/0b8ad14c/ca5cc53fffc11d93e887fbbe6487f406/v0/medium.jpg",
      group_name: "Anuhar Towers",
      apartments: "4, 5 BHK Apartments",
      location: "Rai Durg, Hyderabad",
      price: "Rs 5.45 Cr - Rs 8.80 Cr",
        },
        {
          id: 1,
          value:
            "https://housing-images.n7net.in/4f2250e8/3c457f920b1ae266375bdfd79139e6b1/v0/fs/anuhar_rami_reddy_towers-puppalaguda-hyderabad-anuhar_homes_pvt_ltd.jpeg",
            name: "Anuhar Homes Pvt Ltd",
      
            brand_logo:
              "https://housing-images.n7net.in/0b8ad14c/ca5cc53fffc11d93e887fbbe6487f406/v0/medium.jpg",
            group_name: "Anuhar Rami Reddy T...",
            apartments: "4, 5 BHK Apartments",
            location: "Rai Durg, Hyderabad",
            price: "Rs 5.45 Cr - Rs 8.80 Cr",
        },
        {
          id: 2,
          value:
            "https://housing-images.n7net.in/4f2250e8/2d233a5e4e3945ee4766b8d179822e84/v0/fs/anuhar_gautami_heights-miyapur-hyderabad-anuhar_homes_pvt_ltd.jpg",
            name: "Anuhar Homes Pvt Ltd",
      
            brand_logo:
              "https://housing-images.n7net.in/0b8ad14c/ca5cc53fffc11d93e887fbbe6487f406/v0/medium.jpg",
            group_name: "Anuhar Gautami Heights",
            apartments: "4, 5 BHK Apartments",
            location: "Rai Durg, Hyderabad",
            price: "Rs 5.45 Cr - Rs 8.80 Cr",
        },
      ],
    },
    {
    
      arr: [
        {
          id: 0,
          value:
          "https://housing-images.n7net.in/4f2250e8/806bb6f89bdaaf2f91751149ab5b23fe/v0/fs/ghr_callisto-kollur-hyderabad-ghr_infra.jpg",
          name: "Ashoka Builders India Private Limited",
    
      brand_logo:
        "https://housing-images.n7net.in/0b8ad14c/5f7d1c778846642c1b21ba34696ae64a/v0/medium.jpg",

      group_name: "ASBL Loft",
      apartments: "2, 3, 4 BHK Apartments",
      location: "Miyapur, Hyderabad",
      price: "Rs 85.6 L - Rs 66.66 Cr",
        },
      ],
    },
    {
     
      arr: [
        {
          id: 0,
          value:
            "https://housing-images.n7net.in/4f2250e8/5bc51244cdae456ec962aaccac39f489/v0/fs/anuhar_towers-puppalaguda-hyderabad-anuhar_homes_pvt_ltd.jpeg",
            name: "Anuhar Homes Pvt Ltd",
      
            brand_logo:
              "https://housing-images.n7net.in/0b8ad14c/2eb5bccd3722b02645fe8e7145a87572/v4/medium.jpg",
      
            group_name: "Anuhar Towers",
            apartments: "2, 3, 4 BHK Apartments",
            location: "Gachibowli, Hyderabad",
            price: "Rs 99.7 L - 2.33 Cr",
        },
       
      ],
    },
    // {
    //   name: "Auro Realty Private Limited",
    //   url: "https://housing-images.n7net.in/4f2250e8/bcfadd6a87e6684a38d2e74255c6306c/v0/fs/the_regent-kondapur_hyderabad-hyderabad-auro_realty_private_limited.jpeg",
    //   brand_logo:
    //     "https://housing-images.n7net.in/0b8ad14c/2eb5bccd3722b02645fe8e7145a87572/v4/medium.jpg",

    //   group_name: "The Regent",
    //   apartments: "2, 3, 4 BHK Apartments",
    //   location: "Gachibowli, Hyderabad",
    //   price: "Rs 99.7 L - 2.33 Cr",
    //   arr: [
    //     {
    //       id: 0,
    //       value:
    //         "https://housing-images.n7net.in/4f2250e8/5bc51244cdae456ec962aaccac39f489/v0/fs/anuhar_towers-puppalaguda-hyderabad-anuhar_homes_pvt_ltd.jpeg",
          
    //     },
    //     {
    //       id: 1,
    //       value:
    //         "https://housing-images.n7net.in/4f2250e8/3c457f920b1ae266375bdfd79139e6b1/v0/fs/anuhar_rami_reddy_towers-puppalaguda-hyderabad-anuhar_homes_pvt_ltd.jpeg",
         
    //     },
    //   ],
    // },
  ];

  const sliderTenresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderTenproductTemplate = (product) => {
    // console.log(product)
    return (
      <>
        <ImageSlider imgs={product.arr} />
      </>
    );
  };

  return (
    <>
      <Slider
        ProductService={sliderTenProductService}
        responsiveOptions={sliderTenresponsiveOptions}
        productTemplate={sliderTenproductTemplate}
      />
    </>
  );
};

export default SpotLightSlider;
