import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import './Services.css'
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
const Services = () => {
    // const { id } = useParams();

    const [full, setFull] = useState(false)

    return (
        <>
            <Navbar />
            <div className="services_content_body">

                <div className="service_banner_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7  ps-sm-3 col-12">

                                <div className="big-heading">Your Trusted Partner To Find The  <span>Right Services</span>

                                </div>

                                <div className="big-para mt-3">
                                    Posting an ad is free and easy – it only takes a few simple steps! Select the right category and publish your classified ad for free.
                                </div>
                                <button className='primary-btn mt-3'>Explore Now</button>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="container">
                    <div className="how_its_works_services">
                        <div className='top_header_services '>
                            <h5 className='color-primary text-center'>How It Works  </h5>
                            <div className='big-heading text-center'>SFTArea can <span>help you</span> make smart choices</div>
                            <div className='text-center color-gray mt-2'>Posting an ad is free and easy – it only takes a few simple steps!

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12 works_card">
                                <div className="works_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/service_1.png" alt="" width={'50px'} />
                                </div>
                                <h4>Search for Services</h4>
                                <div className="paragraph_works text-center">Browse through a wide range of services from interior design to property management.</div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 works_card">
                                <div className="works_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/service_2.png" alt="" width={'50px'} />
                                </div>
                                <h4>Find Professionals</h4>
                                <div className="paragraph_works text-center">Connect with verified experts tailored to your needs.</div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 works_card">
                                <div className="works_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/service_3.png" alt="" width={'50px'} />
                                </div>
                                <h4>Get a Quote</h4>
                                <div className="paragraph_works text-center">Receive competitive quotes from top professionals with no obligations.</div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 works_card">
                                <div className="works_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/service_4.png" alt="" width={'50px'} />
                                </div>
                                <h4>Enjoy the Service</h4>
                                <div className="paragraph_works text-center">Hire the best professionals and enhance your property's value and comfort.</div>
                            </div>

                        </div>
                    </div>

                    <div className="why_choose_services">
                        <div className='top_header_services '>

                            <h5 className='color-primary text-center'>Why Choose Us </h5>
                            <div className='big-heading text-center'>Why Choose Our <span>Services ?</span> </div>
                            {/* <div className='text-center color-gray mt-2'>Posting an ad is free and easy – it only takes a few simple steps!     </div> */}
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-5 pt-5">
                                <div className='why_chose_services_card'>
                                    <h5>Quality Guaranteed</h5>
                                    <p>All services are provided by certified and experienced professionals.</p>
                                </div>
                                <div className='why_chose_services_card'>
                                    <h5>Customer Focused</h5>
                                    <p>We prioritize your satisfaction with customized service offerings.</p>
                                </div>
                                <div className='why_chose_services_card'>
                                    <h5>Efficient and Reliable</h5>
                                    <p>Enjoy timely and reliable services for all your property needs.</p>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <img className='rounded_corner' src={'../Images/service-side-image.png'} width={'100%'} alt="" />
                            </div>
                        </div>
                    </div>


                    <div className="features_listing_services">
                        <div className='top_header_services '>

                            <h5 className='color-primary text-center'>Featured Listings </h5>
                            <div className='big-heading text-center'>Explore Our Top  <span>Services</span> </div>
                        </div>
                        <div className="feature_listing_cards mt-4">
                            <div className="feature_listing_card">
                                <img src="../Images/home_reno.png" width={'100%'} alt="" />
                                <div className="bottom_content">
                                    <h4>Interior Design</h4>
                                    <div>Transform your space with our creative designers.</div>
                                </div>
                            </div>
                            <div className="feature_listing_card">
                                <img src="../Images/interior_des.png" width={'100%'} alt="" />
                                <div className="bottom_content">
                                    <h4>Home Renovations</h4>
                                    <div>Upgrade your home with our expert renovation services.</div>
                                </div>
                            </div>
                            <div className="feature_listing_card">
                                <img src="../Images/property_care_service.png" width={'100%'} alt="" />
                                <div className="bottom_content">
                                    <h4>Property Management</h4>
                                    <div>Let us handle your property needs with top-notch management solutions.</div>
                                </div>
                            </div>
                            <div className="feature_listing_card">
                                <img src="../Images/valuation_advicer_service.png" width={'100%'} alt="" />
                                <div className="bottom_content">
                                    <h4>Valuation Advisors</h4>
                                    <div>Let us handle your property needs with top-notch management solutions.</div>
                                </div>
                            </div>
                            <div className="feature_listing_card">
                                <img src="../Images/loan_hub_service.png" width={'100%'} alt="" />
                                <div className="bottom_content">
                                    <h4>Loan Hub
                                    </h4>
                                    <div>Let us handle your property needs with top-notch management solutions.</div>
                                </div>
                            </div>
                            <div className="feature_listing_card">
                                <img src="../Images/property_carerr.png" width={'100%'} alt="" />
                                <div className="bottom_content">
                                    <h4>Legal Expertise</h4>
                                    <div>Let us handle your property needs with top-notch management solutions.</div>
                                </div>
                            </div>

                            {full ? (<>
                                <div className="feature_listing_card">
                                    <img src="../Images/marketing_service.png" width={'100%'} alt="" />
                                    <div className="bottom_content">
                                        <h4>Marketing Mastery</h4>
                                        <div>Let us handle your property needs with top-notch management solutions.</div>
                                    </div>
                                </div>
                                <div className="feature_listing_card">
                                    <img src="../Images/mortrage_service.png" width={'100%'} alt="" />
                                    <div className="bottom_content">
                                        <h4>Mortgage Services
                                        </h4>
                                        <div>Let us handle your property needs with top-notch management solutions.</div>
                                    </div>
                                </div>
                                <div className="feature_listing_card">
                                    <img src="../Images/real_state_advisory_service.png" width={'100%'} alt="" />
                                    <div className="bottom_content">
                                        <h4>Real Estate Advisory
                                        </h4>
                                        <div>Let us handle your property needs with top-notch management solutions.</div>
                                    </div>
                                </div>
                            </>
                            ) : ""}
                        </div>
                        <div className='mt-4 d-flex justify-content-center'>
                            <button className='transparent-btn' onClick={() => setFull(true)}>
                                View All Services
                            </button>
                        </div>
                    </div>


                    <div className="features_category_services">
                        <div className='top_header_services '>

                            <h5 className='color-primary text-center'>Featured Categories  </h5>
                            <div className='big-heading text-center'>Services at Your <span>Fingertips</span> </div>
                        </div>

                        <div className="category_cards mt-4">
                            <div className="category_card">
                                <div className="category_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/category_v1.png" alt="" width={'50px'} />
                                </div>
                                <h4>Cleaning Solutions</h4>

                                <div className="color-gray text-center">From routine cleaning to deep cleaning, we cover it all.</div>
                                <button className='gray-btn'>View More</button>
                            </div>
                            <div className="category_card">
                                <div className="category_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/category_v1.png" alt="" width={'50px'} />
                                </div>
                                <h4>Landscaping Services</h4>

                                <div className="color-gray text-center">Enhance your outdoor space with our landscaping services.</div>
                                <button className='gray-btn'>View More</button>
                            </div>
                            <div className="category_card">
                                <div className="category_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/category_v1.png" alt="" width={'50px'} />
                                </div>
                                <h4>Legal Expertise</h4>

                                <div className="color-gray text-center">Get legal advice tailored to property laws and regulations.</div>
                                <button className='gray-btn'>View More</button>
                            </div>
                            <div className="category_card">
                                <div className="category_logo-image">
                                    <img src="https://demo6.listivotheme.com/wp-content/uploads/2022/08/category_v1.png" alt="" width={'50px'} />
                                </div>
                                <h4>Loan Hub</h4>

                                <div className="color-gray text-center">Finance your property dreams with our expert loan advisory.</div>
                                <button className='gray-btn'>View More</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default Services
