import React from "react";
import Slider from "../../../Components/Common/Slider/Slider";

const IndianCitiesSlider2 = () => {
  // for slider
  const sliderEightProductService = [
    {
      name: "Delhi / NCR",

      url: "./Images/delhi2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Mumbai",

      url: "./Images/mumbai2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Bangalore",

      url: "./Images/banglore2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Hyderabad",

      url: "./Images/hyderabad2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Pune",

      url: "./Images/pune2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Kolkata",

      url: "./Images/kolkata2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Chennai",

      url: "./Images/chennai2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Ahmedabad",

      url: "./Images/ahmedabad2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Jaipur",

      url: "./Images/jaipur2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Surat",

      url: "./Images/surat2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Lucknow",

      url: "./Images/lucknow2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Bhopal",

      url: "./Images/bhopal2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Chandigarh",

      url: "./Images/chandigarh2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Coimvatore",

      url: "./Images/coimbatore2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Indore",

      url: "./Images/indore2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Visakhapatnam",

      url: "./Images/visakhapatnam2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Bhubneswar",

      url: "./Images/bhubaneswar2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Patna",

      url: "./Images/patna2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "kochi",

      url: "./Images/kochi2.png",

      group_name: "15,500+ Properties",
    },
    {
      name: "Nagpur",

      url: "./Images/nagpur2.png",

      group_name: "15,500+ Properties",
    },
  ];

  const sliderEightresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderEightproductTemplate = (product) => {
    return (
      <div className="Hot-Picks-slider-card2 border">
        <div className="rounded-image2">
          <img src={product.url} width={"100%"} height={""} alt="" />
        </div>
        <div className="content-div-hot2">
          <div className="h5-heading2">
            <h5>{product.name}</h5>
          </div>
          <div className="group-name2 mt-1">{product.group_name} </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Slider
        ProductService={sliderEightProductService}
        responsiveOptions={sliderEightresponsiveOptions}
        productTemplate={sliderEightproductTemplate}
      />
    </>
  );
};

export default IndianCitiesSlider2;
