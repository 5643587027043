import React from 'react'
import './SidebarMenu.css'
import { FaAngleDown, FaAngleUp, FaRegUser } from 'react-icons/fa6'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { GoTriangleDown, GoTriangleRight } from 'react-icons/go'
import { MdCall } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
const SidebarMenu = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div className='sidebar-menu-body'>
     <div className='login-btn ps-2' onClick={()=>Navigate('/login-register/login')}>
     <img src="./Images/login-user-icon2.png" width={"20px"} /> Login / Register
     </div>

     <hr/>
     <div className='second-nav-content'>
     <Accordion expandIcon={<FaAngleDown  size={15} />} collapseIcon={ <FaAngleUp   size={15} />}   multiple >
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2">  Buy</div>}  className="border-0 bg-none" >
             <div className="color-down-content">
             <div className="sidebar-drop-content mt-0">
                  {" "}
                Buy a Home

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                Land / Plot

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Commercial
                </div>
                <div className="sidebar-drop-content">
                  {" "}
                Popular Areas
                </div>
                <div className="sidebar-drop-content">
                  {" "}
          Insights
                </div>
                <div className="sidebar-drop-content">
                  {" "} Articles & News
                </div>
             
             </div>
                </AccordionTab>
                <div className="divider-down my-3"></div>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Rent</div>}>
                <div className="color-down-content">
             <div className="sidebar-drop-content mt-0">
                  {" "}
                Buy a Home

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                Land / Plot

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Commercial
                </div>
                <div className="sidebar-drop-content">
                  {" "}
                Popular Areas
                </div>
                <div className="sidebar-drop-content">
                  {" "}
          Insights
                </div>
                <div className="sidebar-drop-content">
                  {" "} Articles & News
                </div>
             
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Sell </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Rental Services


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Rent a Home


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  For Renters

                </div>
             */}
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Commercial </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Owner Services


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Manage Property


                </div>
           
                <div className="sidebar-drop-content">
                  {" "}
                  Real Estate Ownership

                </div> */}
               
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Services </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Dealer Services


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Builder Solutions


                </div>
               
                <div className="sidebar-drop-content">
                  {" "}
                  Building Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Dealer Support

                </div> */}
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2">More </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Commercial Real Estate

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Business Properties

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Commercial Listings

                </div>
             
                <div className="sidebar-drop-content">
                  {" "}
                  Commercial Leasing


                </div> */}
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Trends </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Full Service List


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  All Services Offered


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Customizable Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Service Overview

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Personalized Assistance

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Comprehensive Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Our Full Range

                </div>
                 */}
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Advertise  </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Full Service List


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  All Services Offered


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Customizable Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Service Overview

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Personalized Assistance

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Comprehensive Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Our Full Range

                </div>
                 */}
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Pages </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Full Service List


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  All Services Offered


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Customizable Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Service Overview

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Personalized Assistance

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Comprehensive Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Our Full Range

                </div>
                 */}
             </div>
                </AccordionTab>
                <AccordionTab headerClassName={"accordian-header-menu"} headerTemplate={<div className="ps-2"> Blog </div>}>
                <div className="ps-4">
             {/* <div className="sidebar-drop-content mt-0">
                  {" "}
                  Full Service List


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  All Services Offered


                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Customizable Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Service Overview

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Personalized Assistance

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Comprehensive Services

                </div>
                <div className="sidebar-drop-content">
                  {" "}
                  Our Full Range

                </div>
                 */}
             </div>
                </AccordionTab>
            </Accordion>
     </div>
     <hr/>
     <div className='top-nav-content'>
      <div className='nav-data' onClick={()=>Navigate('/premium_access')}>Premium Access
</div>
      <div className='nav-data' onClick={()=>Navigate('')}>How its Work
</div>
      <div className='nav-data' onClick={()=>Navigate('/lead_market')}>Lead-Market
</div>
      <div className='nav-data' onClick={()=>Navigate('/advertise')}>Advertise
</div>
      <div className='nav-data'>
      Download App

      </div>
      
     </div>
     <hr/>
     <div className='contact-section'>
      <div className='contact-heading'>Contact</div>
      <div className='contact-detail mt-2'>
      <div className="phone-icon">
                        <MdCall size={20} />
                      </div>
                      <div className="">
                        <p>Toll Free | 9 AM - 8 PM IST</p>
                        <div className="number">1800-41-99099</div>
                      </div>
      </div>
      <hr />
      <div className='contact-detail'>
      <div className="phone-icon">
                        <MdCall size={20} />
                      </div>
                      <div className="">
                        <p>Toll Free | 9 AM - 8 PM IST</p>
                        <div className="number">1800-41-99099</div>
                      </div>
      </div>
     </div>
      </div>
    </>
  )
}

export default SidebarMenu
