import React, { useState } from 'react'
import './ListProperty.css'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from 'primereact/inputnumber';
const ListProperty = () => {
    const [number, setNumber] = useState();
    const categories = [
        { name: 'Owner', key: 'O' },
        { name: 'Developer', key: 'D' },
        { name: 'Agent', key: 'Ag' },
        { name: 'Agency', key: 'A' },


    ];
    const plans = [
        { name: 'Buy', key: 'B' },
        { name: 'Rent-lease', key: 'R' },
        { name: 'Sell', key: 'S' },
        { name: 'Commercial', key: 'C' },
        { name: 'PG', key: 'P' },
    ];
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [selectedPlan, setSelectedPlan] = useState(plans[0]);
    const cardData = [
        {
            name: "Easy Listings",
            description: "Post your property in just a few clicks."
        },
        {
            name: "Smart Matches",
            description: "Our technology connects you with the right buyers and renters quickly."
        },
        {
            name: "Current Insights",
            description: "Get the latest property trends for smart decision-making."
        },
        {
            name: "Supportive Community",
            description: "Join fellow property lovers in a vibrant, supportive space."
        },
        {
            name: "Personal Touch",
            description: "Our friendly team is here for you, day or night."
        },
        {
            name: "Special Welcomes",
            description: "Celebrate our launch with special deals for early users."
        },
        {
            name: "Trust Built-In",
            description: "We’re committed to honesty and clarity in every deal."
        },
        {
            name: "Make Connections",
            description: "Network with professionals and enthusiasts alike."
        },
        {
            name: "Green Choices",
            description: "Find and list properties with a focus on sustainability."
        },
    ]
    return (
        <>
      
            <div className='list_property_cont'>
                <div className="top_list_section">
                    <div className="container position-relative d-flex justify-content-between gap-0">
                        <div>
                            <div className="big-heading">List Your Property
                            </div>
                            <div className="big-para">
                                Begin your property listing with ease.
                            </div>

                        </div>
                        <div className='list_property_form'>
                            <h4 className='p-0 m-0'>List your Property</h4>
                            <div className=''>
                                <label htmlFor="" className='color-gray'>Who are you ?</label>
                                <div className="d-flex gap-4 flex-wrap mt-1">
                                    {categories.map((category) => {
                                        return (
                                            <div key={category.key} className="d-flex gap-1 align-items-center">
                                                <RadioButton inputId={category.key} name="category" value={category} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory.key === category.key} />
                                                <label htmlFor={category.key} className="ml-2">{category.name}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className=''>
                                <label htmlFor="" className='color-gray'>What’s your Plan ?</label>
                                <div className="d-flex flex-wrap gap-4 mt-1">
                                    {plans.map((plan) => {
                                        return (
                                            <div key={plan.key} className="d-flex gap-1 align-items-center">
                                                <RadioButton inputId={plan.key} name="category" value={plan} onChange={(e) => setSelectedPlan(e.value)} checked={selectedPlan.key === plan.key} />
                                                <label htmlFor={plan.key} className="ml-2">{plan.name}</label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className=''>
                                <label htmlFor="" className='color-gray'>Your contact number</label>
                                <div className='d-flex number_section_list gap-3'>
                                    <div className='select_div text-nowrap px-2 color-gray' disabled>
                                        IND +91
                                    </div>
                                    <InputNumber style={{ width: "100%" }} value={number} placeholder='Enter Mobile Number' onValueChange={(e) => setNumber(e.value)} useGrouping={false} maxLength={12} />
                                </div>
                            </div>
                            <div className='whatsapp_description mt-2'>
                                <img src="./Images/logos_whatsapp-icon.png" width={'40px'} alt="" />
                                <span>Share your WhatsApp to start receiving inquiries about <br /> your property</span>
                            </div>
                            <button className='primary-btn mt-2'>
                                Get Started Now
                            </button>
                        </div>
                    </div>
                </div>
                <div className='container greeting_section'>
                    <div className='greeting_heading'>Welcome to <span>sftarea.com</span>
                    </div>
                    <p className='greeting_paragraph'>The New Era of Real Estate Listings</p>


                    <div className='greeting_cards'>
                        {
                            cardData.map((res, index) => (
                                <div index={index} className='greeting_card'>
                                    <h5>{res.name}</h5>
                                    <p>{res.description}</p>
                                </div>
                            ))
                        }

                    </div>
                </div>
                <div className='property_game_section'>
                    <div className='container'>
                        <div className='greeting_heading'>Elevate Your Property Game with
                            <span> sftarea.com</span>
                        </div>
                        <p className='greeting_paragraph'> It's As Easy As 1-2-3!</p>

                    </div>
                </div>
                <div className='counting_section'>
                    <div className='container'>
                        <div className="row d-flex justify-content-end">
                            <div className="col-md-7 py-5">
                                <div className='count_card'>
                                    <div className='very_large_num'>01</div>
                                    <div className=''>
                                        <h4>List with Flair</h4>
                                        <div>Just a few clicks and your property stands out on sftarea.com.
                                            Fill in the essentials, upload dazzling photos, and your listing is live!</div>
                                    </div>
                                </div>
                                <div className='count_card'>
                                    <div className='very_large_num'>02</div>
                                    <div className=''>
                                        <h4>Dashboard Dynamics</h4>
                                        <div>Keep your finger on the pulse in your own control center.
                                            Track inquiries, view profiles of interested buyers or renters, and respond in a flash!</div>
                                    </div>
                                </div>
                                <div className='count_card'>
                                    <div className='very_large_num'>03</div>
                                    <div className=''>
                                        <h4>Connections that Click</h4>
                                        <div>Our smart system brings you closer to the right crowd. Chat, network, and build relationships all within the platform!</div>
                                    </div>
                                </div>
                                <div className='count_card'>
                                    <div className='very_large_num'>04</div>
                                    <div className=''>
                                        <h4>Seal the Deal Seamlessly</h4>
                                        <div> From the first hello to the final handshake, our interface makes every step feel like a breeze. Schedule visits, negotiate terms, and celebrate success with ease!</div>
                                    </div>
                                </div>
                                <div className='count_card'>
                                    <div className='very_large_num'>05</div>
                                    <div className=''>
                                        <h4>Beyond the Sale</h4>
                                        <div>After you seal the deal, we're still here to support you. Enjoy ongoing tips, market insights, and community events to keep you at the top of the real estate game!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                <div className='end_footer_section'>
            <div className='left_content'>
              <div className='normal_tag'>Need Help Choosing a Plan?</div>
              <h2 className='mt-2'>We're Here to Help!</h2>
              <p>Not sure which plan is right for you? Our team is ready to assist you at any time. Just click the button below to chat with us live.
              </p>
            </div>
            <div className='right-btn'>
              <button className='primary-btn'>
                Go To Live Chat page
              </button>
            </div>
          </div>

                </div>
            </div>
           
        </>
    )
}

export default ListProperty
