import React from "react";
import Header from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import "./Homepage.css";
import Search from "../../Components/Common/Search/Search";
import SliderOne from "./MultiSliders/SliderOne";
import CategorySlider from "./MultiSliders/CategorySlider";
import FeaturesPropertySlider from "./MultiSliders/FeaturesPropertySlider";
import VisionarySlider from "./MultiSliders/VisionarySlider";
import PrestigiousSlider from "./MultiSliders/PrestigiousSlider";
import TopSelectionsSlider from "./MultiSliders/TopSelectionsSlider";
import PremierSlider from "./MultiSliders/PremierSlider";
import LatestListing from "./MultiSliders/LatestListing";
import HotPicksSlider from "./MultiSliders/HotPicksSlider";
import { FaArrowRightLong } from "react-icons/fa6";
import PropertyServices from "./MultiSliders/PropertyServicesSlider";
import IndianCitiesSlider from "./MultiSliders/IndianCitiesSlider";
import FeatureCollectionsSlider from "./MultiSliders/FeatureCollectionsSlider";
import SpotLightSlider from "./MultiSliders/SpotLightSlider";
import TrustedCompaniesSlider from "./MultiSliders/TrustedCompaniesSlider";
import IndianCitiesSlider2 from "./MultiSliders/IndianCitiesSlider2";
import TestomonialSlider from "./MultiSliders/TestomonialSlider";
import TopAgenciesSlider from "./MultiSliders/TopAgenciesSlider";
import TopAgentsSlider from "./MultiSliders/TopAgentsSlider";
import { GiCheckMark } from "react-icons/gi";
const Homepage = () => {
  const sliderTestmonialProductService = [
    {
      comment: `As a first-time home buyer, the guidance and listings provided on this site were invaluable. The detailed information about each property made my decision much easier!`,
      url: "./Images/client-image1.png",
      name:"Avinash Reddy",
      position:"Buying Properties",
      location:"Hyderabad",
   
    },
    {
      comment: `I found the perfect apartment through this website, and what's best, I didn't have to pay any service fees! The process was transparent and the customer support was very responsive!`,
      url: "./Images/client-image2.png",
      name:"Sumitra Choudhary",
      position:"Buying Properties",
      location:"Vijayawada",
   
    },
    {
      comment: `Excellent platform with a wide range of options available in different price ranges. The search filters helped me find exactly what I was looking for!`,
      url: "./Images/client-image3.png",
      name:"Karthik Rao ",
      position:"Buying Properties",
      location:"Tirupati",
   
    },
    {
      comment: `This website made my property search a breeze with its user-friendly interface and detailed listings. I found a great place within my budget and the locality I desired!`,
      url: "./Images/client-image4.png",
      name:"Nazia Shaik ",
      position:"Buying Properties",
      location:"Visakhapatnam",
   
    },
    {
      comment: `Thanks to this portal, I bought my first home smoothly and efficiently. The no-charge policy is a real money-saver!`,
      url: "./Images/client-image.png",
      name:"Rohan Naidu ",
      position:"Buying Properties",
      location:"Nellore",
   
    },
    {
      comment: `Selling my house was faster and easier than I expected. The listing process was straightforward, and I appreciated not having to pay any service charges!`,
      url: "./Images/client-image5.png",
      name:"Manisha Choudhary ",
      position:"Selling Properties",
      location:"Warangal",
   
    },
    {
      comment: `Great exposure for my property and received inquiries from genuine buyers. Very pleased with the service and especially that it’s free!`,
      url: "./Images/client-image1.png",
      name:"Srinivas Reddy  ",
      position:"Selling Properties",
      location:"Karimnagar",
   
    },
    {
      comment: `This portal helped me connect with serious buyers without intermediaries. The process was transparent and simple!`,
      url: "./Images/client-image3.png",
      name:"Deepak Rao",
      position:"Selling Properties",
      location:"Guntur",
   
    },
    {
      comment: `I managed to sell my property in just a few weeks, thanks to the excellent visibility my listing got on this website. Also, the absence of service charges was a huge plus!`,
      url: "./Images/client-image2.png",
      name:"Fatima Shaik ",
      position:"Selling Properties",
      location:"Rajahmundry",
   
    },
    {
      comment: `A straightforward and effective platform for sellers. I listed my property, and it was sold quicker than I thought, without any fees!`,
      url: "./Images/client-image1.png",
      name:"Prakash Reddy  ",
      position:"Selling Properties",
      location:"Ongole",
   
    },
    {
      comment: `The commercial property listings on this site are comprehensive and provided me with all the information I needed to make an informed decision. Highly recommended for business real estate needs!`,
      url: "./Images/client-image.png",
      name:"Vijay Kumar Choudhary  ",
      position:"Commercial Properties",
      location:"Nizamabad",
   
    },
    {
      comment: `I found the perfect office space for my startup on this site. The filtering options allowed me to narrow down my choices efficiently!`,
      url: "./Images/client-image2.png",
      name:"Lakshmi Reddy  ",
      position:"Commercial Properties",
      location:"Hyderabad",
   
    },
    {
      comment: `Excellent resource for finding commercial spaces. The detailed descriptions and photos made choosing much easier!`,
      url: "./Images/client-image1.png",
      name:"Suresh Rao  ",
      position:"Commercial Properties",
      location:"Kakinada",
   
    },
 
  
      
  ];
  

  return (
    <>
      <div className="homepage">
      
        {/* section one Banner start */}
        <div className="section-one">
          <div className="container d-flex flex-column gap-0">
            <div className="big-heading">Looking For A Home?</div>
            <div className="big-para">
              Find a variety of properties that suit you very easily, forget all
              difficulties in finding a residence for you
            </div>
            <div className="w-100" style={{ position: "relative" }}>
              <Search />
            </div>
          </div>
        </div>
        {/* main container start */}
        <div className="container main-cont">
          {/* section two Category slider start */}
          <div className="section-two">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Our Services <span></span>
              </h4>
            </div>
            <div>
              <SliderOne />
            </div>
          </div>
          {/* section Featured Collections start  */}
          <div className="section-ninth">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                {" "}
                Featured Collections <br />
                {/* <span className=""> Popular properties by our expert agents</span> */}
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeatureCollectionsSlider />
            </div>
          </div>
          {/* section subcategory start */}
          {/* <div className="section-three">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>Categories</h4>
               <div className="see-all-btn ">
                <span className="">See all</span> <FaArrowRightLong  fontSize={'13'}   />
              </div>
            </div>
            <div>
              <CategorySlider />
            </div>
          </div> */}
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Prime Property Picks:
                <span className=""> Discover our top property choices!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div
            className="section-spotlight-homepage"
            style={{ position: "relative" }}
          >
            <div className="spotlight-heading">
              <h4>Signature Living Spaces :</h4>
              <p>Explore our distinct living spaces!</p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>
          {/* slider  */}

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Premium Living Properties:
                <span> Find premium living options here!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Preferred Owner Properties:
                <span> Discover beloved homes!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div className="section-spotlight-homepage">
            <div className="spotlight-heading">
              <h4>Landmark Luxury Homes : </h4>
              <p> Experience Luxury at Its Best!</p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Fresh Property Selection:
                <span> Discover the Latest Listings!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Up-and-Coming Property Developments:{" "}
                <span> Discover Next-Gen Projects!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div className="section-spotlight-homepage">
            <div className="spotlight-heading">
              <h4>Top Selling Properties in the month : </h4>
              <p>This Month's Sales Leaders!</p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Trending Residences Picks:
                <span> Discover Trending Homes, Just for You!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section Featured Properties start */}
          <div className="section-fourth">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Smart Investment Properties:{" "}
                <span> Invest in High Potential Properties!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          <div className="section-spotlight-homepage">
            <div className="spotlight-heading">
              <h4>Independent Houses and Villas : </h4>
              <p>Elegant Villas for Elegant Living!</p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* section Visionary Ventures Projects start */}
          <div className="section-seventh">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                {" "}
                Residential and Farmlands:
                <span> Find Your Home with a Green Touch!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <VisionarySlider />
            </div>
          </div>
          {/* section In Spotlight start */}
          {/* <div className="section-eightth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                In Spotlight: <span> Find your best places</span>
              </h4>
            </div>
            <div>
              <Spotlight url={"./Images/Spotlightimage.png"} />
            </div>
          </div> */}
          {/* section Prime Projects start */}
          {/* <div className="section-sixth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Prime Projects:{" "}
                <span>Top Projects and Developers Unveiled</span>
              </h4>
            </div>
            <div>
              <FeaturesPropertySlider />
            </div>
          </div> */}
          {/* section  Prestigious Properties start */}
          {/* <div className="section-ninth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                {" "}
                Prestigious Properties:{" "}
                <span> Hyderabad's Leading Projects</span>
              </h4>
            </div>
            <div>
              <PrestigiousSlider />
            </div>
          </div> */}
          {/* section Top Selections start */}
          {/* <div className="section-tenth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Top Selections: <span>Highly Recommended Projects </span>
              </h4>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div> */}
          {/* section Hot Picks start */}
          {/* <div className="section-eleventh">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Hot Picks: <span>Hyderabad's Premier Developments </span>
              </h4>
            </div>
            <div>
              <HotPicksSlider />
            </div>
          </div> */}
          {/* section Top Selections start */}
          {/* <div className="section-twelth">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Top Selections: <span>Highly Recommended Projects </span>
              </h4>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div> */}
          {/* section SFTAREA Premier Properties start */}
          {/* <div className="section-thirteen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>SFTAREA Premier Properties</h4>
            </div>
            <div>
              <PremierSlider />
            </div>
          </div> */}
          {/* <div className="section-fourteen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                In Spotlight: <span>Find your best places </span>
              </h4>
            </div>
            <div>
              <Spotlight url={"./Images/Spotlightimage.png"} />
            </div>
          </div> */}
          {/* <div className="section-fifteen">
            <div className="top-heading d-flex flex-wrap justify-content-between">
              <h4>
                Latest Listings: <span>Discover the Newest Properties</span>
              </h4>
            </div>
            <div>
              <LatestListing />
            </div>
          </div> */}

          {/* Investment Opportunit */}

          <div className="section-sixteen">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Commercial Investments in Hyderabad:{" "}
                <span> Invest in Hyderabad's growing market!</span>
                {/* <div className="mt-2 fs-5">
                  <span>Profitable Properties or Opportunity Investments:</span>
                  <div>
                    <span>Profitable for Investment</span>
                  </div>
                </div> */}
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div>

          {/* section In Spotlight start */}
          <div className="section-spotlight-homepage">
            <div className="spotlight-heading">
              <h4>Invest in Hyderabad's top commercial assets: </h4>
              <p>Choose key commercial areas!</p>
            </div>
            <div>
              <SpotLightSlider />
            </div>
          </div>

          {/* Income Generating */}
          <div className="section-seventeen">
            <div className="top-heading d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4>
                Commercial Office & Shops in Hyderabad:{" "}
                <span>Find your perfect retail locations!</span>
              </h4>
              <div className="see-all-btn ">
                <span className="">Explore all Properties</span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div>
            </div>
            <div>
              <TopSelectionsSlider />
            </div>
          </div>
          {/* section realState */}
          <div className="section-seventeen">
            <div className="top-heading  d-flex flex-wrap justify-content-sm-between justify-content-center">
              <h4 className="">
              Explore Properties in Major Cities:{" "}
                <span>
                  {" "}
                  Find Ideal Homes and Investments in Top Cities!
                </span>
              </h4>
              {/* <div className="see-all-btn ">
                <span className="">Explore all </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div> */}
            </div>
            <div>
              <IndianCitiesSlider2 />
            </div>
          </div>
          {/* why-chose-us section */}
          <div className="why-chose-us-cards-section">
            <div className="top-heading  d-flex flex-wrap justify-content-between ">
              <h4 className="">
              Why SFTAREA.com?{" "}
                <span>
                  {" "}
                  Discover the Unique Benefits of Our Platform!
                </span>
              </h4>
            </div>
            {/* <div className="download-top-heading">
              Why Choose Sftarea.com?
              <p className="para-download">
                A direct and straightforward heading that encourages users to
                understand the unique benefits your platform offers.
              </p>
            </div> */}
            <div className=" d-flex flex-wrap justify-content-center ">
              <div className="choose-card">
                <div className="post-logo-image">
                  <img
                    src={"./Images/smart-post-logo.svg"}
                    width={"70px"}
                    alt=""
                  />
                </div>
                <div className="resion-heading mb-1">
                Buy & Sell 
              
                </div>
                <div className="resion-heading mt-0 mb-0">
                Fast & Free Listings
                </div>
                <div className="card-why-paragraph my-0">
                List your property for free and quickly connect with buyers and sellers.

                </div>
               
                <br/>
                {/* <h6 className=" mb-0 mt-2 ">Quick posts, Quick deals, Absolutely free.</h6>
                  <div className="card-why-paragraph mt-1 mb-2">
              Join today and speed up buying or selling.
                  </div>
                  <button className="primary-btn py-2 px-0 rounded-2 mb-3">Explore Buying Commercial</button> */}
                <div className="card-image-post">
                  <div className="overlay"></div>
                  <img
                    src={"./Images/smart-post-image.jpg"}
                    width={"100%"}
                    alt=""
                  />
                </div>
                <div className="card-why-paragraph">
                  <b>Explore Buying and Selling:  </b> Post your property with ease and attract buyers faster.

                </div>
                <div className="see-all-listing-btn">
                  See all listings <FaArrowRightLong fontSize={"13"} />
                </div>
              </div>
              <div className="choose-card">
                <div className="post-logo-image">
                  <img
                    src={"./Images/filter-post-logo.svg"}
                    width={"70px"}
                    alt=""
                  />
                </div>
                <div className="resion-heading mb-1">
                Commercial Properties

              
                </div>
                <div className="resion-heading mt-0 mb-0">
                Prime Business Spaces
                </div>
                <div className="card-why-paragraph my-0">
                Easily find and lease the perfect commercial space.

                </div>
                <br/>
                {/* <h6 className=" mb-0 mt-2 ">Efficient Commercial Listings, Quick Deals, Absolutelyb free.</h6>
                  <div className="card-why-paragraph mt-1 mb-2">
                Join Now and fast-track your commercial property transactions on Sftarea.com.
                  </div>
                  <button className="primary-btn py-2 px-0 rounded-2 mb-3">Explore Leasing Commercial </button> */}
                <div className="card-image-post">
                  <div className="overlay"></div>
                  <img
                    src={"./Images/filter-image.jpg"}
                    width={"100%"}
                    alt=""
                  />
                </div>
                <div className="card-why-paragraph">
           Always updated, so you never miss top opportunities.

                
                </div>
                <div className="see-all-listing-btn">
                  See all listings <FaArrowRightLong fontSize={"13"} />
                </div>
              </div>
              <div className=" choose-card">
                <div className="post-logo-image">
                  <img
                    src={"./Images/getsupport-icon.png"}
                    width={"70px"}
                    alt=""
                  />
                </div>
                <div className="resion-heading mb-1">
                Rentals & Co-Living


              
                </div>
                <div className="resion-heading mt-0 mb-0">
                Effortless Rentals
                </div>
                <div className="card-why-paragraph my-0">
                Discover rental and co-living spaces that fit your lifestyle with no fees.


                </div>
                <br/>
                {/* <h6 className=" mb-0 mt-2 ">Discover your Next PG or Co-living Home</h6>
                  <div className="card-why-paragraph mt-1 mb-2">
                Access diverse room options in the heart of India's major cities, tailored for community living.
                  </div>
                  <button className="primary-btn py-2 px-0 rounded-2 mb-3">Explore PG/Co-living </button> */}
                <div className="card-image-post">
                  <div className="overlay"></div>
                  <img
                    src={"./Images/get-support-lady.png"}
                    width={"100%"}
                    alt=""
                  />
                </div>
                <div className="card-why-paragraph">
               <b> Explore Comfortable Rentals: </b>: Updated options to find your ideal home.

               

                
                </div>
                <div className="see-all-listing-btn">
                  See all listings <FaArrowRightLong fontSize={"13"} />
                </div>
              </div>

              {/* <div className=" choose-card">
                  <div className="post-logo-image">
                    <img src={'./Images/getsupport-icon.png'} width={'70px'} alt="" />
                  </div>
                  <div className="resion-heading mb-0">Great Support</div>
                  <h6 className=" mb-0 mt-2 ">Discover your Next PG or Co-living Home</h6>
                  <div className="card-why-paragraph mt-1 mb-2">
                Access diverse room options in the heart of India's major cities, tailored for community living.
                  </div>
                  <button className="primary-btn py-2 px-0 rounded-2 mb-3">Explore PG/Co-living </button>
                  <div className="card-image-post">
                  <div className='overlay'></div>
                  <img src={'./Images/get-support-lady.png'}   width={'100%'} alt="" />
                  </div>
                  <div className="card-why-paragraph">
               Efficiently search through updated options to find a shared or private room that fits your lifestyle. 
                  </div>
                  <div className="see-all-listing-btn">
                  See all listings   <FaArrowRightLong fontSize={"13"} />
                  </div>
                 </div> */}
            </div>
          </div>

          {/* section realState */}
          <div className="section-seventeen">
            <div className="top-heading  d-flex flex-wrap justify-content-between ">
              <h4 className="">
              Top Neighborhoods in Hyderabad:{" "}
                <span>
                  {" "}
                  Explore the Best Living Spaces in Hyderabad!
                </span>
              </h4>
              {/* <div className="see-all-btn ">
                <span className="">Explore all </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div> */}
            </div>
            <div>
              <IndianCitiesSlider />
            </div>
          </div>

          {/* section  top Agancies start */}
          <div className="section-eighteen">
            <div className="top-heading  d-flex flex-wrap justify-content-between ">
              <h4 className="">
                Our Top Agencies:{" "}
                <span>
                  {" "}
                  Partner with Leading Real Estate Agencies!
                </span>
              </h4>
            </div>
            {/* <div className="download-top-heading">
              Our Top Agencies
              <p className="para-download">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque corrupti quos
                dolores!
              </p>
            </div> */}

            <div>
              <TopAgenciesSlider />
            </div>
          </div>
          {/* section  top Agents start */}
          <div className="section-ninteen">
            {/* <div className="top-heading  d-flex flex-wrap justify-content-between ">
              <h4 className="">
                Our Top Agents :{" "}
                <span>
                  {" "}
                  At vero eos et accusamus et iusto odio more dignissimos
                  ducimus qui blanditiis praesentium voluptatum deleniti atque
                  corrupti!
                </span>
              </h4>
            </div> */}
            {/* <div className="download-top-heading">
            Our Top Agents 

              <p className="para-download">
              At vero eos et accusamus et iusto odio more dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti!
              </p>
            </div> */}

            <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap">
              <div className="left-agents-text">
                <h3 className="agents-heading">Browse Agents </h3>
                <p className="agents-paragraph">
                Connect with Expert Agents for Property Guidance!
                </p>
              </div>
              <div className="agents-cards-section">
                <TopAgentsSlider />
              </div>
            </div>
          </div>
          {/* section  Happy client reviews start */}
          <div className="section-twenty">
            <div className="top-heading  d-flex flex-wrap justify-content-between ">
              <h4 className="">
              Client Success Stories:{" "}
                <span>
                  {" "}
                  Hear from Clients Who Achieved Their Real Estate Dreams!
                </span>
              </h4>
            </div>
            {/* <div className="download-top-heading">
              Hear from Our Happy Clients
              <p className="para-download">
                Incorporate a testimonial example here to build trust and
                provide social proof of your service's effectiveness.
              </p>
            </div> */}

            <div>
              <TestomonialSlider sliderTestmonialProductService={sliderTestmonialProductService} />
            </div>
          </div>
        </div>
        {/* previous download option container */}
        <div className="download-container">
          <div className="container">
            <div className="row d-flex align-items-center ">
              <div className="col-12  col-lg-9">
                <div className="download-left-side ">
                  <div className="donwload_text_main_div">
                    <div className="download_text">Get the SFTAREA App</div>
                    <p className="download_text_pra">
                      Real Estate at Your Fingertips
                    </p>
                    <p className="download_text_small">
                      Unlock the full potential of SFTAREA.com with our mobile
                      app. It's the easiest way to discover, manage, and engage
                      with real estate anytime, anywhere.
                    </p>

                    <div className="right-para mt-3">
                    <GiCheckMark />  <span className="bold-text-right ps-1">Instant Notifications:</span> <span className="para">Be the first to know about new listings with real-time alerts.
</span>
                    </div>
                    <div className="right-para mt-3">
                    <GiCheckMark />  <span className="bold-text-right ps-1"> Effortless Management:</span> <span className="para">Track and manage your listings or interests seamlessly.

</span>
                    </div>
                    <div className="right-para mt-3 mb-4">
                    <GiCheckMark />  <span className="bold-text-right ps-1"> Personalized Experience:</span> <span className="para">Get recommendations tailored just for you.


</span>
                    </div>
                  </div>
{/* <h4>Download Now for Free</h4> */}
                  <div className="d-flex download-app-images gap-3 mt-3 mb-6">
                    <img src="/Images/playstore.png"  alt="" />
                    <img src="/Images/appstoreIcon.png"  alt="" />
                  </div>
                </div>
              </div>
            
              <div className="col-12  mt-md-0 mt-1 col-lg-3">
                <div className="download-right-side ">
                  <img src={"/Images/handimage2.png"} height=""alt=""  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="download-top-heading">
        Why Choose Us
        <p className="para-download">It’s our job to make sure that you get the best possible deal on the property.</p>
        </div>
        <div className="why-choose-us-container">
          <div className="container">
            <div className="row d-flex ">
              <div className="col-7 pb-4">
                <div className="choose-left-side ">
                 <div className="choose-card">
                  <div className="post-logo-image">
                    <img src={'./Images/smart-post-logo.svg'} width={'70px'} alt="" />
                  </div>
                  <div className="resion-heading">Thousands of posts every day</div>
                  <div className="card-image-post">
                  <div className='overlay'></div>
                  <img src={'./Images/smart-post-image.jpg'}   width={'100%'} alt="" />
                  </div>
                  <div className="card-why-paragraph">
                  The lists are always refreshed and updated constantly, you will never miss
                  </div>
                  <div className="see-all-listing-btn">
                  See all listings   <FaArrowRightLong fontSize={"13"} />
                  </div>
                 </div>
                 <div className="choose-card">
                  <div className="post-logo-image">
                    <img src={'./Images/filter-post-logo.svg'} width={'70px'} alt="" />
                  </div>
                  <div className="resion-heading">Smart filter</div>
                  <div className="card-image-post">
                  <div className='overlay'></div>
                  <img src={'./Images/filter-image.jpg'}   width={'100%'} alt="" />
                  </div>
                  <div className="card-why-paragraph">
                  Find the right house for you in the shortest amount of time
                  </div>
                  <div className="see-all-listing-btn">
                  See all listings   <FaArrowRightLong fontSize={"13"} />
                  </div>
                 </div>
              
                </div>
              </div>
              <div className="col-5">
                <div className="choose-right-side ">
                 <div className="content-download  d-flex align-items-end flex-column gap-3 flex-end mt-5">
                  
                 <img src="/Images/playstore.png" alt="" width={'150px'} />
                      <img src="/Images/appstoreIcon.png" alt="" width={'150px'} />
                  
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container property-main_container ">
          <div className="row">
            <div className="property_text_heading h3">Property Services</div>
            <p className=" property_text_pra ps-3">
              Donec porttitor euismod dignissim. Nullam a lacinia ipsum, nec
              dignissim purus.
            </p>
          </div>
          <PropertyServices />
        </div> */}
        <div className="container main-cont" style={{paddingTop:"58px !important"}}>
          {/* section trusted companies */}
          <div className="section-seventeen">
            <div className="top-heading  d-flex flex-wrap justify-content-between ">
              <h4 className="">
              India's Top 100 Real Estate Companies:
                <span>
                  {" "}
                  Discover the Leading Firms in India's Real Estate Market!
                </span>
              </h4>
              {/* <div className="see-all-btn ">
                <span className="">Explore all </span>{" "}
                <FaArrowRightLong fontSize={"13"} />
              </div> */}
            </div>
            <div>
              <TrustedCompaniesSlider />
            </div>
          </div>
        </div>
        <div className="footer-banner-normal">
          <div className="container">
            <div className="left-banner-side d-flex align-items-center gap-3">
              <div className="banner-man-image">
                <img src="/Images/men-with-house.png" width={"150px"} alt="" />
              </div>
              <div className="banner-content">
                <h3>Become a Real Estate Agent</h3>
                <p className="banner-para">
                  Elevate your real estate career with free registration at
                  Sftarea.com. . <br />
                  Join our network today and connect with success!
                </p>
              </div>
            </div>
            <div className="right-banner-btn">
              <button className="primary-btn  rounded-3 py-2 px-5">
                Register Now
              </button>
            </div>
          </div>
        </div>
      
      </div>
    </>
  );
};

export default Homepage;
