import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';
import { useNavigate } from 'react-router-dom';

const SliderOne = () => {
  const Navigate = useNavigate()
  // for slider one
  const sliderOneProductService = [
    {
      id: "interior_design",
      name: "Interior Design",
      url: "./Images/interior.png",
    },
    {
      id: "design_architect",
      name: "Design & Architect",
      url: "./Images/Design.png",
    },
    {
      id:"renew_contractors",
      name: "Renew Contractors",
      url: "./Images/Contractors.png",
    },
    {
      id:"loan_hub",
      name: "Loan Hub",
      url: "./Images/loan.png",
    },
    {
      id:"legal_expert",
      name: "Legal Expert",
      url: "./Images/legal.png",
    },
    {
      id:"valuation_advisors",
      name: "Valuation Advisors",
      url: "./Images/valuation.png",
    },
    {
      id:"property_care",
      name: "Property Care",
      url: "./Images/property.png",
    },


    {
      id:"market_mastery",
      name: "Market Mastery",
      url: "./Images/market.png",
    },
    {
      id:"packers_movers",
      name: "Packers & Movers",
      url: "./Images/packers_movers.png",
    },
    {
      id:"home_renovations",
      name: "Home Renovations",
      url: "./Images/home_renew.png",
    },
    {
      id:"mortgage_services",
      name: "Mortgage Services",
      url: "./Images/mortgage.png",
    },
    {
      id:"realState_advisory",
      name: "Real Estate Advisory",
      url: "./Images/realState.png",
    },
    {
      id:"investment_insights",
      name: "Investment Insights",
      url: "./Images/investment_insights.png",
    },
    {
      id:"property_insurance",
      name: "Property Insurance",
      url: "./Images/property_insuranse.png",
    },
    {
      id:"property_inspections",
      name: "Property Inspections",
      url: "./Images/inspection.png",
    },
    {
      id:"home_staging",
      name: "Home Staging",
      url: "./Images/home_staging.png",
    },
    {
      id:"cleaning_solutions",
      name: "Cleaning Solutions",
      url: "./Images/cleaning.png",
    },
    {
      id:"energy_efficiency",
      name: "Energy Efficiency",
      url: "./Images/energy.png",
    },
    {
      id:"green_building",
      name: "Green Building Solutions",
      url: "./Images/green_building.png",
    },
    {
      id:"smart_home",
      name: "Smart Home Solutions",
      url: "./Images/smart_home.png",
    },
    {
      id:"home_security",
      name: "Home Security",
      url: "./Images/home_security.png",
    },
    {
      id:"landscaping_services",
      name: "Landscaping Services",
      url: "./Images/landScaping_service.png",
    },
    {
      id:"phtography_videography",
      name: "Photography & Videography",
      url: "./Images/photography.png",
    },
    {
      id:"event_celebrations",
      name: "Events & Celebrations",
      url: "./Images/event_celebration.png",
    },

    {
      id:"",
      name: "",
      url: "",
    },

  ];

  const sliderOneresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 6,
      numScroll: 2,
    },
    {
      breakpoint: "1199px",
      numVisible: 5,
      numScroll: 2,
    },
    {
      breakpoint: "900px",
      numVisible: 4,
      numScroll: 2,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
      numScroll: 2,
    },
    {
      breakpoint: "575px",
      numVisible: 2,
      numScroll: 1,
    },
  ];
  const sliderOneproductTemplate = (product) => {
    // console.log(product.id)
    return (
      <div className="slider-one-card" onClick={()=>Navigate(`services/${product.id}`)}>
        <div className="circle-image">
          <div className='overlay'></div>
          <img src={product.url} alt="" />
        </div>
        <div className="simple-paragraph">{product.name}</div>

      </div>
    );
  };

  return (
    <>
      <Slider ProductService={sliderOneProductService} responsiveOptions={sliderOneresponsiveOptions} productTemplate={sliderOneproductTemplate} />
    </>
  )
}

export default SliderOne
