import React from 'react'
import Slider from '../../../Components/Common/Slider/Slider';

const TrustedCompaniesSlider = () => {
      // for slider TrustedCompaniesSlider
const sliderTrustedCompaniesProductService = [
    {
      
      url: "./Images/trusted-1.png",
    },
    {
    
      url: "./Images/trusted-2.png",
    },
    {
  
      url: "./Images/trusted-3.png",
    },
    {
    
      url:  "./Images/trusted-4.png",
    },
    {
     
      url:  "./Images/trusted-1.png",
    },
    {
      
      url:  "./Images/trusted-5.png",
    },
    {

      url:  "./Images/trusted-1.png",
    },
    {
       
        url:  "./Images/trusted-2.png",
      },
    {
        url:  "./Images/trusted-3.png",
      },
    {

      url:  "./Images/trusted-1.png",
    },
    {
       
        url:  "./Images/trusted-2.png",
      },
    {
        url:  "./Images/trusted-3.png",
      },
    {

      url:  "./Images/trusted-1.png",
    },
    {
       
        url:  "./Images/trusted-2.png",
      },
    {
        url:  "./Images/trusted-3.png",
      },
  
  ];
  
  const sliderTrustedCompaniesresponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 6,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "900px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const sliderTrustedCompaniesproductTemplate = (product) => {
    return (
      <div className="slider-trusted-companies-card">
        
     
          <div className="">
         
            <img src={product.url} width={"150px"} alt="" />
          </div>
         
      
      </div>
    );
  };
  return (
    <>
                <Slider  invervalValue={2000} ProductService={sliderTrustedCompaniesProductService} responsiveOptions={sliderTrustedCompaniesresponsiveOptions} productTemplate={sliderTrustedCompaniesproductTemplate} />

    </>
  )
}

export default TrustedCompaniesSlider
